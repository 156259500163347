import React from "react";
import { images } from "../../constants";
import { SubHeading } from "../../components";
import "./aboutUs.css";

const AboutUs = () => (
  <div className="illumin8__aboutPage flex__center illumin8__wrapper section__padding">
    <div className="app__specialMenu-menu">
      <div
        className="app__specialMenu-menu_wine  flex__center"
        style={{ margin: "1rem 2rem" }}
      >
        <h1 className="illumin8__headtext__aboutUs">Hui Zhang</h1>
        <SubHeading title="Co-Founder, CEO" />

        <div className="app__specialMenu_menu_items">
          <p className="illumin8__p__opensans">
            As an idealist (according to my Enneagram test results), I'm driven
            by a desire to make a positive impact and create meaningful change.
            At illumin8, I'm passionate about innovation and dedicated to
            delivering top-quality web solutions and exceptional customer
            service to our clients. <br />
            <br />
            When I'm not working, I love indulging my love of anime and keeping
            up with the latest developments in the financial market. I'm also a
            devoted dog mom to Shin, and can often be found listening to my
            favourite podcast, "The All In Podcast."
          </p>
        </div>
      </div>

      <div className="app__specialMenu-menu_img">
        <img
          style={{ borderRadius: "20px" }}
          src={images.teamUs}
          alt="menu__img"
        />
      </div>

      <div
        className="app__specialMenu-menu_cocktails  flex__center"
        style={{ margin: "1rem 2rem" }}
      >
        <h1 className="illumin8__headtext__aboutUs">Yasser Benslimane</h1>
        <SubHeading title=" Co-Founder, CMO" />
        <div className="app__specialMenu_menu_items">
          {" "}
          <p className="illumin8__p__opensans">
            With many years of experience in management and sales, I bring a ton
            of knowledge and expertise to the table. At Illumin8, I use my sense
            of humour and love of all things cheeky to help drive results and
            deliver top-quality web solutions and exceptional customer service
            to our clients. So if you need a CMO who can make you laugh and help
            your business succeed, I'm your guy!
            <br />
            <br /> In my free time, I love playing video games, keeping up with
            new technologies, and indulging my love of anime. I'm also a huge
            dog lover and am the proud owner of a cute little pooch named Shin.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default AboutUs;
