import * as React from "react";
import Paper from "@mui/material/Paper";
import { Link } from "react-router-dom";
import "./onlineOrder.css";
import OnlineOrderCoverBGImg from "../../../assets/onlineOrder/onlineOrderCoverBGImg.jpg";

function OnlineOrderCover() {
  return (
    <div className="illumin8__onlineOrderCover">
      <Paper>
        <div
          className="illumin8__onlineOrderCover-bgImg"
          style={{
            backgroundImage: `url(${OnlineOrderCoverBGImg})`,
          }}
        >
          <div className="illumin8__onlineOrderCover-content">
            <h1> Pastry Shop </h1>
            <h2>
              Weekday <br />
            </h2>
            <h3>Open Hour: 10:00 - 20:00</h3>
            <br />
            <br />
            <h2>
              Weekend <br />
            </h2>
            <h3>Open Hour: 11:00 - 22:00</h3>
          </div>

          <Link to="/onlineOrderMenu">
            <button id="OOCButoon1"> ORDER NOW</button>
          </Link>

          <Link to="/onlineOrderPlaceOrder">
            <button id="OOCButoon2"> MY ORDER</button>
          </Link>
        </div>
      </Paper>
    </div>
  );
}

export default OnlineOrderCover;
