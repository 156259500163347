import * as React from "react";
import { useTheme } from "@mui/material/styles";
import { autoPlay } from "react-swipeable-views-utils";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDatePicker } from "@mui/x-date-pickers/StaticDatePicker";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import PetsIcon from "@mui/icons-material/Pets";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AppointmentNavbar from "./AppointmentNavbar";
import AppointmentFooter from "./AppointmentFooter";
import appointmentBookingImg from "../../../assets/appintmentBooking/appointmentBookingImg.jpg";
import {
  appointmentPickerImg1,
  appointmentPickerImg2,
  appointmentPickerImg3,
} from "./imports";
import SwipeableViews from "react-swipeable-views";
import "./appointmentBooking.css";

// * carousel effect * //
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

// * Multiple select * //
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Veterinary Appointments",
  "Canine Daycare",
  "Behavioral Grooming",
];

function getStyles(name, personName, selectTheme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? selectTheme.typography.fontWeightRegular
        : selectTheme.typography.fontWeightMedium,
  };
}

// * button group * //
const timePeriod1 = [
  {
    time: "10:00 - 11:00",
  },
  {
    time: "11:00 - 12:00",
  },
  {
    time: "12:00 - 13:00",
  },
  {
    time: "13:00 - 14:00",
  },
];

const timePeriod2 = [
  {
    time: "14:00 - 15:00",
  },
  {
    time: "15:00 - 16:00",
  },
  {
    time: "16:00 - 17:00",
  },
  {
    time: "17:00 - 18:00",
  },
];

export default function AppointmentPicker() {
  //* Alerts Dialog *//
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //

  const [value, setValue] = React.useState(dayjs("now"));

  // * carousel effect * //
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  const images = [
    {
      imgPath: appointmentPickerImg1,
    },
    {
      imgPath: appointmentPickerImg2,
    },
    {
      imgPath: appointmentPickerImg3,
    },
  ];

  // * Multiple select * //
  const selectTheme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  return (
    <div className="illumin8__AppointmentPicker">
      <AppointmentNavbar />
      <Box
        sx={{
          display: "flex",
          flexWrap: { xs: "wrap", md: "noWrap" },
          mt: 2,
          width: "100%",
          flexGrow: 1,
        }}
      >
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {images.map((step, index) => (
            <div className="illumin8__AppointmentPicker-img" key={step.label}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Box
                  component="img"
                  sx={{
                    height: "80vh",
                    display: "block",
                    overflow: "hidden",
                    width: "100%",
                  }}
                  src={step.imgPath}
                />
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
      </Box>
      <Card
        elevation={0}
        sx={{
          width: "100%",
          mt: 6,
          padding: { xs: 1, md: 10 },
        }}
      >
        <CardContent>
          <Typography
            sx={{ fontSize: { xs: 28, md: 45 }, fontWeight: 800 }}
            component="div"
          >
            BEST SPA TREATMENT
          </Typography>
          <Typography color="black" sx={{ fontSize: { xs: 20, md: 25 } }}>
            <br />
            Our Dog salon provides professional dog grooming services in a
            friendly and calm environment. We also sell luxury dog products for
            your four legged friend.
          </Typography>
        </CardContent>
      </Card>

      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: "100%",
            maxWidth: 550,
          },
          mt: 6,
          justifyContent: "center",
        }}
      >
        <Card>
          <CardMedia
            sx={{
              height: { xs: 300, md: 600 },
            }}
            component="img"
            height="630"
            image={appointmentBookingImg}
          />
          <CardContent>
            <Typography
              sx={{ textAlign: "center" }}
              gutterBottom
              variant="h6"
              component="div"
            >
              APPOINTMENT BOOKING
            </Typography>
          </CardContent>
        </Card>
        <Box sx={{ justifyContent: "center" }}>
          <FormControl sx={{ mt: 4, width: "100%", maxWidth: 550 }}>
            <Select
              multiple
              displayEmpty
              value={personName}
              onChange={handleChange}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                if (selected.length === 0) {
                  return <em>CHOOSE YOUR SERVICES</em>;
                }

                return selected.join(", ");
              }}
              MenuProps={MenuProps}
              inputProps={{ "aria-label": "Without label" }}
            >
              {names.map((name) => (
                <MenuItem
                  key={name}
                  value={name}
                  style={getStyles(name, personName, selectTheme)}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <StaticDatePicker
              displayStaticWrapperAs="desktop"
              value={value}
              onChange={(newValue) => {
                setValue(newValue);
              }}
              renderInput={(params) => <TextField {...params} />}
              dayOfWeekFormatter={(day) => `${day}.`}
              toolbarFormat="ddd DD MMMM"
              showToolbar
            />
            <Box>
              <Stack
                sx={{ justifyContent: "center" }}
                direction="row"
                spacing={2}
              >
                {timePeriod1.map((timePeriod) => (
                  <Button
                    variant="outlined"
                    color="inherit"
                    sx={{
                      "&:hover": {
                        backgroundColor: "#0069d9",
                        borderColor: "#0062cc",
                        boxShadow: "none",
                      },
                    }}
                  >
                    {timePeriod.time}
                  </Button>
                ))}
              </Stack>

              <Stack
                sx={{ mt: 2, justifyContent: "center" }}
                direction="row"
                spacing={2}
              >
                {timePeriod2.map((timePeriod) => (
                  <Button
                    variant="outlined"
                    color="inherit"
                    sx={{
                      "&:hover": {
                        backgroundColor: "#0069d9",
                        borderColor: "#0062cc",
                        boxShadow: "none",
                      },
                    }}
                  >
                    {timePeriod.time}
                  </Button>
                ))}
              </Stack>
              <Stack
                sx={{ mt: 2, justifyContent: "flex-end" }}
                direction="row"
                spacing={2}
              >
                <Button
                  onClick={handleClickOpen}
                  variant="contained"
                  color="error"
                  startIcon={<PetsIcon />}
                >
                  BOOK
                </Button>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {"Appointment Booking Confirmation"}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                      You have selected your appointment for Saturday December
                      03, 2023 at 1:00 p.m. at Quartier Dix30. Would you like to
                      confirm?
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose}>Delete</Button>
                    <Button onClick={handleClose} autoFocus>
                      Confirm
                    </Button>
                  </DialogActions>
                </Dialog>
              </Stack>
            </Box>
          </LocalizationProvider>
        </Box>
      </Box>
      <AppointmentFooter />
    </div>
  );
}
