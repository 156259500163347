import React from "react";
import { Link } from "react-router-dom";
import { data } from "../../constants";
import "./blog.css";

function Blog() {
  return (
    <div className="illumin8__blog  illumin8__bgGD">
      <p className="gradient__text" style={{ padding: "6rem" }}>
        BLOGS & INSIGHT
      </p>
      <div class="wrapper">
        <div class="scroller">
          {data.blogs.map((item) => (
            <Link to={item.url}>
              <div class="slide">
                <div class="blogPhoto">
                  <img src={item.imgUrl} alt="" />
                </div>

                <div class="text">
                  <h2>{item.title}</h2>
                  <span> {item.subtitle}</span>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Blog;
