import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Grid, CssBaseline, Container } from "@mui/material";
import { images } from "../../../constants";
import MainFeaturedPost from "../MainFeaturedPost";
import SalonMainPost from "./SalonMainPost";

const SalonMainFeaturedPost = {
  title: "",
  image: images.salonCSImg01,
  imageText: "main image description",
};

const defaultTheme = createTheme();

function SalonCaseStudy() {
  return (
    <div className="illumin8__salonCaseStudy illumin8__bg">
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />

        <MainFeaturedPost post={SalonMainFeaturedPost} />
        <Container maxWidth="lg">
          <Grid sx={{ mt: 3 }}>
            <SalonMainPost />
          </Grid>
        </Container>
      </ThemeProvider>
    </div>
  );
}

export default SalonCaseStudy;
