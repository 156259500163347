import * as React from "react";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import contactUs from "../../assets/contactUs.jpeg";
import axios from "axios";
import "../contactInfo/contactInfo.css";

export default function ContactInfo() {
  const navigate = useNavigate();

  // * handle Submit function * //
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    console.log({
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
      email: data.get("email"),
      message: data.get("description"),
    });

    // * fetch the data from server * //
    // https://5anrui6me8.execute-api.us-east-2.amazonaws.com/contactUs

    try {
      const postMessage = await axios.post(
        "https://5anrui6me8.execute-api.us-east-2.amazonaws.com/contactUs",
        {
          firstName: data.get("firstName"),
          lastName: data.get("lastName"),
          email: data.get("email"),
          message: data.get("description"),
        }
      );

      if (postMessage) {
        navigate("/");
        // TODO:: before navigate to the home, display a popup to show the user that the message is posted
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <div className="illumin8__contact illumin8__wrapper section__padding illumin8__bg">
      <Box
        sx={{
          display: "flex",
          flexWrap: { xs: "wrap", md: "noWrap" },
          "& > :not(style)": {
            m: 2,
            width: "100%",
          },
          justifyContent: "center",
          alignItems: "center",
          mt: 6,
        }}
      >
        <Paper elevation={0} sx={{ backgroundColor: "inherit" }}>
          <CardMedia
            sx={{
              height: { xs: 300, md: 620, xl: 700 },
              borderRadius: "20px",
            }}
            component="img"
            image={contactUs}
            alt="appointmentItemGrooming1"
          />
        </Paper>

        <Paper elevation={0} sx={{ backgroundColor: "inherit" }}>
          <div className="illumin8__contact-form">
            <h1 className="gradient__text">Contact Us</h1>
            <form
              className="illumin8__contact-form"
              onSubmit={handleSubmit}
              id="contact-form"
              method="POST"
            >
              <label htmlFor="firstName">First Name*</label>
              <input
                variant="filled"
                id="firstName"
                name="firstName"
                type="text"
                required
              />
              <label variant="standard" htmlFor="lastName">
                Last Name*
              </label>
              <input
                variant="standard"
                id="lastName"
                name="lastName"
                type="text"
                required
              />
              <label htmlFor="email">Email*</label>
              <input id="email" name="email" type="email" required />
              <label htmlFor="message">Message*</label>
              <textarea
                id="description"
                rows="6"
                placeholder="Enter your business ideas..."
                name="description"
                required
              ></textarea>
              <button className="illumin8__button__Contained" type="submit">
                Send Message
              </button>
            </form>
          </div>
        </Paper>
      </Box>
    </div>
  );
}
