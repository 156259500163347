import React from "react";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import EcommerceNavbar from "./EcommerceNavbar";
import EcommerceFooter from "./EcommerceFooter";

//* Button Customization *//
const theme = createTheme({
  palette: {
    neutral: {
      main: "black",
      contrastText: "#fff",
    },
  },
});
//

function EcommerceSignup() {
   const [showPassword, setShowPassword] = React.useState(false);

   const handleClickShowPassword = () => setShowPassword((show) => !show);

   const handleMouseDownPassword = (event) => {
     event.preventDefault();
   };
  return (
    <div className="illumin8__ecommerceSignup">
      <EcommerceNavbar />

      <Container>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 12,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Paper>
            <Box
              noValidate
              sx={{
                p: 5,
                width: { xs: "wrap", md: "60ch" },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1 }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography
                component="h1"
                variant="h5"
                sx={{
                  fontWeight: 600,
                  fontSize: "30px",
                  fontFamily: "Manrope",
                  mb: 4,
                }}
              >
                SIGN UP
              </Typography>

              <Box
                method="POST"
                component="form"
                sx={{
                  width: "auto",
                  height: "auto",
                }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id="firstName"
                      name="firstName"
                      label="First Name"
                      autoComplete="given-name"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      required
                      fullWidth
                      id="lastName"
                      name="lastName"
                      label="Last Name"
                      autoComplete="family-name"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      fullWidth
                      id="email"
                      name="email"
                      label="Email Address"
                      autoComplete="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl required fullWidth variant="outlined">
                      <InputLabel htmlFor="outlined-adornment-password">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        type={showPassword ? "text" : "password"}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        name="password"
                        label="Password"
                        id="password"
                        autoComplete="current-password"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <font color="#000000">
                      <FormControlLabel
                        control={
                          <Checkbox value="allowExtraEmails" color="primary" />
                        }
                        label="I want to receive inspiration, marketing promotions and updates via email."
                      />
                    </font>
                  </Grid>
                </Grid>
                <ThemeProvider theme={theme}>
                  <Button
                    color="neutral"
                    variant="contained"
                    border-radius="60px"
                    type="submit"
                    fullWidth
                    sx={{
                      borderradius: "60px",
                      height: 50,
                      width: "100%",
                      mt: 3,
                    }}
                  >
                    <Typography color="white">Sign Up</Typography>
                  </Button>
                </ThemeProvider>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Container>
      <EcommerceFooter />
    </div>
  );
}

export default EcommerceSignup;
