import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import AppointmentNavbar from "./AppointmentNavbar";
import AppointmentFooter from "./AppointmentFooter";
import {
  appointmentItemToy1,
  appointmentItemFood1,
  appointmentItemCloth1,
  appointmentItemProduct1,
} from "./imports";

const items = [
  {
    description: "Dog Toy",
    price: "$20.00",
    imgPath: appointmentItemToy1,
  },
  {
    description: "Dog Food",
    price: "$60.00",
    imgPath: appointmentItemFood1,
  },
  {
    description: "Dog Clothes",
    price: "$40.00",
    imgPath: appointmentItemCloth1,
  },
  {
    description: "Dog Leash",
    price: "$40.00",
    imgPath: appointmentItemProduct1,
  },
  
];

export default function AppointmentProducts() {
  return (
    <div className="illumin8__appointmentProducts">
      <AppointmentNavbar />
      <Box
        sx={{
          display: "flex",
          flexWrap: { xs: "wrap", md: "noWrap" },
          overflow: "auto",
          "& > :not(style)": {
            m: 2,
            width: 300,
            height: 500,
          },
          justifyContent: "center",
          mt: 6,
        }}
      >
        {items.map((item) => (
          <Card>
            <CardMedia
              component="img"
              height="75%"
              image={item.imgPath}
              alt="appointmentItemGrooming1"
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {item.description}
              </Typography>
              <CardActions>
                <Typography variant="body1" color="text.secondary">
                  {item.price}
                  <Button sx={{ ml: 6 }} size="small">
                    <FavoriteIcon />
                  </Button>
                  <Button size="small">
                    <ShoppingCartIcon />
                  </Button>
                </Typography>
              </CardActions>
            </CardContent>
          </Card>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: { xs: "wrap", md: "noWrap" },
          overflow: "scroll",
          "& > :not(style)": {
            m: 2,
            width: 300,
            height: 500,
          },
          justifyContent: "center",
          mt: 6,
        }}
      >
        {items.map((item) => (
          <Card>
            <CardMedia
              component="img"
              height="75%"
              image={item.imgPath}
              alt="appointmentItemGrooming1"
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {item.description}
              </Typography>
              <CardActions>
                <Typography variant="body1" color="text.secondary">
                  {item.price}
                  <Button sx={{ ml: 6 }} size="small">
                    <FavoriteIcon />
                  </Button>
                  <Button size="small">
                    <ShoppingCartIcon />
                  </Button>
                </Typography>
              </CardActions>
            </CardContent>
          </Card>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexWrap: { xs: "wrap", md: "noWrap" },
          overflow: "scroll",
          "& > :not(style)": {
            m: 2,
            width: 300,
            height: 500,
          },
          justifyContent: "center",
          mt: 6,
        }}
      >
        {items.map((item) => (
          <Card>
            <CardMedia
              component="img"
              height="75%"
              image={item.imgPath}
              alt="appointmentItemGrooming1"
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {item.description}
              </Typography>
              <CardActions>
                <Typography variant="body1" color="text.secondary">
                  {item.price}
                  <Button sx={{ ml: 6 }} size="small">
                    <FavoriteIcon />
                  </Button>
                  <Button size="small">
                    <ShoppingCartIcon />
                  </Button>
                </Typography>
              </CardActions>
            </CardContent>
          </Card>
        ))}
      </Box>
      <AppointmentFooter />
    </div>
  );
}
