import React from "react";
import AppointmentNavbar from "./AppointmentNavbar";
import AppointmentBanner from "./AppointmentBanner";
import AppointmentFooter from "./AppointmentFooter";
import AppointmentItems from "./AppointmentItems";

function AppointmentBooking() {
  return (
    <div className="illumin8__appointmentBooking">
      <AppointmentNavbar />
      <AppointmentBanner />
      <AppointmentItems />
      <AppointmentFooter />
    </div>
  );
}

export default AppointmentBooking;
