import React from "react";
import { SubHeading } from "../../components";
import { images, data } from "../../constants";
import "../../pages/showcase/menu/container/Laurels/Laurels.css";

const AwardCard = ({ award: { title, subtitle } }) => (
  <div className="app__laurels_awards-card">
    <div className="app__laurels_awards-card_content">
      <SubHeading title={title} />
      <p className="illumin8__p__opensans">{subtitle}</p>
    </div>
  </div>
);

const OurBrand = () => (
  <div className="illumin8__aboutPage illumin8__wrapper section__padding">
    <div className="app__wrapper_info">
      <SubHeading title="Your story is our story." />
      <h1 className="illumin8__headtext__cormorant">
        <strong>OUR BRANDING </strong>
      </h1>

      <div className="app__laurels_awards">
        {data.awards.map((award) => (
          <AwardCard award={award} key={award.title} />
        ))}
      </div>
    </div>

    <div className="app__wrapper_img">
      <img
        style={{ borderRadius: "20px" }}
        src={images.team02}
        alt="laurels_img"
      />
    </div>
  </div>
);

export default OurBrand;
