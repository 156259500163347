import * as React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import EditIcon from "@mui/icons-material/Edit";
import Button from "@mui/material/Button";
import Zoom from "@mui/material/Zoom";
import PaymentIcon from "@mui/icons-material/Payment";

const TAX_RATE = 0.15;

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

function priceRow(qty, unit) {
  return qty * unit;
}

function createRow(desc, qty, unit) {
  const price = priceRow(qty, unit);
  return { desc, qty, unit, price };
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

const rows = [
  createRow("Croissant", 4, 6),
  createRow("Pies", 2, 8),
  createRow("Danishes", 2, 10),
  createRow("Macaron", 6, 12),
  createRow("Strudel", 4, 10),
];

const invoiceSubtotal = subtotal(rows);
const invoiceTaxes = TAX_RATE * invoiceSubtotal;
const invoiceTotal = invoiceTaxes + invoiceSubtotal;

const fabStyle = {
  position: "sticky",
  justifyContent: "center",
  textAlign: "center",
  bottom: 26,
  width: 400,
  maxHeight: 40,
  color: "common.white",
  bgcolor: "#313131",
  "&:hover": {
    bgcolor: "#ff4603",
  },
};

function OnlineOrderCheckout() {
  const [value] = React.useState(0);

  const fabs = [
    {
      color: "inherit",
      sx: fabStyle,
      icon: <PaymentIcon sx={{ marginLeft: 2 }} />,
      label: "PLACE ORDER",
    },
  ];
  return (
    <div className="illumin8__onlineOrderCheckout">
      <Box
        sx={{
          bgcolor: "#eeeeee",
          position: "relative",
          overflow: "auto",
          maxWidth: 400,
          height: 630,
          flexGrow: 1,
          justifyContent: "center",
        }}
      >
        <TableContainer component={Paper}>
          <Toolbar
            sx={{
              bgcolor: "#bdbdbd",
              pl: { sm: 2 },
              pr: { xs: 1, sm: 1 },
            }}
          >
            <Typography
              sx={{ flex: "1 1 100%" }}
              variant="subtitle1"
              id="tableTitle"
              component="div"
            >
              ORDERED ITEMS
            </Typography>

            <Tooltip title="Edit">
              <IconButton>
                <EditIcon color="error" />
              </IconButton>
            </Tooltip>
          </Toolbar>
          <Table aria-label="spanning table">
            <TableHead sx={{ bgcolor: "#e0e0e0" }}>
              <TableRow>
                <TableCell>Desc</TableCell>
                <TableCell align="right">Qty.</TableCell>
                <TableCell align="right">Unit</TableCell>
                <TableCell align="right">Sum</TableCell>
              </TableRow>
            </TableHead>
            <TableBody sx={{ bgcolor: "#eeeeee" }}>
              {rows.map((row) => (
                <TableRow key={row.desc}>
                  <TableCell>{row.desc}</TableCell>
                  <TableCell align="right">{row.qty}</TableCell>
                  <TableCell align="right">{row.unit}</TableCell>
                  <TableCell align="right">{ccyFormat(row.price)}</TableCell>
                </TableRow>
              ))}

              <TableRow>
                <TableCell rowSpan={3} />
                <TableCell colSpan={2}>Subtotal</TableCell>
                <TableCell align="right">
                  {ccyFormat(invoiceSubtotal)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Tax</TableCell>
                <TableCell align="right">{`${(TAX_RATE * 100).toFixed(
                  0
                )} %`}</TableCell>
                <TableCell align="right">{ccyFormat(invoiceTaxes)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={2}>Total</TableCell>
                <TableCell align="right">{ccyFormat(invoiceTotal)}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {fabs.map((fab, index) => (
          <Zoom key={fab.color} in={value === index} unmountOnExit>
            <Link to="/onlineOrderPlaceOrder">
              <Button sx={fab.sx} color={fab.color}>
                {fab.label} {fab.icon}
              </Button>
            </Link>
          </Zoom>
        ))}
      </Box>
    </div>
  );
}

export default OnlineOrderCheckout;
