import React from "react";
import { Link } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import PaymentsIcon from "@mui/icons-material/Payments";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import EcommerceNavbar from "./EcommerceNavbar";
import EcommerceFooter from "./EcommerceFooter";

//* Button Customization *//
const theme = createTheme({
  palette: {
    neutral: {
      main: "black",
      contrastText: "#fff",
    },
  },
});
//

function EcommerceLogin() {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <div className="illumin8__ecommerceLogin">
      <EcommerceNavbar />

      <Container>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 12,
            display: "flex",
            flexWrap: { xs: "wrap", md: "noWrap" },
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Paper elevation={0}>
            <Card
              noValidate
              sx={{
                p: { xs: 2, md: 5 },
                mr: { xs: 0, md: 8 },
                width: { xs: "100%", md: "60ch" },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar
                sx={{
                  m: 1,
                }}
              >
                <LockOutlinedIcon />
              </Avatar>
              <Typography
                component="h1"
                variant="h5"
                sx={{
                  fontWeight: 600,
                  fontSize: "30px",
                  fontFamily: "Manrope",
                  mb: 2,
                }}
              >
                SIGN IN
              </Typography>

              <Box
                component="form"
                method="POST"
                sx={{
                  width: "auto",
                  height: "auto",
                }}
              >
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                />

                <FormControl required fullWidth variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">
                    Password
                  </InputLabel>
                  <OutlinedInput
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    name="password"
                    label="Password"
                    id="password"
                    autoComplete="current-password"
                  />
                </FormControl>

                <font color="#000000">
                  <FormControlLabel
                    control={<Checkbox value="remember" color="primary" />}
                    color="rgb(0, 0, 0)"
                    label="Remember me"
                  />
                </font>
                <ThemeProvider theme={theme}>
                  <Button
                    color="neutral"
                    variant="contained"
                    border-radius="60px"
                    type="submit"
                    fullWidth
                    sx={{
                      borderradius: "60px",
                      height: 50,
                      width: "100%",
                      mt: 3,
                    }}
                  >
                    <font>Sign In</font>
                  </Button>
                </ThemeProvider>

                <font color="#000000">
                  <Link to="">
                    <Typography sx={{ m: 2 }}>Forgot your password?</Typography>
                  </Link>
                </font>
              </Box>
            </Card>
          </Paper>

          <Paper elevation={0}>
            <Card
              sx={{
                backgroundColor: "#9e9e9e",
                p: { xs: 1, md: 4 },
                width: { xs: "100%", md: "60ch" },
                mt: { xs: 5, md: 0 },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CardContent>
                <Typography sx={{ fontSize: 30, fontWeight: 800 }} gutterBottom>
                  Create an Account
                </Typography>
                <Typography sx={{ fontSize: 16, mb: 8 }} component="div">
                  Create an account to enjoy all the benefits of our registered
                  customers.
                </Typography>
                <Typography
                  sx={{ mb: 1.5, display: "flex" }}
                  color="text.secondary"
                >
                  <FavoriteIcon sx={{ mr: 2 }} />
                  Save your favourites across devices
                </Typography>
                <Typography
                  sx={{ mb: 1.5, display: "flex" }}
                  color="text.secondary"
                >
                  <ShoppingBagIcon sx={{ mr: 2 }} />
                  Save time at checkout for future orders
                </Typography>
                <Typography
                  sx={{ mb: 1.5, display: "flex" }}
                  color="text.secondary"
                >
                  <PaymentsIcon sx={{ mr: 2 }} />
                  Manage your purchases and returns in your order history
                </Typography>
                <Typography
                  sx={{ mb: 1.5, display: "flex" }}
                  color="text.secondary"
                >
                  <ThumbUpIcon sx={{ mr: 2 }} />
                  Become a member to profit from special offers
                </Typography>
              </CardContent>
              <CardActions>
                <ThemeProvider theme={theme}>
                  <Link
                    to="/ecommerceSignup"
                    sx={{
                      height: 50,
                      width: "100%",
                      mt: 3,
                    }}
                  >
                    <Button
                      color="neutral"
                      variant="contained"
                      border-radius="60px"
                      type="submit"
                      fullWidth
                      sx={{
                        height: 50,
                        width: { xs: "100%", md: "60ch" },
                        mt: 3,
                      }}
                    >
                      <Typography color="white">REGISTER NOW</Typography>
                    </Button>
                  </Link>
                </ThemeProvider>
              </CardActions>
            </Card>
          </Paper>
        </Box>
      </Container>
      <EcommerceFooter />
    </div>
  );
}

export default EcommerceLogin;
