import React from "react";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ButtonGroup from "@mui/material/ButtonGroup";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import EcommerceNavbar from "./EcommerceNavbar";
import EcommerceFooter from "./EcommerceFooter";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import {
  clothing1,
  clothing2,
  clothing3,
  mastercardLogo,
  visaLogo,
  paypalLogo,
} from "./imports";

//* Button Customization *//
const theme = createTheme({
  palette: {
    neutral: {
      main: "black",
      contrastText: "#fff",
    },
  },
});
//

//* Spanning table *//
const TAX_RATE = 0.15;

function ccyFormat(num) {
  return `${num.toFixed(2)}`;
}

function priceRow(quantity, unitPrice) {
  return quantity * unitPrice;
}

function subtotal(items) {
  return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
}

function createRow(
  description,
  Color,
  size,
  quantity,
  unitPrice,
  imgPath,
  url
) {
  const price = priceRow(quantity, unitPrice);
  return { description, Color, size, quantity, unitPrice, price, imgPath, url };
}

const items = [
  createRow(
    "Dress White",
    "Black",
    "S",
    1,
    100.99,
    clothing1,
    "/ecommerceItemDetails"
  ),
  createRow(
    "Dress Silver",
    "Black",
    "M",
    2,
    200.99,
    clothing2,
    "/ecommerceItemDetails"
  ),
  createRow(
    "Dress Red",
    "Black",
    "L",
    3,
    300.99,
    clothing3,
    "/ecommerceItemDetails"
  ),
];

const invoiceSubtotal = subtotal(items);
const invoiceTaxes = TAX_RATE * invoiceSubtotal;
const invoiceTotal = invoiceTaxes + invoiceSubtotal;
//

function EcommerceShoppingBag() {
  //* Badge visibility */
  const [count, setCount] = React.useState(1);

  //
  return (
    <div className="illumin8__ecommerceShoppingBag">
      <EcommerceNavbar />
      <Typography
        sx={{
          fontSize: { xs: 30, md: 40 },
          fontFamily: "monospace",
          fontWeight: 700,
          letterSpacing: ".3rem",
          color: "black",
          textDecoration: "none",
          textAlign: "center",
          overflow: "auto",
          p: 6,
        }}
      >
        Shopping Bag
      </Typography>

      <div className="illumin8__ecommerceShoppingBag-container">
        <Box
          sx={{
            display: "flex",
            flexWrap: { xs: "wrap", md: "noWrap" },
            "& > :not(style)": {
              m: { xs: 1, md: 2 },
              width: 800,
              height: "100%",
            },
            justifyContent: "center",
          }}
        >
          <div className="illumin8__ecommerceShoppingBag-info">
            {items.map((item) => (
              <Paper>
                <Card sx={{ mb: 2, display: "flex" }} elevation={0}>
                  <CardMedia
                    component="img"
                    sx={{ width: 150 }}
                    image={item.imgPath}
                  />

                  <CardContent
                    sx={{ display: "flex", flexDirection: "column" }}
                  >
                    <List>
                      <ListItem>
                        <ListItemText>
                          <Typography variant="h6">
                            {item.description}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                          >
                            Color: {item.Color}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                          >
                            Size: {item.size}
                          </Typography>
                          <Typography
                            variant="subtitle2"
                            color="text.secondary"
                          >
                            Unit Price: {item.unitPrice}
                          </Typography>

                          <Typography
                            sx={{ mt: 1, display: "flex" }}
                            variant="subtitle2"
                            color="text.secondary"
                            quantityContent={count}
                          >
                            Quantity: {item.quantity}
                            <ButtonGroup
                              size="small"
                              sx={{ ml: { xs: 1, md: 1 } }}
                            >
                              <Button
                                size="small"
                                color="inherit"
                                aria-label="reduce"
                                onClick={() => {
                                  setCount(Math.max(count - 1, 0));
                                }}
                              >
                                <RemoveIcon fontSize="small" />
                              </Button>

                              <Button
                                size="small"
                                color="inherit"
                                aria-label="increase"
                                onClick={() => {
                                  setCount(count + 1);
                                }}
                              >
                                <AddIcon fontSize="small" />
                              </Button>
                            </ButtonGroup>
                          </Typography>
                        </ListItemText>
                      </ListItem>

                      <ListItem>
                        <ListItemText sx={{ display: "flex" }}>
                          <Typography color="error" variant="body2">
                            Total Price: {item.unitPrice}
                            <Button size="small" color="inherit">
                              <DeleteOutlineOutlinedIcon
                                sx={{ fontSize: 18 }}
                              />
                            </Button>
                          </Typography>
                        </ListItemText>
                      </ListItem>
                    </List>
                  </CardContent>
                </Card>
              </Paper>
            ))}
          </div>
          <div className="illumin8__ecommerceShoppingBag-checkoutTable">
            <Paper>
              <TableContainer>
                <Table sx={{ p: { xs: 1, md: 6 }, mb: 2, width: "100%" }}>
                  <TableBody>
                    <TableRow>
                      <TableCell rowSpan={4} />
                      <TableCell colSpan={2}>
                        <Typography variant="subtitle1">Subtotal: </Typography>
                      </TableCell>
                      <TableCell align="right">
                        {ccyFormat(invoiceSubtotal)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>
                        <Typography variant="subtitle1">Delivery: </Typography>
                      </TableCell>
                      <TableCell align="right">FREE</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>
                        <Typography variant="subtitle1">Tax: </Typography>
                      </TableCell>
                      <TableCell align="right">{`${(TAX_RATE * 100).toFixed(
                        0
                      )} %`}</TableCell>
                      <TableCell align="right">
                        {ccyFormat(invoiceTaxes)}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={3}>
                        <Typography variant="subtitle1">Total: </Typography>
                      </TableCell>
                      <TableCell align="right">
                        <Typography color="error" variant="subtitle1">
                          C$ {ccyFormat(invoiceTotal)}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <CardActions>
                <ThemeProvider theme={theme}>
                  <Button
                    color="neutral"
                    variant="contained"
                    sx={{
                      ml: 1,
                      height: 50,
                      width: "100%",
                      alignItems: "center",
                    }}
                  >
                    <Link to="/ecommerceLogin">
                      <Typography sx={{ color: "white" }}>Log in</Typography>
                    </Link>
                  </Button>
                  <Button
                    color="neutral"
                    variant="contained"
                    sx={{
                      mr: 1,
                      height: 50,
                      width: "100%",

                      alignItems: "center",
                    }}
                  >
                    <Link to="/ecommerceShoppingBag">
                      <Typography sx={{ color: "white" }}>checkout</Typography>
                    </Link>
                  </Button>
                </ThemeProvider>
              </CardActions>
              <Typography sx={{ ml: 2, mt: 1 }} variant="subtitle2">
                We accept*
              </Typography>
              <Card sx={{ display: "flex" }} elevation={0}>
                <CardMedia
                  component="img"
                  sx={{ m: 2, width: "8%" }}
                  image={mastercardLogo}
                />
                <CardMedia
                  component="img"
                  sx={{ m: 2, width: "10%" }}
                  image={visaLogo}
                />
                <CardMedia
                  component="img"
                  sx={{ m: 2, width: "16%" }}
                  image={paypalLogo}
                />
              </Card>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography color="black">Delivery</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="black">
                    Inclement weather conditions may impact the timely arrival
                    of your order or return package. Please allow additional
                    days for delivery. We appreciate your patience and
                    understanding.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography color="black">Return Options</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="black">
                    We now accept free in-store returns & exchanges of online
                    items. Sale items are final sale — returns or exchanges will
                    not be accepted.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography color="black">Shipping Opttions</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="black">
                    <li>FREE STANDARD SHIPPING OVER $30 FOR MEMBERS</li>
                    <li>$7.99 below $30 (5-10 business days)</li>
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </Paper>
          </div>
        </Box>
      </div>

      <EcommerceFooter />
    </div>
  );
}

export default EcommerceShoppingBag;
