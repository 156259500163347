import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import Croissants from "../../../assets/onlineOrder/Croissants.jpeg";

const item1 = [
  {
    title: " A1. Croissant",
    price: "$6",
    imgPath: Croissants,
  },
  {
    title: " A1. Croissant",
    price: "$6",
    imgPath: Croissants,
  },
  {
    title: " A1. Croissant",
    price: "$6",
    imgPath: Croissants,
  },
  {
    title: " A1. Croissant",
    price: "$6",
    imgPath: Croissants,
  },
  {
    title: " A1. Croissant",
    price: "$6",
    imgPath: Croissants,
  },
  {
    title: " A1. Croissant",
    price: "$6",
    imgPath: Croissants,
  },
];

function OnlineOderItem1() {
  return (
    <div className="illumin8__OnlineOderItem1">
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 1,
            width: "45%",
          },
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {item1.map((item) => (
          <Card>
            <CardMedia
              component="img"
              alt="order1"
              height="160"
              image={item.imgPath}
            />
            <CardContent sx={{ textAlign: "center" }}>
              <Typography gutterBottom variant="body2" component="div">
                {item.title}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {item.price}
              </Typography>
            </CardContent>
            <CardActions sx={{ mt: -2, justifyContent: "center" }}>
              <Button size="small">
                <RemoveCircleIcon />
              </Button>
              <Button size="small">
                <AddCircleIcon />
              </Button>
            </CardActions>
          </Card>
        ))}
      </Box>
    </div>
  );
}

export default OnlineOderItem1;
