import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  appointmentItemToy1,
  appointmentItemFood1,
  appointmentItemCloth1,
} from "./imports";

const items = [
  {
    description: "Dog Toy",
    price: "$20.00",
    imgPath: appointmentItemToy1,
  },
  {
    description: "Dog Food",
    price: "$60.00",
    imgPath: appointmentItemFood1,
  },
  {
    description: "Dog Clothes",
    price: "$40.00",
    imgPath: appointmentItemCloth1,
  },
];

function AppointmentItems() {
  return (
    <div className="illumin8__appointmentItems">
      <Card
        elevation={0}
        sx={{
          width: "100%",
          mt: 6,
          padding: { xs: 3, md: 10 },
        }}
      >
        <CardContent>
          <Typography
            sx={{
              color: "#d84315",
              fontSize: { xs: 25, md: 45 },
              fontWeight: 900,
            }}
            component="div"
          >
            PROMOTION SEASON COMING SOON
          </Typography>
          <Typography sx={{ color: "#f4511e", fontSize: { xs: 20, md: 25 } }}>
            <br />
            We're all about serving up paw-sitively top-notch munchies to keep
            your furry friend's health in tip-top shape and dishing out the
            ultimate tail-wagging toys for some unbeatable bonding time! 🐾🍖🎾
          </Typography>
        </CardContent>
      </Card>
      <Box
        sx={{
          display: "flex",
          flexWrap: { xs: "wrap", md: "noWrap" },
          overflow: "auto",
          "& > :not(style)": {
            m: 1,
            width: 300,
            height: 500,
          },
          justifyContent: "center",
          mt: 6,
        }}
      >
        <Card sx={{ bgcolor: "#ff6333" }}>
          <CardContent sx={{ mt: 20, color: "white" }}>
            <Typography variant="h5">New Out Now</Typography>
            <Typography variant="h4">30% OFF</Typography>
          </CardContent>
          <CardActions>
            <Button sx={{ color: "#ffffff" }} size="large" variant="outlined">
              <Typography sx={{ color: "white" }}>SHOP ALL</Typography>
            </Button>
          </CardActions>
        </Card>
        {items.map((item) => (
          <Card>
            <CardMedia
              component="img"
              height="75%"
              image={item.imgPath}
              alt="appointmentItemGrooming1"
            />
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {item.description}
              </Typography>
              <CardActions>
                <Typography variant="body1" color="text.secondary">
                  {item.price}
                  <Button sx={{ ml: 6 }} size="small">
                    <FavoriteIcon />
                  </Button>
                  <Button size="small">
                    <ShoppingCartIcon />
                  </Button>
                </Typography>
              </CardActions>
            </CardContent>
          </Card>
        ))}
      </Box>
    </div>
  );
}

export default AppointmentItems;
