import * as React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Container from "@mui/material/Container";

const footers = [
  {
    title: "Company",
    description: ["Team", "History"],
  },
  {
    title: "Resources",
    description: ["Contact us", "Locations"],
  },
  {
    title: "Features",
    description: ["FAQs", "Branding"],
  },

  {
    title: "Legal",
    description: ["Privacy policy", "Terms of use"],
  },
];

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit">Website</Link> {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function EcommerceFooter() {
  return (
    <div className="illumin8__ecommerceFooter">
      <Container
        width="100%"
        component="footer"
        align="center"
        display="flex"
        sx={{
          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
          mt: 8,
          py: [3, 6],
        }}
      >
        <Grid container spacing={4}>
          {footers.map((footer) => (
            <Grid item xs={4} sm={3} key={footer.title}>
              <Typography variant="h6" color="text.primary" gutterBottom>
                {footer.title}
              </Typography>
              <Typography>
                {footer.description.map((item) => (
                  <Typography key={item}>
                    <Link variant="overline" color="text.secondary">
                      {item}
                    </Link>
                  </Typography>
                ))}
              </Typography>
            </Grid>
          ))}
        </Grid>
        <Copyright sx={{ mt: 5 }} />
      </Container>
    </div>
  );
}

export default EcommerceFooter;
