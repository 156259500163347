import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { images } from "../../constants";
import Sidebar from "./Sidebar";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Grid, Container, Box, Paper, Typography } from "@mui/material";
import blogArticleImg4 from "../../assets/homePage/blogArticleImg4.jpg";

const post04Sidebar = {
  title: "Unveiling Your Unique Brand Essence",
  description:
    "Unlock unparalleled business potential with our insightful branding guide. Discover, define, and deploy your distinctive brand essence, mastering the art of irresistible appeal that captivates audiences and nurtures enduring customer loyalty seamlessly and effectively. Elevate your brand today!",
  archives: [
    {
      title: "New Digital Horizons",
      imgPath: images.blog5,
      url: "/NewDigitalHorizons",
    },
    {
      title: "Our offer to you?",
      imgPath: images.blog2,
      url: "/OurOfferToYou",
    },
    {
      title: "Win-win cooperation",
      imgPath: images.blog3,
      url: "/WinwinCooperation",
    },
  ],
  social: [
    {
      name: (
        <a href="https://www.linkedin.com/company/solutions-illumin8/">
          LinkedIn
        </a>
      ),
      icon: LinkedInIcon,
    },
    {
      name: (
        <a href="https://www.facebook.com/profile.php?id=100086324772795">
          Facebook
        </a>
      ),
      icon: FacebookIcon,
    },
    {
      name: (
        <a href="https://www.instagram.com/solutions_illumin8/">Instagram</a>
      ),
      icon: InstagramIcon,
    },
  ],
};

const defaultTheme = createTheme();

export default function BlogArticleFour() {
  return (
    <div className="illumin8__blogPost  illumin8__bg">
      <ThemeProvider theme={defaultTheme}>
        <Paper
          elevation={0}
          sx={{
            position: "relative",
            backgroundColor: "grey.800",
            color: "#fff",
            mb: 4,
            width: "100vw",
            height: "50vh",
            textAlign: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: `url(${blogArticleImg4})`,
          }}
        >
          {
            <img
              style={{ display: "none", width: "100%", height: "100%" }}
              src={blogArticleImg4}
              alt=""
            />
          }

          <Grid container justifyContent="center" alignItems="center">
            <Grid>
              <Box
                sx={{
                  position: "relative",
                  padding: { xs: "10vh", md: "30vh" },
                }}
              ></Box>
            </Grid>
          </Grid>
        </Paper>
        <Container maxWidth="lg">
          <Grid container spacing={5} sx={{ mt: 3, pb: 10 }}>
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                "& .markdown": {
                  py: 3,
                },
              }}
            >
              <Typography fontFamily="Manrope" variant="h4" fontWeight={600}>
                Branding Strategy
              </Typography>
              <Typography
                fontFamily="Manrope"
                variant="subtitle1"
                marginTop={4}
              >
                <strong>1. What is Branding? </strong> <br />
                <br />
                Branding is like giving a personality to a product, service, or
                organization. Think about your friends – each has their own
                personality, way they dress, and way they communicate.
                Similarly, branding gives a unique identity to a business so
                that people can recognize it and form an emotional connection
                with it. <br />
                <br /> Key Elements of Branding: <br />
                <Typography
                  fontFamily="Manrope"
                  marginLeft={4}
                  variant="subtitle1"
                >
                  <ul>
                    <li>
                      <strong>Logo:</strong> The symbol or design that
                      represents the brand.
                    </li>
                    <li>
                      <strong> Colors & Typography:</strong> Specific colors and
                      fonts that the brand uses consistently.
                    </li>
                    <li>
                      <strong> Tone of Voice:</strong> How the brand "speaks" or
                      communicates.
                    </li>
                    <li>
                      <strong> Brand Story:</strong> The background, mission,
                      and values of a company.
                    </li>
                    <li>
                      <strong>Brand Experience:</strong> How consumers feel when
                      they interact with the brand.
                    </li>
                  </ul>
                </Typography>
                <br />
                <br />
                <strong>2. Benefits of Branding </strong> <br />
                <br />
                <Typography
                  fontFamily="Manrope"
                  marginLeft={4}
                  variant="subtitle1"
                >
                  <ul>
                    <li>
                      <strong> Recognition:</strong> Just as you recognize your
                      friends from afar, a strong brand stands out in a crowded
                      market.
                    </li>
                    <li>
                      <strong> Trust: </strong>A consistent brand can build
                      trust with consumers. Think about how you feel about your
                      favorite brands and why you prefer them over others.
                    </li>
                    <li>
                      <strong>Increased Value:</strong> Companies with strong
                      brands often have a higher market value and can command
                      higher prices for their products.
                    </li>
                    <li>
                      <strong> Customer Loyalty: </strong>People are more likely
                      to stick with brands they love and trust.
                    </li>
                    <li>
                      <strong>Competitive Edge:</strong> A strong brand can give
                      a company an edge in the market, making it harder for
                      competitors to make inroads.
                    </li>
                  </ul>
                </Typography>
                <br />
                <br />
                <strong>3. What Makes a Brand Bad? </strong> <br />
                <br />
                <Typography
                  fontFamily="Manrope"
                  marginLeft={4}
                  variant="subtitle1"
                >
                  <ul>
                    <li>
                      <strong>Inconsistency:</strong> Changing logos, tones, or
                      messages frequently confuses consumers.
                    </li>
                    <li>
                      <strong>Broken Promises: </strong>Brands that don’t
                      deliver on what they promise lose trust quickly.
                    </li>
                    <li>
                      <strong>Bad Reputation:</strong> Negative news, scandals,
                      or unethical behavior can damage a brand's image.
                    </li>
                    <li>
                      <strong>Irrelevance: </strong>Brands that don’t evolve
                      with changing times or consumer needs can become outdated.
                    </li>
                  </ul>
                </Typography>
                <br />
                <br />
                <strong>4. Case Study: New Coke </strong>
                <br />
                <br />
                In 1985, Coca-Cola, one of the most recognized brands worldwide,
                decided to reformulate its iconic soda and introduced "New
                Coke". They did this because taste tests suggested that people
                preferred a sweeter drink like Pepsi. However, they
                underestimated the deep emotional connection consumers had with
                the original formula.
                <br />
                <br />
                The reaction was swift and negative:
                <br />
                <br />
                <Typography
                  fontFamily="Manrope"
                  marginLeft={4}
                  variant="subtitle1"
                >
                  <ul>
                    <li>Consumers protested and stockpiled old Coke.</li>
                    <li>
                      Negative media coverage and public outcry were widespread.
                    </li>
                    <li>
                      Within 3 months, Coca-Cola had to reintroduce the old
                      formula as "Coca-Cola Classic."
                    </li>
                  </ul>
                </Typography>
                <br />
                <br />
                <strong>* Lessons Learned </strong>
                <br />
                <br />
                1. Brands are not just about taste or features; they carry
                emotional weight.
                <br />
                <br />
                2. Big changes should be approached with caution, and it's vital
                to understand what your brand means to its fans.
              </Typography>
            </Grid>
            <Sidebar
              title={post04Sidebar.title}
              description={post04Sidebar.description}
              archives={post04Sidebar.archives}
              social={post04Sidebar.social}
            />
          </Grid>
        </Container>
      </ThemeProvider>
    </div>
  );
}
