import React from "react";
import { images, data } from "../../constants";
import {
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
} from "@mui/material";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import "../services.css";

const Branding = () => (
  <div className="illumin8__services">
    {/* service1 - Brand Strategy Development */}
    <div className="illumin8__services-content illumin8__wrapper section__padding">
      <Grid
        container
        spacing={{ xs: 0, sm: 8 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          <div>
            <i className="photo">
              <img src={images.branding01} alt="" />
            </i>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="illumin8__wrapper_info">
            <h1 className="illumin8__services__header-h1">
              <strong>Brand Strategy Development</strong>
            </h1>
            <p className="illumin8__p__opensans">
              A strong brand starts with a well-defined strategy. Our branding
              experts delve deep into your business, target audience, and market
              landscape to develop a comprehensive brand strategy. By
              identifying your brand's core values, positioning, and key
              messaging, we lay the groundwork for a cohesive and impactful
              brand identity.
            </p>

            <List
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              {data.Brand_service1.map((item) => (
                <ListItem>
                  <ListItemAvatar>
                    <ThumbUpAltIcon
                      sx={{ fontSize: { xs: 20, md: 25, xl: 35 } }}
                    />
                  </ListItemAvatar>
                  <Typography
                    fontFamily="Manrope"
                    color="black"
                    sx={{
                      fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 17 },
                    }}
                  >
                    <strong>{item.title}</strong>
                  </Typography>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      </Grid>
    </div>

    {/* service2 - Logo Design and Visual Identity */}
    <div className="illumin8__services-content illumin8__wrapper section__padding">
      <Grid
        container
        spacing={{ xs: 0, sm: 8 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid className="div1" item xs={12} sm={6}>
          <div id="div1" className="illumin8__wrapper_info">
            <h1 className="illumin8__services__header-h1">
              <strong>Logo Design & Visual Identity</strong>
            </h1>
            <p className="illumin8__p__opensans">
              A memorable logo and visual identity are the faces of your brand.
              Our creative team specializes in crafting visually stunning and
              distinctive logos that encapsulate your brand's essence. We also
              develop comprehensive visual identity guidelines to ensure
              consistent and cohesive brand representation across all
              touchpoints.
            </p>
            <List
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              {data.Brand_service2.map((item) => (
                <ListItem>
                  <ListItemAvatar>
                    <ThumbUpAltIcon
                      sx={{ fontSize: { xs: 20, md: 25, xl: 35 } }}
                    />
                  </ListItemAvatar>
                  <Typography
                    fontFamily="Manrope"
                    color="black"
                    sx={{
                      fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 17 },
                    }}
                  >
                    <strong>{item.title}</strong>
                  </Typography>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
        <Grid className="div2" item xs={12} sm={6}>
          <div>
            <i className="photo">
              <img src={images.branding02} alt="" />
            </i>
          </div>
        </Grid>
      </Grid>
    </div>
    {/* service3 - Brand Messaging and Voice */}
    <div className="illumin8__services-content illumin8__wrapper section__padding">
      <Grid
        container
        spacing={{ xs: 0, sm: 8 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          <div>
            <i className="photo">
              <img src={images.branding03} alt="" />
            </i>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="illumin8__wrapper_info">
            <h1 className="illumin8__services__header-h1">
              <strong>Brand Messaging and Voice</strong>
            </h1>
            <p className="illumin8__p__opensans">
              Effective communication is at the heart of successful branding. We
              help define your brand's unique voice and craft compelling
              messaging that resonates with your target audience. From taglines
              and brand stories to brand voice guidelines, we ensure your
              messaging aligns with your brand strategy and creates a strong
              emotional connection.
            </p>

            <List
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              {data.Brand_service3.map((item) => (
                <ListItem>
                  <ListItemAvatar>
                    <ThumbUpAltIcon
                      sx={{ fontSize: { xs: 20, md: 25, xl: 35 } }}
                    />
                  </ListItemAvatar>
                  <Typography
                    fontFamily="Manrope"
                    color="black"
                    sx={{
                      fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 17 },
                    }}
                  >
                    <strong>{item.title}</strong>
                  </Typography>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      </Grid>
    </div>
    {/* service4 - Brand Collateral Design */}
    <div className="illumin8__services-content illumin8__wrapper section__padding">
      <Grid
        container
        spacing={{ xs: 0, sm: 8 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid className="div1" item xs={12} sm={6}>
          <div id="div1" className="illumin8__wrapper_info">
            <h1 className="illumin8__services__header-h1">
              <strong>Brand Collateral Design</strong>
            </h1>
            <p className="illumin8__p__opensans">
              Consistency is key in brand representation. We create impactful
              brand collateral, including business cards, letterheads,
              brochures, and presentations, that reflect your brand's visual
              identity and messaging. Our designs ensure a professional and
              cohesive brand presence across all offline and online channels.
            </p>

            <List
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              {data.Brand_service4.map((item) => (
                <ListItem>
                  <ListItemAvatar>
                    <ThumbUpAltIcon
                      sx={{ fontSize: { xs: 20, md: 25, xl: 35 } }}
                    />
                  </ListItemAvatar>
                  <Typography
                    fontFamily="Manrope"
                    color="black"
                    sx={{
                      fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 17 },
                    }}
                  >
                    <strong>{item.title}</strong>
                  </Typography>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
        <Grid className="div2" item xs={12} sm={6}>
          <div>
            <i className="photo">
              <img src={images.branding04} alt="" />
            </i>
          </div>
        </Grid>
      </Grid>
    </div>
    {/* service5 - Brand Guidelines and Documentation */}
    <div className="illumin8__services-content illumin8__wrapper section__padding">
      <Grid
        container
        spacing={{ xs: 0, sm: 8 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          <div>
            <i className="photo">
              <img src={images.branding05} alt="" />
            </i>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="illumin8__wrapper_info">
            <h1 className="illumin8__services__header-h1">
              <strong>Brand Guidelines </strong>
            </h1>
            <p className="illumin8__p__opensans">
              To maintain brand consistency, we provide comprehensive brand
              guidelines and documentation. These guidelines outline the dos and
              don'ts of brand representation, including logo usage, typography,
              colour palettes, and imagery guidelines. They serve as a reference
              for internal teams and external partners to maintain a unified
              brand image.
            </p>

            <List
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              {data.Brand_service5.map((item) => (
                <ListItem>
                  <ListItemAvatar>
                    <ThumbUpAltIcon
                      sx={{ fontSize: { xs: 20, md: 25, xl: 35 } }}
                    />
                  </ListItemAvatar>
                  <Typography
                    fontFamily="Manrope"
                    color="black"
                    sx={{
                      fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 17 },
                    }}
                  >
                    <strong>{item.title}</strong>
                  </Typography>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      </Grid>
    </div>
    {/* service6 - Brand Audit and Consultation */}
    <div className="illumin8__services-content illumin8__wrapper section__padding">
      <Grid
        container
        spacing={{ xs: 0, sm: 8 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid className="div1" item xs={12} sm={6}>
          <div id="div1" className="illumin8__wrapper_info">
            <h1 className="illumin8__services__header-h1">
              <strong>Brand Audit & Consultation</strong>
            </h1>
            <p className="illumin8__p__opensans">
              Brands need periodic evaluation and refinement. Our branding
              services include brand audits and consultations to assess the
              effectiveness of your existing brand strategy and identity. We
              provide insights and recommendations to strengthen your brand's
              impact, relevance, and differentiation, helping you stay ahead of
              the competition.
            </p>

            <List
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              {data.Brand_service6.map((item) => (
                <ListItem>
                  <ListItemAvatar>
                    <ThumbUpAltIcon
                      sx={{ fontSize: { xs: 20, md: 25, xl: 35 } }}
                    />
                  </ListItemAvatar>
                  <Typography
                    fontFamily="Manrope"
                    color="black"
                    sx={{
                      fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 17 },
                    }}
                  >
                    <strong>{item.title}</strong>
                  </Typography>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
        <Grid className="div2" item xs={12} sm={6}>
          <div>
            <i className="photo">
              <img src={images.branding06} alt="" />
            </i>
          </div>
        </Grid>
      </Grid>
    </div>
  </div>
);

export default Branding;
