import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Grid, CssBaseline, Container } from "@mui/material";
import { images } from "../../../constants";
import MainFeaturedPost from "../MainFeaturedPost";
import BrandingMainPost from "./BrandingMainPost";
import FooterPost from "../FooterPost";

const brandingMainFeaturedPost = {
  title: "",
  image: images.brandingCSImg,
  imageText: "main image description",
};

const brandingFeaturedPosts = [
  {
    title: "- Jeff Bezos, Founder of Amazon",
    description:
      "A brand for a company is like a reputation for a person. You earn reputation by trying to do hard things well.Your brand is what people say about you when you're not in the room.",
    image: images.brandingCSImg08,
    imageLabel: "",
  },
];

const defaultTheme = createTheme();

function BrandingCaseStudy() {
  return (
    <div className="illumin8__brandingCaseStudy  illumin8__bg">
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />

        <MainFeaturedPost post={brandingMainFeaturedPost} />
        <Container maxWidth="lg">
          <Grid sx={{ mt: 3 }}>
            <BrandingMainPost />
          </Grid>
          <Grid spacing={4} sx={{ pb: 6 }}>
            {brandingFeaturedPosts.map((post) => (
              <FooterPost key={post.title} post={post} />
            ))}
          </Grid>
        </Container>
      </ThemeProvider>
    </div>
  );
}

export default BrandingCaseStudy;
