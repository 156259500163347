import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import GlobalStyles from "@mui/material/GlobalStyles";
import Container from "@mui/material/Container";
import { PriceList } from "./PriceList";




function Prices() {
  return (
    <div className="illumin8__prices" id="prices">
      <React.Fragment>
        <GlobalStyles
          styles={{
            ul: { margin: 5, padding: 5, listStyle: "none" },
          }}
        />
        <CssBaseline />

        <Container maxWidth="m" component="main">
          <Grid container spacing={5} alignItems="flex-end">
            {PriceList.map((PriceList) => (
              // Enterprise card is full width at sm breakpoint
              <Grid
                item
                key={PriceList.title}
                xs={12}
                sm={PriceList.title === "Enterprise" ? 12 : 6}
                md={4}
              >
                <Card
                  sx={{
                    bgcolor: "#e0f2f1",
                    "&:hover": {
                      background: "#b2dfdb",
                    },
                  }}
                >
                  <CardHeader
                    title={PriceList.title}
                    subheader={PriceList.subheader}
                    titleTypographyProps={{ align: "center" }}
                    action={PriceList.title === "Pro" ? <StarIcon /> : null}
                    subheaderTypographyProps={{
                      align: "center",
                    }}
                    sx={{
                      backgroundColor: (theme) =>
                        theme.palette.mode === "light"
                          ? theme.palette.grey[200]
                          : theme.palette.grey[700],
                    }}
                  />
                  <CardContent style={{ color: "black" }}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "baseline",
                        mb: 2,
                      }}
                    >
                      <Typography
                        style={{ color: "black" }}
                        component="h2"
                        variant="h3"
                        color="text.primary"
                      >
                        ${PriceList.price}
                      </Typography>
                      <Typography
                        variant="h6"
                        color="text.primary"
                      ></Typography>
                    </Box>
                    <ul>
                      {PriceList.description.map((line) => (
                        <Typography
                          sx={{ my: 2 }}
                          component="li"
                          variant="subtitle1"
                          align="center"
                          key={line}
                        >
                          {line}
                        </Typography>
                      ))}
                    </ul>
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      variant={PriceList.buttonVariant}
                      sx={{
                        borderradius: "60px",
                        backgroundColor: "#009688",
                        mt: 3,
                        mb: 2,
                      }}
                    >
                      {PriceList.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </React.Fragment>
    </div>
  );
}

export default Prices;
