import React from "react";
import { images, data } from "../../constants";
import {
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
} from "@mui/material";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import "../services.css";

const Website = () => (
  <div className="illumin8__services">
    {/* service1 - Responsive Website Design */}
    <div className="illumin8__services-content illumin8__wrapper section__padding">
      <Grid
        container
        spacing={{ xs: 0, sm: 8 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          <div>
            <i className="photo">
              <img src={images.website01} alt="" />
            </i>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="illumin8__wrapper_info">
            <h1 className="illumin8__services__header-h1">
              <strong> Responsive Website Design</strong>
            </h1>
            <p className="illumin8__p__opensans">
              In today's digital world, a strong online presence is essential
              for any business. Our branding company offers expert website
              design services that prioritize responsiveness. We create visually
              stunning and user-friendly websites that adapt seamlessly across
              all devices, ensuring an optimal browsing experience for your
              customers.
            </p>

            <List
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              {data.Website_service1.map((item) => (
                <ListItem>
                  <ListItemAvatar>
                    <ThumbUpAltIcon
                      sx={{ fontSize: { xs: 20, md: 25, xl: 35 } }}
                    />
                  </ListItemAvatar>
                  <Typography
                    fontFamily="Manrope"
                    color="black"
                    sx={{
                      fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 17 },
                    }}
                  >
                    <strong>{item.title}</strong>
                  </Typography>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      </Grid>
    </div>
    {/* service2 - Customized Website Development */}
    <div className="illumin8__services-content illumin8__wrapper section__padding">
      <Grid
        container
        spacing={{ xs: 0, sm: 8 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid className="div1" item xs={12} sm={6}>
          <div id="div1" className="illumin8__wrapper_info">
            <h1 className="illumin8__services__header-h1">
              <strong>Customized Website Development</strong>
            </h1>
            <p className="illumin8__p__opensans">
              We understand that every business is unique, and so should be its
              website. Our skilled developers work closely with you to
              understand your brand's goals and objectives, crafting customized
              websites that reflect your brand's identity and provide seamless
              functionality. We leverage the latest technologies to build
              robust, scalable, and secure websites tailored to your specific
              needs.
            </p>
            <List
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              {data.Website_service2.map((item) => (
                <ListItem>
                  <ListItemAvatar>
                    <ThumbUpAltIcon
                      sx={{ fontSize: { xs: 20, md: 25, xl: 35 } }}
                    />
                  </ListItemAvatar>
                  <Typography
                    fontFamily="Manrope"
                    color="black"
                    sx={{
                      fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 17 },
                    }}
                  >
                    <strong>{item.title}</strong>
                  </Typography>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
        <Grid className="div2" item xs={12} sm={6}>
          <div>
            <i className="photo">
              <img src={images.website02} alt="" />
            </i>
          </div>
        </Grid>
      </Grid>
    </div>
    {/* service3 - E-commerce Solutions */}
    <div className="illumin8__services-content illumin8__wrapper section__padding">
      <Grid
        container
        spacing={{ xs: 0, sm: 8 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          <div>
            <i className="photo">
              <img src={images.website03} alt="" />
            </i>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="illumin8__wrapper_info">
            <h1 className="illumin8__services__header-h1">
              <strong>E-commerce Solutions</strong>
            </h1>
            <p className="illumin8__p__opensans">
              If you're looking to establish or enhance your online store, our
              branding company has got you covered. We specialize in developing
              comprehensive e-commerce solutions that enable you to showcase
              your products or services, streamline the purchasing process, and
              provide a seamless shopping experience for your customers. From
              inventory management to secure payment gateways, we ensure a
              smooth and efficient online selling platform.
            </p>

            <List
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              {data.Website_service3.map((item) => (
                <ListItem>
                  <ListItemAvatar>
                    <ThumbUpAltIcon
                      sx={{ fontSize: { xs: 20, md: 25, xl: 35 } }}
                    />
                  </ListItemAvatar>
                  <Typography
                    fontFamily="Manrope"
                    color="black"
                    sx={{
                      fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 17 },
                    }}
                  >
                    <strong>{item.title}</strong>
                  </Typography>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      </Grid>
    </div>

    {/* service4 - Mock Ups */}
    <div className="illumin8__services-content illumin8__wrapper section__padding">
      <Grid
        container
        spacing={{ xs: 0, sm: 8 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid className="div1" item xs={12} sm={6}>
          <div id="div1" className="illumin8__wrapper_info">
            <h1 className="illumin8__services__header-h1">
              <strong>Mock Ups</strong>
            </h1>
            <p className="illumin8__p__opensans">
              Discover the power of visualizing your ideas with our Mock Ups
              service. From websites to packaging designs, we transform concepts
              into stunning, lifelike representations. With meticulous attention
              to detail and a seamless user experience, our Mock Ups help you
              showcase your vision, impress clients, and bring your projects to
              life.
            </p>

            <List
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              {data.Website_service4.map((item) => (
                <ListItem>
                  <ListItemAvatar>
                    <ThumbUpAltIcon
                      sx={{ fontSize: { xs: 20, md: 25, xl: 35 } }}
                    />
                  </ListItemAvatar>
                  <Typography
                    fontFamily="Manrope"
                    color="black"
                    sx={{
                      fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 17 },
                    }}
                  >
                    <strong>{item.title}</strong>
                  </Typography>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
        <Grid className="div2" item xs={12} sm={6}>
          <div>
            <i className="photo">
              <img src={images.website04} alt="" />
            </i>
          </div>
        </Grid>
      </Grid>
    </div>

    {/* service5 - Prototyping */}
    <div className="illumin8__services-content illumin8__wrapper section__padding">
      <Grid
        container
        spacing={{ xs: 0, sm: 8 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          <div>
            <i className="photo">
              <img src={images.website05} alt="" />
            </i>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="illumin8__wrapper_info">
            <h1 className="illumin8__services__header-h1">
              <strong>Prototyping</strong>
            </h1>
            <p className="illumin8__p__opensans">
              With our prototyping service, we turn your visionary concepts into
              tangible prototypes, bringing your ideas to life. Our skilled team
              utilizes cutting-edge technology and expertise to create
              functional prototypes that showcase your product's potential.
              Experience innovation in action and accelerate your path to
              success with our prototyping solutions.
            </p>

            <List
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              {data.Website_service5.map((item) => (
                <ListItem>
                  <ListItemAvatar>
                    <ThumbUpAltIcon
                      sx={{ fontSize: { xs: 20, md: 25, xl: 35 } }}
                    />
                  </ListItemAvatar>
                  <Typography
                    fontFamily="Manrope"
                    color="black"
                    sx={{
                      fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 17 },
                    }}
                  >
                    <strong>{item.title}</strong>
                  </Typography>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      </Grid>
    </div>
  </div>
);

export default Website;
