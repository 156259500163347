import React from "react";
import { AboutUs } from "../../containers";
import { about01, about02, about03, about04, about05 } from "./imports";
import { BsArrowLeftShort, BsArrowRightShort } from "react-icons/bs";
import OurBrand from "../../containers/aboutUs/OurBrand";
import "../about/about.css";

export default function About() {
  // * images Gallery * //
  const scrollRef = React.useRef(null);

  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === "left") {
      current.scrollLeft -= 300;
    } else {
      current.scrollLeft += 300;
    }
  };
  //
  return (
    <div className="illumin8__about illumin8__bg">
      <div className="illumin8__wrapper illumin8__gallery  section__padding illumin8__bgy">
        <div className="illumin8__gallery-content">
          <h1 style={{ marginTop: "4rem", fontSize: "70px", color: "black" }}>
            Your{" "}
            <span
              className="responsive"
              style={{ fontSize: "80px", color: "#ffc107" }}
            >
              Light{" "}
            </span>
            at the End of the Tunnel
          </h1>
        </div>
        <div className="illumin8__gallery-images">
          <div className="illumin8__gallery-images_container" ref={scrollRef}>
            {[about01, about02, about03, about04, about05].map(
              (image, index) => (
                <div
                  className="illumin8__gallery-images_card flex__center"
                  key={`gallery_image-${index + 1}`}
                >
                  <img src={image} alt="gallery_image" />
                </div>
              )
            )}
          </div>
          <div className="illumin8__gallery-images_arrows">
            <BsArrowLeftShort
              className="illumin8__gallery__arrow-icon"
              onClick={() => scroll("left")}
            />
            <BsArrowRightShort
              className="illumin8__gallery__arrow-icon"
              onClick={() => scroll("right")}
            />
          </div>
        </div>
      </div>

      <AboutUs />

      <div className="illumin8__aboutPage illumin8__wrapper illumin8__bgy section__padding">
        <div className="illumin8__wrapper_info">
          <h1
            className="illumin8__headtext__cormorant"
            style={{ margin: "2rem 0" }}
          >
            <strong>OUR STORY </strong>
          </h1>

          <div className="illumin8__laurels_awards">
            <p className="illumin8__p__opensans">
              <strong>
                Solutions illumin8 was born from a passion for helping small
                businesses shine.
              </strong>{" "}
              The founders, Hui and Yasser, saw too many small business owners
              struggling to create a website that accurately reflected their
              brand and effectively communicated what they had to offer. They
              knew that every small business deserves a professional online
              presence, and they were determined to make that a reality.
              <br />
              <br /> At Illumin8, we understand the hard work and dedication
              that goes into running a small business.{" "}
              <strong>
                We've seen the passion and the drive of small business owners,
                and we're inspired by their stories.
              </strong>{" "}
              That's why we've made it our mission to help small businesses
              shine online, and to bring their unique stories to life through
              their websites. <br />
              <br />
              Our team of experts works closely with each and every one of our
              clients, taking the time to get to know their brand and their
              vision.
              <strong>
                {" "}
                We believe that creating a website should be a collaborative
                process, and that every small business deserves a website that
                truly reflects who they are.{" "}
              </strong>{" "}
              <br />
              <br />
              Our values of <strong> authenticity </strong> ,{" "}
              <strong>transparency</strong>, and a <strong>passion</strong> for
              helping others drive everything we do at Illumin8. We're more than
              just a website design company; we're a team of individuals who are
              dedicated to making a positive impact on the small business
              community. <br />
              <br />
              So if you're a small business owner looking to create a website
              that accurately reflects your brand and effectively communicates
              what you have to offer, we invite you to join us at Illumin8.{" "}
              <em>
                Let us illuminate your way and bring your unique story to life
                online!
              </em>
            </p>
          </div>
        </div>
      </div>

      <OurBrand />
    </div>
  );
}
