import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { images } from "../../constants";
import Sidebar from "./Sidebar";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Grid, Container, Box, Paper, Typography } from "@mui/material";
import blogArticleImg6 from "../../assets/homePage/blogArticleImg6.jpg";

const post06Sidebar = {
  title: "SEO Matters ",
  description:
    "Look, the web's a crowded place. If you want your website to stand out and grab attention, you need SEO. It's as simple as that. Without it, you're just another drop in the digital ocean.",
  archives: [
    {
      title: "New Digital Horizons",
      imgPath: images.blog5,
      url: "/NewDigitalHorizons",
    },
    {
      title: "Branding Strategy",
      imgPath: images.blog4,
      url: "/BrandingStrategy",
    },
    {
      title: "Our offer to you?",
      imgPath: images.blog2,
      url: "/OurOfferToYou",
    },
  ],
  social: [
    {
      name: (
        <a href="https://www.linkedin.com/company/solutions-illumin8/">
          LinkedIn
        </a>
      ),
      icon: LinkedInIcon,
    },
    {
      name: (
        <a href="https://www.facebook.com/profile.php?id=100086324772795">
          Facebook
        </a>
      ),
      icon: FacebookIcon,
    },
    {
      name: (
        <a href="https://www.instagram.com/solutions_illumin8/">Instagram</a>
      ),
      icon: InstagramIcon,
    },
  ],
};

const defaultTheme = createTheme();

function BlogArticle6() {
  return (
    <div className="illumin8__blogPost  illumin8__bg">
      <ThemeProvider theme={defaultTheme}>
        <Paper
          elevation={0}
          sx={{
            position: "relative",
            backgroundColor: "grey.800",
            color: "#fff",
            mb: 4,
            width: "100vw",
            height: "50vh",
            textAlign: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: `url(${blogArticleImg6})`,
          }}
        >
          {
            <img
              style={{ display: "none", width: "100%", height: "100%" }}
              src={blogArticleImg6}
              alt=""
            />
          }

          <Grid container justifyContent="center" alignItems="center">
            <Grid>
              <Box
                sx={{
                  position: "relative",
                  padding: { xs: "10vh", md: "30vh" },
                }}
              ></Box>
            </Grid>
          </Grid>
        </Paper>
        <Container maxWidth="lg">
          <Grid container spacing={5} sx={{ mt: 3, pb: 10 }}>
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                "& .markdown": {
                  py: 3,
                },
              }}
            >
              <Typography fontFamily="Manrope" variant="h4" fontWeight={600}>
                Why SEO Still Matters Today?
              </Typography>
              <Typography
                fontFamily="Manrope"
                variant="subtitle1"
                marginTop={4}
              >
                The internet is vast, bustling with websites vying for
                attention. In this digital age, having a website isn't enough;
                it's about making it visible. That's where SEO comes in. Without
                a solid SEO strategy, your website risks getting lost in the
                shuffle. Think of SEO as your digital spotlight, highlighting
                your presence amidst a sea of competitors. It's not just about
                ranking higher on search engines; it's about ensuring that when
                someone searches for what you offer, they find you first.
                <br />
                <br />
                <strong>What's New in SEO? </strong> <br />
                Remember the days when SEO was all about keyword stuffing? Those
                days are long gone. Today, SEO is a sophisticated game. It's
                about optimizing for voice searches as people increasingly turn
                to voice assistants. It's about ensuring your site is
                mobile-friendly since more users now browse on their phones than
                desktops. And let's not forget the ever-evolving search engine
                algorithms that keep us on our toes. Staying updated with these
                changes isn't a luxury; it's a necessity to remain relevant.
                <br />
                <br />
                <strong>The Big Deal About SEO for Businesses</strong> <br />
                SEO isn't just a technical term; it's your online brand
                ambassador. It amplifies your brand's voice, ensuring you reach
                corners of the digital world you didn't even know existed. And
                the future? It's even more exciting. We're moving towards a
                world where search engines won't just answer queries but
                anticipate them. Imagine a potential customer thinking about a
                product, and your website pops up even before they type
                anything. That's the power of future SEO, and it's a
                game-changer.
                <br />
                <br />
                <strong>The Perks of Using SEO</strong>
                <br />
                So, why should a business invest in SEO? First off, it's
                cost-effective. Instead of constantly pumping money into ads
                that might or might not get clicked, SEO targets users who are
                actively searching for your services. It's like setting up shop
                in the busiest part of town. Plus, a high rank on search engines
                isn't just about visibility; it builds trust. When users see
                your website at the top, they instinctively view your brand as
                reliable and authoritative.
                <br />
                <br />
                <strong>How We Has Got Your Back</strong>
                <br />
                At Solutions illumin8, we're more than just a service provider;
                we're your partners in the digital journey. We understand the
                nuances of the ever-changing SEO landscape. Our team is here to
                help you navigate it, from selecting the right keywords to
                crafting content that resonates with your audience. We ensure
                your site isn't just optimized but stands out. With us by your
                side, SEO isn't a challenge; it's an opportunity.
              </Typography>
            </Grid>
            <Sidebar
              title={post06Sidebar.title}
              description={post06Sidebar.description}
              archives={post06Sidebar.archives}
              social={post06Sidebar.social}
            />
          </Grid>
        </Container>
      </ThemeProvider>
    </div>
  );
}

export default BlogArticle6;
