import React from "react";
import Box from "@mui/material/Box";
import OnlineOrderCover from "./OnlineOrderCover";

function OnlineOrder() {
  return (
    <div className="illumin8__onlineOrder">
      <Box
        sx={{
          maxWidth: 400,
          maxHeight: 85,
          flexGrow: 1,
          margin: "auto",
          paddingBottom: 90,
        }}
      >
        <OnlineOrderCover />
      </Box>
    </div>
  );
}

export default OnlineOrder;
