import PropTypes from "prop-types";
import { Link } from "react-router-dom";
// @mui
import {
  Box,
  Card,
  Typography,
  Stack,
  ButtonGroup,
  Button,
} from "@mui/material";

import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import { styled } from "@mui/material/styles";
// utils
import { fCurrency } from "../utils/formatNumber";
// components
import Label from "../components/label";
import { ColorPreview } from "../components/color-utils";

// ----------------------------------------------------------------------

const StyledProductImg = styled("img")({
  top: 0,
  width: "100%",
  height: "100%",
  objectFit: "fill",
  position: "absolute",
});

// ----------------------------------------------------------------------

ShopProductCard.propTypes = {
  product: PropTypes.object,
};

export default function ShopProductCard({ product }) {
  const { name, cover, price, colors, status, priceSale } = product;

  return (
    <Card>
      <Box sx={{ pt: "100%", position: "relative" }}>
        <Box>
          {status && (
            <Label
              variant="filled"
              color={(status === "sale" && "error") || "info"}
              sx={{
                zIndex: 9,
                top: 16,
                left: 4,
                position: "absolute",
                textTransform: "uppercase",
              }}
            >
              {status}
            </Label>
          )}

          <ButtonGroup
            color="inherit"
            variant="text"
            sx={{
              color: "black",
              zIndex: 9,
              top: 16,
              right: 1,
              position: "absolute",
              textTransform: "uppercase",
            }}
          >
            <Button color="inherit" size="small">
              <FavoriteBorderIcon />
            </Button>
            <Button color="inherit" size="small">
              <AddShoppingCartIcon />
            </Button>
          </ButtonGroup>
        </Box>
        
        <Link to="/ecommerceItemDetails">
          <StyledProductImg alt={name} src={cover} />
        </Link>
      </Box>

      <Stack spacing={2} sx={{ p: 3 }}>
        <Link to="/ecommerceItemDetails" color="inherit" underline="hover">
          <Typography variant="subtitle2" noWrap>
            {name}
          </Typography>
        </Link>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <ColorPreview colors={colors} />
          <Typography variant="subtitle1">
            <Typography
              component="span"
              variant="body1"
              sx={{
                color: "text.disabled",
                textDecoration: "line-through",
              }}
            >
              {priceSale && fCurrency(priceSale)}
            </Typography>
            &nbsp;
            {fCurrency(price)}
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
