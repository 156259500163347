import * as React from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import "./onlineOrder.css";

function OnlineOrderNavbar() {
  return (
    <div className="illumin8__onlineOrder">
      <div className="illumin8__onlineOrderNavbar">
        <AppBar position="static">
          <Toolbar sx={{ bgcolor: "black" }} variant="dense">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 1 }}
            >
              <Link to="/onlineOrder">
                <ArrowBackIosNewIcon />
              </Link>
            </IconButton>
            <Typography color="inherit" component="div">
              Back
            </Typography>

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "flex", md: "flex" } }}>
              <IconButton
                size="large"
                aria-label="show 4 new mails"
                color="inherit"
              >
                <Badge badgeContent={8} color="error">
                  <ShoppingCartIcon />
                </Badge>
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
      </div>
    </div>
  );
}

export default OnlineOrderNavbar;
