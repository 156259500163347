import React from "react";
import { Link } from "react-router-dom";
import {
  Card,
  Box,
  Button,
  CardMedia,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { clothing1, clothing2, clothing3, clothing4 } from "./imports";

const itemClothing = [
  {
    description: "Dress",
    price: "C$ 100.99",
    imgPath: clothing1,
    url: "/ecommerceItemDetails",
  },
  {
    description: "Dress",
    price: "C$ 100.99",
    imgPath: clothing2,
    url: "/ecommerceItemDetails",
  },
  {
    description: "Dress",
    price: "C$ 100.99",
    imgPath: clothing3,
    url: "/ecommerceItemDetails",
  },
  {
    description: "Dress",
    price: "C$ 100.99",
    imgPath: clothing4,
    url: "/ecommerceItemDetails",
  },
];

function EcommerceSimilarProducts() {
  return (
    <div className="illumin8__ecommerceSimilarProducts">
      <Typography
        sx={{
          fontSize: { xs: 34, md: 44 },
          fontFamily: "monospace",
          fontWeight: 700,
          color: "black",
          textDecoration: "none",
          textAlign: "center",
          overflow: "auto",
          p: 5,
        }}
      >
        SIMILAR PRODUCTS
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: { xs: "wrap", md: "noWrap" },
          overflow: "auto",
          "& > :not(style)": {
            m: 1,
            mb: 4,
            maxWidth: "100%",
            width: 300,
            height: 500,
          },
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        {itemClothing.map((item) => (
          <Card>
            <Link to={item.url}>
              <CardMedia
                component="img"
                height="75%"
                image={item.imgPath}
                alt="clothing1"
              />
            </Link>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {item.description}
              </Typography>
              <CardActions>
                <Typography variant="body1" color="text.secondary">
                  {item.price}
                  <Button sx={{ ml: 6 }} size="small">
                    <FavoriteIcon />
                  </Button>
                  <Button size="small">
                    <ShoppingCartIcon />
                  </Button>
                </Typography>
              </CardActions>
            </CardContent>
          </Card>
        ))}
      </Box>
    </div>
  );
}

export default EcommerceSimilarProducts;
