import React from "react";

import { SubHeading } from "../../components";
import { images } from "../../constants";
import "./Chef.css";

const Chef = () => (
  <div className="app__chef app__wrapper section__padding">
    <div className="app__wrapper_img app__wrapper_img-reverse">
      <img src={images.chef} alt="chef_image" />
    </div>
    <div className="app__wrapper_info">
      <SubHeading title="Chef's intro" />
      <h1 className="headtext__cormorant">John Smith</h1>

      <div className="app__chef-content">
        <p className="p__opensans">
          Meet our head chef, John Smith. With over 15 years of experience in
          the culinary industry, Chef John brings a wealth of knowledge and
          expertise to our kitchen. He is passionate about using fresh,
          locally-sourced ingredients to create innovative and delicious dishes.
          <br />
          <br />
          He has trained under some of the most renowned chefs in the world and
          has a talent for combining traditional techniques with modern flavors.
          His dishes are not only beautiful but also delicious and full of
          flavor. He is constantly pushing the boundaries of culinary arts, and
          his dedication to his craft is evident in every dish he creates.
        </p>
      </div>
    </div>
  </div>
);

export default Chef;
