import React from "react";
import EcommerceBanner from "./EcommerceBanner";
import EcommerceNavbar from "./EcommerceNavbar";
import EcommerceItems from "./EcommerceItems";
import EcommerceFooterBanner from "./EcommerceFooterBanner";
import EcommerceFooter from "./EcommerceFooter";

function Ecommerce() {
  return (
    <div className="illumin8__Ecommerce">
      <EcommerceNavbar />
      <EcommerceBanner />
      <EcommerceItems />
      <EcommerceFooterBanner />
      <EcommerceFooter />
    </div>
  );
}

export default Ecommerce;
