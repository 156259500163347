import * as React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, CardMedia } from "@mui/material";
import { images } from "../../../../constants";

function DeliciousMainPost() {
  return (
    <Grid
      item
      xs={12}
      md={8}
      sx={{
        "& .markdown": {
          py: 3,
        },
      }}
    >
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h5"
        fontWeight={600}
        marginTop={8}
      >
        Delicious Restaurant & Bar - Web Design & Development
      </Typography>

      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="subtitle1"
        fontWeight={550}
        marginTop={4}
      >
        Delicious Restaurant & Bar, a popular dining establishment located in
        the heart of a bustling city, approached our web design and development
        agency with the goal of enhancing its online presence, improving
        customer engagement, and streamlining its reservation and online order
        processes. The client's objectives were to create an inviting and
        user-friendly website that would reflect the ambiance of the restaurant
        while providing efficient reservation and online ordering
        functionalities.
      </Typography>
      <CardMedia
        component="img"
        sx={{ height: "100%", mt: 4, borderRadius: "15px" }}
        image={images.deliciousCSImg02}
      />
      <Typography
        fontFamily="Manrope"
        variant="h5"
        fontWeight={550}
        marginTop={4}
      >
        Challenges
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        1. User Experience Enhancement
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        The existing website lacked an intuitive user interface, making it
        difficult for visitors to navigate the menu, make reservations, and
        place online orders.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        2. Reservation Management
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        The client needed a robust reservation system that would allow customers
        to book tables easily, while also enabling the restaurant staff to
        manage reservations effectively.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        3. Online Ordering System
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        The client wanted to integrate an online ordering system that would
        facilitate takeout and delivery orders, allowing customers to enjoy
        Delicious' offerings in the comfort of their homes.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        4. Mobile Responsiveness
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        With a growing number of users accessing websites on mobile devices, it
        was crucial to ensure the website was fully responsive and provided a
        seamless experience across various screen sizes.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h5"
        fontWeight={550}
        marginTop={4}
      >
        Solution
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        fontWeight={550}
        marginTop={2}
      >
        Our team designed and developed a comprehensive solution that addressed
        the client's challenges while aligning with their brand identity and
        customer needs.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        1. Website Redesign
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        We conducted a thorough analysis of Delicious' branding and atmosphere
        to create a visually appealing and modern design that resonated with the
        restaurant's ambiance. The website featured high-quality images of the
        restaurant's interior, dishes, and beverages, creating an immersive
        experience for users.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        2. Reservation System
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        We implemented a user-friendly reservation system that allowed customers
        to select the desired date, time, and party size. The system displayed
        real-time availability and sent instant confirmation emails to
        customers, enhancing their confidence in the reservation process.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        3. Online Ordering Functionality
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        Our team integrated an efficient online ordering system, enabling
        customers to browse the menu, select items, customize orders, and choose
        between pickup or delivery. The system calculated accurate delivery
        times and provided order status updates, enhancing transparency and
        customer satisfaction.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        4. Mobile Responsiveness
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        The website was developed with a mobile-first approach, ensuring
        seamless navigation and optimal user experience across various devices.
        The mobile version retained all essential features, making it easy for
        customers to make reservations and place orders on the go.
      </Typography>

      <CardMedia
        component="img"
        sx={{
          height: "100%",
          mt: 4,
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
        }}
        image={images.deliciousCSImg08}
      />

      <CardMedia
        component="img"
        sx={{
          height: "100%",
          borderBottomLeftRadius: "15px",
          borderBottomRightRadius: "15px",
        }}
        image={images.deliciousCSImg09}
      />
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h5"
        fontWeight={600}
        marginTop={4}
      >
        Results
      </Typography>
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        * Improved User Engagement
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        The revamped website led to increased user engagement, longer time spent
        on the site, and lower bounce rates, as users found the new design more
        engaging and user-friendly.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        * Streamlined Reservations
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        The new reservation system streamlined the booking process, resulting in
        an increase in reservation requests and a higher conversion rate.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        * Increased Online Orders
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        The online ordering system led to a notable increase in takeout and
        delivery orders, catering to customers' preferences for convenience.
      </Typography>
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        * Enhanced Brand Perception
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        The visually appealing website design and seamless functionalities
        positively impacted the brand perception of Delicious Restaurant & Bar.
      </Typography>

      <CardMedia
        component="img"
        sx={{
          height: "100%",
          mt: 6,
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
        }}
        image={images.deliciousCSImg03}
      />
      <CardMedia
        component="img"
        sx={{ height: "100%" }}
        image={images.deliciousCSImg05}
      />

      <CardMedia
        component="img"
        sx={{
          height: "100%",
          borderBottomLeftRadius: "15px",
          borderBottomRightRadius: "15px",
        }}
        image={images.deliciousCSImg06}
      />
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h5"
        fontWeight={600}
        marginTop={4}
      >
        Conclusion
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        fontWeight={550}
        marginTop={4}
        paddingBottom={10}
      >
        Through the collaborative efforts of our web design and development
        team, Delicious Restaurant & Bar achieved its objectives of enhancing
        its online presence, simplifying reservation management, and enabling
        online orders. The comprehensive solution not only improved customer
        experience but also contributed to the restaurant's growth and success
        in the competitive food and beverage industry.
      </Typography>
    </Grid>
  );
}
DeliciousMainPost.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
};

export default DeliciousMainPost;
