import React from "react";
import GoogleMapReact from "google-map-react";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const AnyReactComponent = ({ text }) => <div>{text}</div>;

function MenuGoogleMap() {
  const defaultProps = {
    center: {
      lat: 45.495417,
      lng: 73.578669,
    },
    zoom: 11,
  };

  return (
    <div className="illumin8__MenuGoogleMap">
      <div style={{ height: "35vh", width: "50vh" }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: "" }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <AnyReactComponent lat={45.495417} lng={73.578669} text="LOCATION">
            <LocationOnIcon />
          </AnyReactComponent>
        </GoogleMapReact>
      </div>
    </div>
  );
}

export default MenuGoogleMap;
