import React from "react";
import { SubHeading } from "../../components";
import { images } from "../../constants";
import "./Header.css";

const Header = () => (
  <div className="app__header app__wrapper section__padding" id="home">
    <div className="app__wrapper_info">
      <SubHeading title="Welcome to our restaurant!" />
      <h1 className="app__header-h1">New experience!</h1>
      <p className="p__opensans" style={{ margin: "2rem 0" }}>
        We offer a diverse menu of delicious dishes. For entrees, try our crispy
        calamari or our refreshing caprese salad. Our main courses include a
        variety of options such as our succulent grilled steak, our classic
        spaghetti Bolognese, our hearty fish and chips, or our flavorful
        vegetable curry.
      </p>
      <button type="button" className="custom__button">
        Explore Menu
      </button>
    </div>

    <div className="app__wrapper_img">
      <img src={images.welcome} alt="header_img" />
    </div>
  </div>
);

export default Header;
