import * as React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, CardMedia } from "@mui/material";
import { images } from "../../../../constants";

function PastryMainPost() {
  return (
    <Grid
      item
      xs={12}
      md={8}
      sx={{
        "& .markdown": {
          py: 3,
        },
      }}
    >
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h5"
        fontWeight={600}
        marginTop={8}
      >
        Pastry Shop - Web Design & Development
      </Typography>

      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="subtitle1"
        fontWeight={550}
        marginTop={4}
      >
        Pastry Shop is a boutique bakery known for its exquisite and delectable
        range of pastries, cakes, and baked goods. With a strong local presence
        and a growing customer base, Pastry Shop aimed to enhance its online
        presence to reach a wider audience and offer the convenience of online
        ordering to its customers.
      </Typography>
      <CardMedia
        component="img"
        sx={{ height: "100%", mt: 4, borderRadius: "15px" }}
        image={images.pastryCSImg01}
      />
      <Typography
        fontFamily="Manrope"
        variant="h5"
        fontWeight={550}
        marginTop={4}
      >
        Project Goals
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        1. Enhanced Online Presence
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        Create a visually appealing and user-friendly website that reflects the
        brand's aesthetic and values, thereby attracting and engaging visitors.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        2. Online Order Functionality
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        Develop a seamless online ordering system that allows customers to
        browse the menu, select items, customize their orders, and make secure
        online payments.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        3. Mobile Responsiveness
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        Ensure the website is fully responsive across all devices to provide a
        consistent and enjoyable user experience.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        4. User Experience
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        Design an intuitive navigation structure and user interface to make it
        easy for customers to find information and place orders.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h5"
        fontWeight={550}
        marginTop={4}
      >
        Design and Development Process
      </Typography>

      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        1. Discovery and Research
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        <ul>
          <li>
            Conducted a thorough analysis of the brand, its values, and target
            audience to understand the design direction.
          </li>
          <li>
            Researched competitors to identify opportunities for differentiation
            and improvement.
          </li>
        </ul>
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        2. Wireframing and Prototyping
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        <ul>
          <li>
            Created wireframes and prototypes to outline the website's
            structure, navigation, and overall layout.
          </li>
          <li>
            Incorporated client feedback to refine the wireframes before moving
            to the design phase.
          </li>
        </ul>
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        3. Visual Design
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        <ul>
          <li>
            Designed the website's interface, incorporating the brand's color
            palette, typography, and imagery to maintain consistency with the
            offline identity.
          </li>
          <li>
            Used high-quality images of the bakery's products to showcase their
            quality and allure.
          </li>
        </ul>
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        4. Development:
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        <ul>
          <li>
            Built the website using modern web development technologies such as
            HTML5, CSS3, and JavaScript.
          </li>
          <li>
            Integrated a content management system (CMS) to enable easy content
            updates by the client.
          </li>
          <li>
            Implemented a responsive design approach to ensure optimal
            performance on various devices and screen sizes.
          </li>
        </ul>
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        5. Online Ordering Functionality
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        <ul>
          <li>
            Developed a custom online ordering system that allowed customers to
            browse the menu, select items, adjust quantities, and add special
            instructions.
          </li>
          <li>
            Integrated a secure payment gateway to facilitate online payments.
          </li>
          <li>
            Included a user-friendly cart feature that displays selected items
            and their prices.
          </li>
        </ul>
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        6. Testing and Quality Assurance
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        <ul>
          <li>
            Conducted thorough testing across different browsers and devices to
            identify and resolve any compatibility issues.
          </li>
          <li>
            Checked the ordering process to ensure smooth functionality and
            accurate calculations.
          </li>
          <li>
            Implemented SSL encryption to secure customer data during online
            transactions.
          </li>
        </ul>
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        7. Launch and Deployment
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        <ul>
          <li>
            Launched the website on a secure and reliable hosting platform.
          </li>
          <li>
            Ensured that all technical aspects were properly configured for
            optimal performance.
          </li>
        </ul>
      </Typography>

      <CardMedia
        component="img"
        sx={{
          height: "100%",
          mt: 4,
          borderRadius: "15px",
        }}
        image={images.pastryCSImg02}
      />

      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h5"
        fontWeight={600}
        marginTop={4}
      >
        Results and Outcomes
      </Typography>

      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        * Enhanced Online Presence
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        The new website perfectly captured the essence of the brand, attracting
        more visitors and enhancing the bakery's online image.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        * Online Ordering Success
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        The seamless online ordering system increased customer convenience,
        leading to a significant uptick in online orders and sales.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        * Positive User Experience
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        The user-friendly design and intuitive navigation made it easy for
        customers to find information and place orders effortlessly.
      </Typography>
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        * Mobile Responsiveness
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        The fully responsive design ensured a consistent and engaging experience
        across devices, contributing to higher user satisfaction.
      </Typography>

      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h5"
        fontWeight={600}
        marginTop={4}
      >
        Conclusion
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        fontWeight={550}
        marginTop={4}
        paddingBottom={10}
      >
        The collaboration between Pastry Shop and the web design and development
        team resulted in a visually captivating website that successfully
        integrated online ordering functionality. The project achieved its goals
        of enhancing the bakery's online presence, facilitating online orders,
        and delivering a satisfying user experience. The new website positioned
        Pastry Shop as a modern and customer-centric bakery, contributing to
        increased customer engagement and business growth.
      </Typography>
    </Grid>
  );
}
PastryMainPost.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
};

export default PastryMainPost;
