import React from "react";
import { Blog, Header, Possibility, StudyCases } from "../../containers";
import { Brand, Services } from "../../components";

export default function HomePage() {
  return (
    <div className="illumin8__homePage illumin8__bg">
      <Header />
      <StudyCases />
      <Services />
      <Possibility />
      <Brand />
      <Blog />
    </div>
  );
}
