import * as React from "react";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
  Stack,
} from "@mui/material";

// * App bar with responsive menu * //
const pages = [
  {
    pageTitle: (
      <Link color="white" underline="none" to="/menu">
        HOME
      </Link>
    ),
  },
  {
    pageTitle: (
      <Link color="white" underline="none" to="/menuEat">
        EAT
      </Link>
    ),
  },
  {
    pageTitle: (
      <Link color="white" underline="none" to="/menuDrink">
        DRINK
      </Link>
    ),
  },
  {
    pageTitle: (
      <Link color="white" underline="none" to="/menuReservation">
        RESERVATION
      </Link>
    ),
  },
  {
    pageTitle: (
      <Link color="white" underline="none" to="/menuOnlineOrdering">
        ONLINE ORDERING
      </Link>
    ),
  },
];

export default function MenuNavbar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <div className="illumin8__menuNavbar">
      <Box sx={{ pt: 15 }}>
        <AppBar
          position="static"
          sx={{
            backgroundColor: "#3e2723",
            height: 100,
            justifyContent: "center",
          }}
        >
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Typography
                variant="h3"
                noWrap
                component="a"
                sx={{
                  mr: 2,
                  display: { xs: "none", md: "flex" },
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  textDecoration: "none",
                }}
              >
                <Link color="inherit" underline="none" to="/menu">
                  DELICIOUS
                </Link>
              </Typography>

              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                >
                  <MenuIcon sx={{ color: "white" }} />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  {pages.map((page) => (
                    <MenuItem
                      sx={{ bgcolor: "black" }}
                      key={page}
                      onClick={handleCloseNavMenu}
                    >
                      <Typography
                        sx={{ color: "white" }}
                        variant="h6"
                        textAlign="center"
                      >
                        {page.pageTitle}
                      </Typography>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>

              <Typography
                noWrap
                component="a"
                sx={{
                  mr: 2,
                  display: { xs: "flex", md: "none" },
                  flexGrow: 1,
                  fontFamily: "monospace",
                  fontWeight: 700,
                  fontSize: 26,
                  letterSpacing: ".3rem",
                  color: "white",
                  textDecoration: "none",
                }}
              >
                <Link color="inherit" underline="none" to="/menu">
                  DELICIOUS
                </Link>
              </Typography>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: "none", md: "flex" },
                  justifyContent: "center",
                }}
              >
                {pages.map((page) => (
                  <Button
                    key={page}
                    onClick={handleCloseNavMenu}
                    sx={{
                      my: 2,
                      display: "block",
                    }}
                  >
                    <Typography
                      color="white"
                      sx={{
                        fontSize: 20,
                        fontWeight: 500,
                        textDecoration: "none",
                      }}
                      variant="h6"
                    >
                      {page.pageTitle}
                    </Typography>
                  </Button>
                ))}
              </Box>
              <Stack direction="row">
                <IconButton>
                  <FacebookIcon color="info" fontSize="large" />
                </IconButton>
                <IconButton>
                  <InstagramIcon color="info" fontSize="large" />
                </IconButton>
              </Stack>
            </Toolbar>
          </Container>
        </AppBar>
      </Box>
    </div>
  );
}
