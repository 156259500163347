import React from "react";
import { Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import { data } from "../../constants";
import { Grid, Box, Paper } from "@mui/material";
import "../servicesPage.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  padding: theme.spacing(2),
}));

function CaseStudy() {
  return (
    <div className="illumin8__caseStudy">
      <div className="illumin8__bgGD">
        <Box
          elevation={0}
          sx={{
            paddingTop: { xs: "4rem", md: "4rem", xl: "4rem" },
          }}
        >
          <h1 className="gradient__text">CASE STUDY</h1>
        </Box>
      </div>
      <div className="illumin8__wrapper illumin8__bgGD">
        <Box
          sx={{
            flexGrow: 1,
            ml: { xs: 1, md: 1, xl: 4 },
            mr: { xs: 1, md: 1, xl: 4 },
          }}
        >
          <Grid
            container
            spacing={{ xs: 2, sm:2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
          >
            {data.caseStudy.map((items, index) => (
              <Grid item xs={12} sm={4} md={4} key={index}>
                <Item>
                  <figure class="illumin8__caseStudy-card">
                    <img src={items.imgPath} alt="" />
                    <figcaption>
                      <h2>{items.title}</h2>
                      <p>{items.subtitle}</p>
                    </figcaption>
                    <Link to={items.url}>
                      <button>{items.button}</button>
                    </Link>
                  </figure>
                </Item>
              </Grid>
            ))}
          </Grid>
        </Box>
      </div>
    </div>
  );
}

export default CaseStudy;
