import * as React from "react";
import PropTypes from "prop-types";
import { Grid, Card, CardContent, CardMedia, Typography } from "@mui/material";

function FooterPost(props) {
  const { post } = props;
  return (
    <Grid item xs={12} md={6}>
      <Card sx={{ display: "flex", borderRadius: "15px" }}>
        <CardContent sx={{ flex: 1 }}>
          <Typography
            fontFamily="Manrope"
            color="black"
            variant="subtitle1"
            paragraph
            marginTop={2}
          >
            {post.description}
          </Typography>
          <Typography fontFamily="Manrope" color="black" variant="h6">
            {post.title}
          </Typography>
        </CardContent>
        <CardMedia
          component="img"
          sx={{ width: 160, display: { xs: "none", sm: "block" } }}
          image={post.image}
          alt={post.imageLabel}
        />
      </Card>
    </Grid>
  );
}
FooterPost.propTypes = {
  post: PropTypes.shape({
    date: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageLabel: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};
export default FooterPost;
