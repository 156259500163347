import React from "react";
import { images } from "../../constants";
import Grid from "@mui/material/Grid";
import "./cta.css";

export default function CTABranding() {
  return (
    <div className="illumin8__CTABranding  illumin8__wrapper section__padding illumin8__bgy">
      <Grid
        container
        spacing={{ xs: 0, sm: 8 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid className="div1" item xs={12} sm={6}>
          <div className="illumin8__wrapper_info">
            <h1 className="illumin8__headtext__cormorant">
              <strong>BRANDING </strong>
            </h1>
            <p className="illumin8__p__opensans" style={{ margin: "1rem 0" }}>
              Welcome to our premier branding service, where we empower
              businesses to make a lasting impact in today's competitive
              landscape. <br />
              <br />
              With our deep understanding of consumer behavior, we craft
              compelling<strong> brand strategies</strong> that resonate with
              your target audience. From defining your unique
              <strong> brand identity</strong> to implementing integrated
              marketing communications, we provide comprehensive solutions that
              drive <strong>brand recognition, loyalty, and growth</strong>.{" "}
              <br />
              <br />
              Trust us to be your strategic partner in elevating your brand to
              new heights.
            </p>
            <a
              href="https://m.me/100722566156091?ref=Welcome%20to%20Solutions%20illumin8%20!"
              target="client"
            >
              <button type="button" className="illumin8__button__Contained">
                Request a Proposal -&gt;
              </button>
            </a>
          </div>
        </Grid>
        <Grid className="div2" item xs={12} sm={6}>
          <div className="illumin8__wrapper_img">
            <img src={images.brandingHP} alt="header_img" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
