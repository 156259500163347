import React from "react";
import { images } from "../../constants";
import Grid from "@mui/material/Grid";
import "./cta.css";

export default function CTAWeb() {
  return (
    <div className="illumin8__CTAWeb illumin8__wrapper section__padding">
      <Grid
        container
        spacing={{ xs: 0, sm: 8 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          <div className="illumin8__wrapper_img illumin8__wrapper_img-reverse">
            <img src={images.web} alt="header_img" />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="illumin8__wrapper_info">
            <h1 className="illumin8__headtext__cormorant">
              <strong>WEB DESIGN & DEVELOPMENT</strong>
            </h1>
            <p className="illumin8__p__opensans" style={{ margin: "1rem 0" }}>
              Have you always wanted to impress your audience with an
              <strong> {""}innovative website design</strong>? Turn your website
              into an immersive digital landscape that will stun everyone who
              lands on it! We know how to make the perfect match between you and
              your customers. We'll add the Peanut Butter to your Jelly.
              <br />
              <br />
              Our goal is to bring your vision to life and provide creative,
              custom solutions for all your marketing needs.
              <br />
              <br />
              Request a consultation with our experts to see how we can
              illuminate your way. What are you waiting for? The consultation is
              free. <strong>Let’s grab a coffee and talk!</strong>
            </p>
            <a
              href="https://m.me/100722566156091?ref=Welcome%20to%20Solutions%20illumin8%20!"
              target="client"
            >
              <button type="button" className="illumin8__button__Contained">
                Request a Proposal -&gt;
              </button>
            </a>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
