import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Grid, CssBaseline, Container } from "@mui/material";
import { images } from "../../../../constants";
import MainFeaturedPost from "../../MainFeaturedPost";
import FeaturedPost from "../../FeaturedPost";
import PetHomeMainPost from "./PetHomeMainPost";

const PetHomeMainFeaturedPost = {
  title: "",
  image: images.webDesignCSImg01,
  imageText: "main image description",
};

const PetHomeFeaturedPosts = [
  {
    url: "/deliciousCaseStudy",
    title: "Delicious Restaurant & Bar",
    description: " Delicious Restaurant & Bar where providing efficient reservation and online ordering functionalities experence.",
    image: images.restaurantCaseStudy,
    imageLabel: "",
  },
  {
    url: "/pastryCaseStudy",
    title: "Pastry Shop",
    description:
      "Pastry Shop is a boutique bakery known for its exquisite and delectable range of pastries, cakes, and baked goods.",
    image: images.buffetCaseStudy,
    imageLabel: "",
  },
];

const defaultTheme = createTheme();

function PetHomeCaseStudy() {
  return (
    <div className="illumin8__petHomeCaseStudy illumin8__bg">
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />

        <MainFeaturedPost post={PetHomeMainFeaturedPost} />
        <Container maxWidth="lg">
          <Grid sx={{ mt: 3 }}>
            <PetHomeMainPost />
          </Grid>
          <Grid container spacing={4} sx={{ pb: 10 }}>
            {PetHomeFeaturedPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} />
            ))}
          </Grid>
        </Container>
      </ThemeProvider>
    </div>
  );
}

export default PetHomeCaseStudy;
