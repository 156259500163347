import React from "react";
import { Link } from "react-router-dom";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import {
  bag1,
  bag2,
  bag3,
  accessories1,
  accessories2,
  accessories3,
  clothing1,
  clothing2,
  clothing3,
  shoes1,
  shoes2,
  shoes3,
} from "./imports";
import "./Ecommerce.css";

const itemClothing = [
  {
    description: "Dress",
    price: "C$ 100.99",
    imgPath: clothing1,
    url: "/ecommerceItemDetails",
  },
  {
    description: "Dress",
    price: "C$ 100.99",
    imgPath: clothing2,
    url: "/ecommerceItemDetails",
  },
  {
    description: "Dress",
    price: "C$ 100.99",
    imgPath: clothing3,
    url: "/ecommerceItemDetails",
  },
];

const itemShoes = [
  {
    description: "Shoes",
    price: "C$ 200.99",
    imgPath: shoes1,
    url: "/ecommerceItemDetails",
  },
  {
    description: "Shoes",
    price: "C$ 200.99",
    imgPath: shoes2,
    url: "/ecommerceItemDetails",
  },
  {
    description: "Shoes",
    price: "C$ 200.99",
    imgPath: shoes3,
    url: "/ecommerceItemDetails",
  },
];

const itemBags = [
  {
    description: "Bag",
    price: "C$ 300.99",
    imgPath: bag1,
    url: "/ecommerceItemDetails",
  },
  {
    description: "Bag",
    price: "C$ 300.99",
    imgPath: bag2,
    url: "/ecommerceItemDetails",
  },
  {
    description: "Bag",
    price: "C$ 300.99",
    imgPath: bag3,
    url: "/ecommerceItemDetails",
  },
];
const itemAccessories = [
  {
    description: "Accessories",
    price: "C$ 400.99",
    imgPath: accessories1,
    url: "/ecommerceItemDetails",
  },
  {
    description: "Accessories",
    price: "C$ 400.99",
    imgPath: accessories2,
    url: "/ecommerceItemDetails",
  },
  {
    description: "Accessories",
    price: "C$ 400.99",
    imgPath: accessories3,
    url: "/ecommerceItemDetails",
  },
];

function EcommerceItems() {
  return (
    <div className="illumin8__ecommerceItems">
      <Box
        sx={{
          display: "flex",
          flexWrap: { xs: "wrap", md: "noWrap" },
          overflow: "auto",
          "& > :not(style)": {
            m: 1,
            width: 300,
            height: 500,
          },
          justifyContent: "center",
          mt: 6,
        }}
      >
        <div className="illumin8__ecommerceItems-clothing">
          <Card sx={{ height: "100%", bgcolor: "black" }}>
            <CardContent sx={{ mt: 20, color: "white" }}>
              <Typography variant="h6">New out now</Typography>
              <Typography variant="h4">CLOTHING</Typography>
            </CardContent>
            <CardActions>
              <Link to="/ecommerceProducts">
                <Button
                  sx={{ color: "#ffffff" }}
                  size="large"
                  variant="outlined"
                >
                  SHOP ALL
                </Button>
              </Link>
            </CardActions>
          </Card>
        </div>

        {itemClothing.map((item) => (
          <Card>
            <Link to={item.url}>
              <CardMedia
                component="img"
                height="75%"
                image={item.imgPath}
                alt="clothing"
              />
            </Link>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {item.description}
              </Typography>
              <CardActions>
                <Typography variant="body1" color="text.secondary">
                  {item.price}
                  <Button sx={{ ml: 6 }} size="small">
                    <FavoriteIcon />
                  </Button>
                  <Button size="small">
                    <ShoppingCartIcon />
                  </Button>
                </Typography>
              </CardActions>
            </CardContent>
          </Card>
        ))}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexWrap: { xs: "wrap", md: "noWrap" },
          "& > :not(style)": {
            m: 1,
            width: 300,
            height: 500,
          },
          justifyContent: "center",
          mt: 2,
        }}
      >
        <div className="illumin8__ecommerceItems-shoes">
          <Card sx={{ height: "100%", bgcolor: "black" }}>
            <CardContent sx={{ mt: 20, color: "white" }}>
              <Typography variant="h6">New out now</Typography>
              <Typography variant="h4">SHOES</Typography>
            </CardContent>
            <CardActions>
              <Link to="/ecommerceProducts">
                <Button
                  sx={{ color: "#ffffff" }}
                  size="large"
                  variant="outlined"
                >
                  SHOP ALL
                </Button>
              </Link>
            </CardActions>
          </Card>
        </div>
        {itemShoes.map((item) => (
          <Card>
            <Link to={item.url}>
              <CardMedia
                component="img"
                height="75%"
                image={item.imgPath}
                alt="clothing1"
              />
            </Link>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {item.description}
              </Typography>
              <CardActions>
                <Typography variant="body1" color="text.secondary">
                  {item.price}
                  <Button sx={{ ml: 6 }} size="small">
                    <FavoriteIcon />
                  </Button>
                  <Button size="small">
                    <ShoppingCartIcon />
                  </Button>
                </Typography>
              </CardActions>
            </CardContent>
          </Card>
        ))}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexWrap: { xs: "wrap", md: "noWrap" },
          "& > :not(style)": {
            m: 1,
            width: 300,
            height: 500,
          },
          justifyContent: "center",
          mt: 2,
        }}
      >
        <div className="illumin8__ecommerceItems-bags">
          <Card sx={{ height: "100%", bgcolor: "black" }}>
            <CardContent sx={{ mt: 20, color: "white" }}>
              <Typography variant="h6">New out now</Typography>
              <Typography variant="h4">BAGS</Typography>
            </CardContent>
            <CardActions>
              <Link to="/ecommerceProducts">
                <Button
                  sx={{ color: "#ffffff" }}
                  size="large"
                  variant="outlined"
                >
                  SHOP ALL
                </Button>
              </Link>
            </CardActions>
          </Card>
        </div>
        {itemBags.map((item) => (
          <Card>
            <Link to={item.url}>
              <CardMedia
                component="img"
                height="75%"
                image={item.imgPath}
                alt="clothing1"
              />
            </Link>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {item.description}
              </Typography>
              <CardActions>
                <Typography variant="body1" color="text.secondary">
                  {item.price}
                  <Button sx={{ ml: 6 }} size="small">
                    <FavoriteIcon />
                  </Button>
                  <Button size="small">
                    <ShoppingCartIcon />
                  </Button>
                </Typography>
              </CardActions>
            </CardContent>
          </Card>
        ))}
      </Box>

      <Box
        sx={{
          display: "flex",
          flexWrap: { xs: "wrap", md: "noWrap" },
          "& > :not(style)": {
            m: 1,
            width: 300,
            height: 500,
          },
          justifyContent: "center",
          mt: 2,
          mb: 4,
        }}
      >
        <div className="illumin8__ecommerceItems-accessories">
          <Card sx={{ height: "100%", bgcolor: "black" }}>
            <CardContent sx={{ mt: 20, color: "white" }}>
              <Typography variant="h6">New out now</Typography>
              <Typography variant="h4">ACCESSORIES</Typography>
            </CardContent>
            <CardActions>
              <Link to="/ecommerceProducts">
                <Button
                  sx={{ color: "#ffffff" }}
                  size="large"
                  variant="outlined"
                >
                  SHOP ALL
                </Button>
              </Link>
            </CardActions>
          </Card>
        </div>
        {itemAccessories.map((item) => (
          <Card>
            <Link to={item.url}>
              <CardMedia
                component="img"
                height="75%"
                image={item.imgPath}
                alt="clothing1"
              />
            </Link>
            <CardContent>
              <Typography gutterBottom variant="h6" component="div">
                {item.description}
              </Typography>
              <CardActions>
                <Typography variant="body1" color="text.secondary">
                  {item.price}
                  <Button size="small">
                    <FavoriteIcon />
                  </Button>
                  <Button size="small">
                    <ShoppingCartIcon />
                  </Button>
                </Typography>
              </CardActions>
            </CardContent>
          </Card>
        ))}
      </Box>
    </div>
  );
}

export default EcommerceItems;
