import React from "react";
import { SocialMediaMarketing } from "../../containers";
import { data } from "../../constants";
import { Box, Card, CardContent, Typography } from "@mui/material";
import CaseStudy from "../caseStudy/CaseStudy";
import "../servicesPage.css";
import "../../containers/heroSlide/heroSlide.css";

function SocialMediaMarketingPage() {
  return (
    <div className="illumin8__websitePage illumin8__bg">
      <div className="illumin8__heroSlide scene illumin8__wrapper">
        <h1 className="illumin8__services__title">SOCIAL MEDIA MARKETING</h1>
        <div className="magic"></div>
        <p className="illumin8__services__subtitle">
          Social media creates communities, not markets.
        </p>
      </div>

      <SocialMediaMarketing />

      <div className="illumin8__brandingPage-standout">
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            m: 6,
          }}
        >
          <Typography
            gutterBottom
            fontFamily="Manrope"
            component="div"
            sx={{ fontSize: { xs: 22, sm: 28, md: 30, xl: 40 } }}
          >
            <strong>Why Social Media Marketing is important?</strong>
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: { xs: "wrap", md: "noWrap" },
            overflow: "auto",
            "& > :not(style)": {
              m: 1,
              width: { xs: 300, sm: 300, md: 300, lg: 310, xl: 410 },
              height: { xs: 200, sm: 260, md: 260, lg: 280, xl: 250 },
            },
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
          }}
        >
          {data.SSM_reason1.map((item) => (
            <Card sx={{ borderRadius: "1rem" }}>
              <CardContent>
                <Typography
                  fontFamily="Manrope"
                  color="black"
                  sx={{ fontSize: { xs: 14, sm: 14, md: 14, lg: 19, xl: 20 } }}
                >
                  <strong>{item.title}</strong>
                </Typography>

                <Typography
                  fontFamily="Manrope"
                  color="text.secondary"
                  marginTop={2}
                  sx={{ fontSize: { xs: 13, sm: 13, md: 13, lg: 16, xl: 17 } }}
                >
                  {item.description}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>

        <Box
          sx={{
            display: "flex",
            flexWrap: { xs: "wrap", md: "noWrap" },
            overflow: "auto",
            "& > :not(style)": {
              m: 1,
              width: { xs: 300, sm: 300, md: 300, lg: 310, xl: 410 },
              height: { xs: 200, sm: 260, md: 260, lg: 280, xl: 250 },
            },
            justifyContent: "center",
            alignItems: "center",
            mt: 2,
            pb: 20,
          }}
        >
          {data.SSM_reason2.map((item) => (
            <Card sx={{ borderRadius: "1rem" }}>
              <CardContent>
                <Typography
                  fontFamily="Manrope"
                  color="black"
                  sx={{ fontSize: { xs: 14, sm: 14, md: 14, lg: 19, xl: 20 } }}
                >
                  <strong>{item.title}</strong>
                </Typography>

                <Typography
                  fontFamily="Manrope"
                  color="text.secondary"
                  marginTop={2}
                  sx={{ fontSize: { xs: 13, sm: 13, md: 13, lg: 16, xl: 17 } }}
                >
                  {item.description}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      </div>
      <CaseStudy />
    </div>
  );
}

export default SocialMediaMarketingPage;
