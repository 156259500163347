import * as React from "react";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";
import SwipeableViews from "react-swipeable-views";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "./onlineOrder.css";
import OnlineOderItem1 from "./OnlineOderItem1";
import OnlineOderItem2 from "./OnlineOderItem2";
import OnlineOderItem3 from "./OnlineOderItem3";
import OnlineOderItem4 from "./OnlineOderItem4";
import OnlineOderItem5 from "./OnlineOderItem5";
import OnlineOderItem6 from "./OnlineOderItem6";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Button from "@mui/material/Button";
import Zoom from "@mui/material/Zoom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    "aria-controls": `action-tabpanel-${index}`,
  };
}

const fabStyle = {
  position: "sticky",
  justifyContent: "center",
  textAlign: "center",
  bottom: 26,
  width: 400,
  maxHeight: 40,
  color: "common.white",
  bgcolor: "#313131",
  "&:hover": {
    bgcolor: "#ff4603",
  },
};

function OnlineOrderMenuOption() {
  const theme = useTheme();

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const transitionDuration = {
    enter: theme.transitions.duration.enteringScreen,
    exit: theme.transitions.duration.leavingScreen,
  };

  const fabs = [
    {
      color: "inherit",
      sx: fabStyle,
      icon: <ArrowForwardIosIcon />,
      label: "CHECKOUT",
    },
    {
      color: "inherit",
      sx: fabStyle,
      icon: <ArrowForwardIosIcon />,
      label: "CHECKOUT",
    },
    {
      color: "inherit",
      sx: fabStyle,
      icon: <ArrowForwardIosIcon />,
      label: "CHECKOUT",
    },
    {
      color: "inherit",
      sx: fabStyle,
      icon: <ArrowForwardIosIcon />,
      label: "CHECKOUT",
    },
    {
      color: "inherit",
      sx: fabStyle,
      icon: <ArrowForwardIosIcon />,
      label: "CHECKOUT",
    },
    {
      color: "inherit",
      sx: fabStyle,
      icon: <ArrowForwardIosIcon />,
      label: "CHECKOUT",
    },
  ];

  return (
    <React.Fragment>
      <div className="illumin8__onlineOrderMenuOption">
        <Box
          sx={{
            bgcolor: "#eeeeee",
            position: "relative",
            overflow: "auto",
            width: 400,
            maxHeight: 630,
            flexGrow: 1,
            justifyContent: "center",
          }}
        >
          <AppBar position="sticky" color="default">
            <Tabs
              value={value}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="inherit"
              aria-label="action tabs example"
              variant="scrollable"
              scrollButtons={false}
            >
              <Tab label="Croissants" {...a11yProps(0)} />
              <Tab label="Pies" {...a11yProps(1)} />
              <Tab label="Danishes" {...a11yProps(2)} />
              <Tab label="Macarons" {...a11yProps(3)} />
              <Tab label="Strudels" {...a11yProps(4)} />
              <Tab label="Cannoli" {...a11yProps(5)} />
            </Tabs>
          </AppBar>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            <TabPanel value={value} index={0} dir={theme.direction}>
              <OnlineOderItem1 />
            </TabPanel>
            <TabPanel value={value} index={1} dir={theme.direction}>
              <OnlineOderItem2 />
            </TabPanel>
            <TabPanel value={value} index={2} dir={theme.direction}>
              <OnlineOderItem3 />
            </TabPanel>
            <TabPanel value={value} index={3} dir={theme.direction}>
              <OnlineOderItem4 />
            </TabPanel>
            <TabPanel value={value} index={4} dir={theme.direction}>
              <OnlineOderItem5 />
            </TabPanel>
            <TabPanel value={value} index={5} dir={theme.direction}>
              <OnlineOderItem6 />
            </TabPanel>
          </SwipeableViews>
          {fabs.map((fab, index) => (
            <Zoom
              key={fab.color}
              in={value === index}
              timeout={transitionDuration}
              style={{
                transitionDelay: `${
                  value === index ? transitionDuration.exit : 0
                }ms`,
              }}
              unmountOnExit
            >
              <Link to="/onlineOrderPlaceOrder">
                <Button sx={fab.sx} color={fab.color}>
                  {fab.label} {fab.icon}
                </Button>
              </Link>
            </Zoom>
          ))}
        </Box>
      </div>
    </React.Fragment>
  );
}

export default OnlineOrderMenuOption;
