import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { images } from "../../constants";
import Sidebar from "./Sidebar";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Grid, Container, Box, Paper, Typography } from "@mui/material";
import blogArticleImg5 from "../../assets/homePage/blogArticleImg5.jpg";

const post05Sidebar = {
  title: "Welcome to New Web Digital",
  description:
    "Your gateway to innovative, cutting-edge digital solutions! Experience unparalleled service, where technology meets creativity, driving your online presence to unprecedented heights. Dive into a world where possibilities are endless, and your vision becomes reality",
  archives: [
    {
      title: "Branding Strategy",
      imgPath: images.blog4,
      url: "/BrandingStrategy",
    },
    {
      title: "Our offer to you?",
      imgPath: images.blog2,
      url: "/OurOfferToYou",
    },
    {
      title: "Win-win cooperation",
      imgPath: images.blog3,
      url: "/WinwinCooperation",
    },
  ],
  social: [
    {
      name: (
        <a href="https://www.linkedin.com/company/solutions-illumin8/">
          LinkedIn
        </a>
      ),
      icon: LinkedInIcon,
    },
    {
      name: (
        <a href="https://www.facebook.com/profile.php?id=100086324772795">
          Facebook
        </a>
      ),
      icon: FacebookIcon,
    },
    {
      name: (
        <a href="https://www.instagram.com/solutions_illumin8/">Instagram</a>
      ),
      icon: InstagramIcon,
    },
  ],
};

const defaultTheme = createTheme();

function BlogArticle5() {
  return (
    <div className="illumin8__blogPost  illumin8__bg">
      <ThemeProvider theme={defaultTheme}>
        <Paper
          elevation={0}
          sx={{
            position: "relative",
            backgroundColor: "grey.800",
            color: "#fff",
            mb: 4,
            width: "100vw",
            height: "50vh",
            textAlign: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: `url(${blogArticleImg5})`,
          }}
        >
          {
            <img
              style={{ display: "none", width: "100%", height: "100%" }}
              src={blogArticleImg5}
              alt=""
            />
          }

          <Grid container justifyContent="center" alignItems="center">
            <Grid>
              <Box
                sx={{
                  position: "relative",
                  padding: { xs: "10vh", md: "30vh" },
                }}
              ></Box>
            </Grid>
          </Grid>
        </Paper>
        <Container maxWidth="lg">
          <Grid container spacing={5} sx={{ mt: 3, pb: 10 }}>
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                "& .markdown": {
                  py: 3,
                },
              }}
            >
              <Typography fontFamily="Manrope" variant="h4" fontWeight={600}>
                New Digital Horizons
              </Typography>
              <Typography
                fontFamily="Manrope"
                variant="subtitle1"
                marginTop={4}
              >
                In today's fast-paced digital landscape, businesses need more
                than just an online presence; they require a distinctive,
                engaging, and functional digital identity. Solutions illumin8,
                with its avant-garde approach to web design and development,
                offers businesses a chance to not just fit into the digital
                realm but to redefine it. Let's explore the intricate tapestry
                of modern web solutions and how Solutions illumin8 is uniquely
                positioned to weave success stories for its clients.
                <br />
                <br />
                <strong>Reimagining the Process </strong> <br />
                The age-old concept of websites being static billboards is long
                gone. Today, websites are vibrant ecosystems that interact with
                visitors, learn from their behaviours, and evolve over time.
                Consider the website for a modern art gallery, which isn't just
                a catalogue of artworks. With the new paradigms of web design,
                the website itself becomes an interactive piece of art. For
                instance, as visitors browse, the site adapts, showing them
                curated pieces based on their preferences, engaging them with
                virtual 3D gallery tours, or even using augmented reality to
                project artworks into their living space. These are not
                futuristic ideas but current capabilities that revolutionize
                user engagement and business potential.
                <br />
                <br />
                <strong>The Future of Digital Transformation</strong> <br />
                Digital transformation is the cornerstone of modern businesses.
                But what does it truly entail? It's not about replacing paper
                with pixels; it's about leveraging technology to redefine
                operations, customer experiences, and value propositions. Take,
                for example, a local bookstore. In a digital-first approach,
                this bookstore doesn't just list its books online. It creates
                virtual reading rooms, allows readers to preview books in
                immersive environments, hosts live author sessions, and
                integrates AI-driven recommendations. Such an approach
                transforms a simple bookstore into a global literary hub, and
                this is the power of thoughtful design and development. The
                future holds even more promise: integration with IoT,
                hyper-personalized user experiences, and seamless
                digital-physical integrations.
                <br />
                <br />
                <strong>
                  The Undeniable Business Case for Superior Web Design and
                  Development
                </strong>
                <br />
                Every business's digital storefront, their website, speaks
                volumes. A well-crafted, responsive, and engaging website not
                only retains visitors but converts them into loyal customers.
                Reflect on a boutique fashion brand. A generic website layout
                might display products and prices, but a custom-designed site
                could tell a story. The brand's ethos, its sustainability
                efforts, the artistry of its designers—all these narratives can
                be intricately woven into the website's design. When customers
                explore such a website, they're not just shopping; they're
                becoming part of a story. This deep connection, fostered by
                custom web design, leads to brand loyalty and advocacy.
                <br />
                <br />
                <strong>
                  Solutions illumin8: Crafting Success Stories in the Digital
                  Realm
                </strong>
                <br />
                At Solutions illumin8, we pride ourselves on being architects of
                digital dreams. For budding restaurateurs, we don't just create
                a website; we might build a platform where diners can virtually
                explore the restaurant, customize their meals using interactive
                menus, and even attend live cook-along sessions with the chef.
                For a non-profit working on reforestation, we'd craft an
                interactive platform where donors can virtually 'plant' trees,
                watch forests grow in real-time, and engage with communities on
                the ground. Our commitment is to understand the unique heartbeat
                of each business and translate it into digital masterpieces that
                resonate, engage, and convert.
                <br />
                <br />
                As the boundaries of what's possible in the digital realm
                continue to expand, having a visionary partner becomes
                invaluable. Solutions illumin8 is not just a web solutions
                provider; we are digital storytellers, technological artisans,
                and most importantly, co-authors in our clients' success tales.
                Let's embark on this digital journey together.
              </Typography>
            </Grid>
            <Sidebar
              title={post05Sidebar.title}
              description={post05Sidebar.description}
              archives={post05Sidebar.archives}
              social={post05Sidebar.social}
            />
          </Grid>
        </Container>
      </ThemeProvider>
    </div>
  );
}

export default BlogArticle5;
