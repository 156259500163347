import onlineOrderCover from "../../assets/homePage/onlineOrderCover.jpg";
import appointmentBookingCover from "../../assets/homePage/appointmentBookingCover.jpg";
import ecommerceCover from "../../assets/homePage/ecommerceCover.jpg";
import menuCover from "../../assets/homePage/menuCover.jpg";
import { Link } from "react-router-dom";

export const StudyCaseList = [
  {
    url: "/ecommerce",
    image: ecommerceCover,
    title: "E-COMMERCE",
    description: (
      <Link to="/ecommerce" underline="hover">
        View Demo {">"}
      </Link>
    ),
  },
  {
    url: "/menu",
    image: menuCover,
    title: "RESERVATION",
    description: (
      <Link to="/menu" underline="hover">
        View Demo {">"}
      </Link>
    ),
  },
  {
    url: "/appointmentBooking",
    image: appointmentBookingCover,
    title: "APPOINTMENT",
    description: (
      <Link to="/appointmentBooking" underline="hover">
        View Demo {">"}
      </Link>
    ),
  },
  {
    url: "/onlineOrder",
    image: onlineOrderCover,
    title: "ONLINE ORDER",
    description: (
      <Link to="/onlineOrder" underline="hover">
        View Demo {">"}
      </Link>
    ),
  },
];
