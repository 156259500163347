import * as React from "react";
import { Box, Card, CardContent, CardMedia, Typography } from "@mui/material";
import { menuDrinks, menuDrinkNew } from "./MenuList";
import MenuNavbar from "./MenuNavbar";
import MenuFooter from "./MenuFooter";
import { Gallery } from "./container";

function MenuDrink() {
  return (
    <div className="illumin8__menuDrinks">
      <MenuNavbar />
      <div className="illumin8__menuDrink-gallery">
        <Gallery />
      </div>
      <div className="illumin8__menuDrink-new ">
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: 40,
            color: "black",
            fontFamily: "monospace",
            letterSpacing: ".3rem",
            textAlign: "center",
            p: 2,
            mt: 4,
          }}
        >
          NEW
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 2,
              width: 400,
            },
            justifyContent: "center",
          }}
        >
          {menuDrinkNew.map((item) => (
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                alt="green iguana"
                height="300"
                image={item.imgPath}
              />
              <CardContent>
                <Typography
                  sx={{ mb: 2, display: "flex", justifyContent: "center" }}
                  variant="h5"
                >
                  {item.title}
                  <Typography sx={{ ml: 4, color: "red" }} variant="h5">
                    {item.price}
                  </Typography>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {item.description}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      </div>
      <div className="illumin8__menuDrink">
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: 40,
            color: "black",
            fontFamily: "monospace",
            letterSpacing: ".3rem",
            textAlign: "center",
            p: 2,
            mt: 4,
          }}
        >
          DRINKS
        </Typography>

        <Box
          marginBottom={6}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 2,
              width: 400,
            },
            justifyContent: "center",
          }}
        >
          {menuDrinks.map((item) => (
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                alt="green iguana"
                height="300"
                image={item.imgPath}
              />
              <CardContent>
                <Typography
                  sx={{ mb: 2, display: "flex", justifyContent: "center" }}
                  variant="h5"
                >
                  {item.title}
                  <Typography sx={{ ml: 4, color: "red" }} variant="h5">
                    {item.price}
                  </Typography>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {item.description}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      </div>
      <MenuFooter />
    </div>
  );
}

export default MenuDrink;
