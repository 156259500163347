import * as React from "react";
import { Link } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

export default function OnlineOrderCheckoutNavbar() {
  return (
    <div className="illumin8__onlineOrder">
      <div className="illumin8__onlineOrderNavbar">
        <AppBar position="static">
          <Toolbar sx={{ bgcolor: "black" }} variant="dense">
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 1 }}
            >
              <Link to="/onlineOrderMenu">
                <ArrowBackIosNewIcon />
              </Link>
            </IconButton>
            <Typography color="inherit" component="div">
              Back
            </Typography>
          </Toolbar>
        </AppBar>
      </div>
    </div>
  );
}
