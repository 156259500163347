import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { images } from "../../constants";
import Sidebar from "./Sidebar";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Grid, Container, Box, Paper, Typography } from "@mui/material";
import blogArticleImg1 from "../../assets/homePage/blogArticleImg1.jpg";

const post01Sidebar = {
  title: "Introducing Solutions Illumin8",
  description:
    "Embark on a digital journey with a cutting-edge startup poised to illuminate your web presence. We specialize in crafting dynamic and innovative web solutions that captivate, engage, and inspire. With a keen eye for design and a passion for technology, Solutions Illumin8 brings a fresh approach to creating impactful websites, user-friendly interfaces, and seamless e-commerce platforms. ",
  archives: [
    {
      title: "Our offer to you?",
      imgPath: images.blog2,
      url: "/OurOfferToYou",
    },
    {
      title: "Win-win cooperation",
      imgPath: images.blog3,
      url: "/WinwinCooperation",
    },
    {
      title: "Branding Strategy",
      imgPath: images.blog4,
      url: "/BrandingStrategy",
    },
  ],
  social: [
    {
      name: (
        <a href="https://www.linkedin.com/company/solutions-illumin8/">
          LinkedIn
        </a>
      ),
      icon: LinkedInIcon,
    },
    {
      name: (
        <a href="https://www.facebook.com/profile.php?id=100086324772795">
          Facebook
        </a>
      ),
      icon: FacebookIcon,
    },
    {
      name: (
        <a href="https://www.instagram.com/solutions_illumin8/">Instagram</a>
      ),
      icon: InstagramIcon,
    },
  ],
};

const defaultTheme = createTheme();

export default function BlogArticleOne() {
  return (
    <div className="illumin8__blogPost  illumin8__bg">
      <ThemeProvider theme={defaultTheme}>
        <Paper
          elevation={0}
          sx={{
            position: "relative",
            backgroundColor: "grey.800",
            color: "#fff",
            mb: 4,
            width: "100vw",
            height: "50vh",
            textAlign: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: `url(${blogArticleImg1})`,
          }}
        >
          {
            <img
              style={{ display: "none", width: "100%", height: "100%" }}
              src={blogArticleImg1}
              alt=""
            />
          }

          <Grid container justifyContent="center" alignItems="center">
            <Grid>
              <Box
                sx={{
                  position: "relative",
                  padding: { xs: "10vh", md: "30vh" },
                }}
              ></Box>
            </Grid>
          </Grid>
        </Paper>
        <Container maxWidth="lg">
          <Grid container spacing={5} sx={{ mt: 3, pb: 10 }}>
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                "& .markdown": {
                  py: 3,
                },
              }}
            >
              <Typography fontFamily="Manrope" variant="h4" fontWeight={600}>
                We are a start-up!
              </Typography>
              <Typography
                fontFamily="Manrope"
                variant="subtitle1"
                marginTop={4}
              >
                <em>Dear viewers,</em>
                <br />
                <br />
                Welcome to our first-ever post!
                <br />
                <br />
                We are the team behind the scene at Solutions illumin8. We
                wanted to take the time to sit with all of you and have a chat.
                Who are we? Why are we here? What do we do? Those are all some
                of the questions we will answer for you today!
                <br />
                <br />
                <strong> WHO ARE WE?</strong>
                <br />
                To get straight to the point: We are a start-up in the tech
                industry. The founders of illumin8 are Hui and Yasser (You can
                learn more about each of them on our ‘About us’ page).{" "}
                <em>
                  We're a team of design wizards, coding gurus, and social media
                  savants who are passionate about what we do.{" "}
                </em>
                And we're not just here to do a job – we're here to be a partner
                in your success and make your life easier. So let's make magic
                happen together!
                <br />
                <br />
                <strong> WHY WE WERE HERE?</strong>
                <br />
                At illumin8, we are here to help businesses like yours succeed
                online. We believe that every business deserves a professional
                and effective online presence, and we are dedicated to providing
                the web solutions and support needed to make that a reality.
                <em>
                  We want to bring about a positive change in this new
                  technological era.
                </em>{" "}
                <br /> <br />
                illumin8 was created to help businesses digitize and stay
                relevant in today’s new world of social media and online
                marketing. We want small and medium companies to remain
                competitive and give them the edge they need to become
                successful and exceed expectations.
                <br />
                <br />
                <strong> WHAT DO WE STAND FOR? </strong>
                <br />
                We stand for providing top-quality web solutions and exceptional
                customer service. We believe in going above and beyond for our
                clients and delivering results that exceed expectations. We
                stand for honesty, transparency, and open communication, and we
                value the relationships we build with our clients. <br /> <br />
                We also stand for innovation and staying at the forefront of the
                web solutions industry, and we continuously educate ourselves on
                the latest technologies and techniques to ensure that we are
                always offering the best solutions to our clients. In all that
                we do, we stand for our client's success.
                <br />
                <br />
                <strong> WHAT DO WE DO?</strong>
                <br />
                We work together to find a complete solution for your company’s
                needs.We start from analyzing the business’s core, building a
                complete web solution from scratch and up to a full-on
                rebranding of the company. We offer a range of web solutions and
                services to help businesses like yours succeed online. Our team
                of skilled designers, developers, and marketers can help you
                with everything from web design and development to branding and
                social media management. <br /> <br /> We can create a beautiful
                and functional website that is tailored to your specific needs
                and goals, and we can help you establish a strong and consistent
                brand identity. We can also help you connect with your audience
                and promote your brand through social media. In short, we can
                help you elevate your online presence and achieve your business
                objectives.
                <br />
                <br />
                <em>
                  If you want to know more, do not hesitate to get in touch with
                  us! You know where to find us!
                </em>
              </Typography>
            </Grid>
            <Sidebar
              title={post01Sidebar.title}
              description={post01Sidebar.description}
              archives={post01Sidebar.archives}
              social={post01Sidebar.social}
            />
          </Grid>
        </Container>
      </ThemeProvider>
    </div>
  );
}
