import React from "react";
import { images, data } from "../../constants";
import {
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
} from "@mui/material";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import "../services.css";

const SocialMediaMarketing = () => (
  <div className="illumin8__services">
    {/* service1 - Social Media Strategy Development */}
    <div className="illumin8__services-content illumin8__wrapper section__padding">
      <Grid
        container
        spacing={{ xs: 0, sm: 8 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          <div>
            <i className="photo">
              <img src={images.smm01} alt="" />
            </i>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="illumin8__wrapper_info">
            <h1 className="illumin8__services__header-h1">
              <strong>Social Media Strategy Development</strong>
            </h1>
            <p className="illumin8__p__opensans">
              Social media has become a powerful platform for businesses to
              connect with their audience. Our branding company specializes in
              developing effective social media strategies tailored to your
              brand's goals and objectives. We conduct thorough research,
              identify the most suitable social media platforms for your target
              audience, and create a roadmap for maximizing your brand's reach
              and engagement.
            </p>

            <List
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              {data.SSM_service1.map((item) => (
                <ListItem>
                  <ListItemAvatar>
                    <ThumbUpAltIcon
                      sx={{ fontSize: { xs: 20, md: 25, xl: 35 } }}
                    />
                  </ListItemAvatar>
                  <Typography
                    fontFamily="Manrope"
                    color="black"
                    sx={{
                      fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 17 },
                    }}
                  >
                    <strong>{item.title}</strong>
                  </Typography>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      </Grid>
    </div>

    {/* service2 - Content Creation */}
    <div className="illumin8__services-content illumin8__wrapper section__padding">
      <Grid
        container
        spacing={{ xs: 0, sm: 8 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid className="div1" item xs={12} sm={6}>
          <div id="div1" className="illumin8__wrapper_info">
            <h1 className="illumin8__services__header-h1">
              <strong>Content Creation</strong>
            </h1>
            <p className="illumin8__p__opensans">
              Compelling and engaging content is the cornerstone of successful
              social media marketing. Our team of skilled content creators
              develops captivating visuals, informative articles, and engaging
              videos that resonate with your audience. We curate relevant and
              shareable content from trusted sources to establish your brand as
              a thought leader in your industry, fostering trust and building a
              loyal following.
            </p>
            <List
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              {data.SSM_service2.map((item) => (
                <ListItem>
                  <ListItemAvatar>
                    <ThumbUpAltIcon
                      sx={{ fontSize: { xs: 20, md: 25, xl: 35 } }}
                    />
                  </ListItemAvatar>
                  <Typography
                    fontFamily="Manrope"
                    color="black"
                    sx={{
                      fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 17 },
                    }}
                  >
                    <strong>{item.title}</strong>
                  </Typography>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
        <Grid className="div2" item xs={12} sm={6}>
          <div>
            <i className="photo">
              <img src={images.smm02} alt="" />
            </i>
          </div>
        </Grid>
      </Grid>
    </div>

    {/* service3 - Social Media Advertising */}
    <div className="illumin8__services-content illumin8__wrapper section__padding">
      <Grid
        container
        spacing={{ xs: 0, sm: 8 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          <div>
            <i className="photo">
              <img src={images.smm03} alt="" />
            </i>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="illumin8__wrapper_info">
            <h1 className="illumin8__services__header-h1">
              <strong>Social Media Advertising</strong>
            </h1>
            <p className="illumin8__p__opensans">
              To expand your brand's reach and drive targeted traffic, our
              branding company offers strategic social media advertising
              services. We develop highly targeted ad campaigns, leveraging
              advanced audience targeting capabilities to reach the right people
              at the right time. From creating eye-catching ad creatives to
              optimizing campaign performance, we help you achieve your
              marketing objectives while maximizing return on investment.
            </p>

            <List
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              {data.SSM_service3.map((item) => (
                <ListItem>
                  <ListItemAvatar>
                    <ThumbUpAltIcon
                      sx={{ fontSize: { xs: 20, md: 25, xl: 35 } }}
                    />
                  </ListItemAvatar>
                  <Typography
                    fontFamily="Manrope"
                    color="black"
                    sx={{
                      fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 17 },
                    }}
                  >
                    <strong>{item.title}</strong>
                  </Typography>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      </Grid>
    </div>

    {/* service4 - Community Management */}
    <div className="illumin8__services-content illumin8__wrapper section__padding">
      <Grid
        container
        spacing={{ xs: 0, sm: 8 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid className="div1" item xs={12} sm={6}>
          <div id="div1" className="illumin8__wrapper_info">
            <h1 className="illumin8__services__header-h1">
              <strong>Community Management</strong>
            </h1>
            <p className="illumin8__p__opensans">
              Building a strong and engaged online community is crucial for
              brand loyalty and advocacy. Our social media marketing services
              include community management, where we actively engage with your
              audience, respond to comments and messages, and foster meaningful
              conversations. By nurturing relationships and providing
              exceptional customer service, we cultivate a loyal community of
              brand advocates.
            </p>

            <List
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              {data.SSM_service4.map((item) => (
                <ListItem>
                  <ListItemAvatar>
                    <ThumbUpAltIcon
                      sx={{ fontSize: { xs: 20, md: 25, xl: 35 } }}
                    />
                  </ListItemAvatar>
                  <Typography
                    fontFamily="Manrope"
                    color="black"
                    sx={{
                      fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 17 },
                    }}
                  >
                    <strong>{item.title}</strong>
                  </Typography>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
        <Grid className="div2" item xs={12} sm={6}>
          <div>
            <i className="photo">
              <img src={images.smm04} alt="" />
            </i>
          </div>
        </Grid>
      </Grid>
    </div>

    {/* service5 - Analytics and Performance Tracking */}
    <div className="illumin8__services-content illumin8__wrapper section__padding">
      <Grid
        container
        spacing={{ xs: 0, sm: 8 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          <div>
            <i className="photo">
              <img src={images.smm05} alt="" />
            </i>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="illumin8__wrapper_info">
            <h1 className="illumin8__services__header-h1">
              <strong>Analytics and Performance Tracking </strong>
            </h1>
            <p className="illumin8__p__opensans">
              Data-driven insights are critical for optimizing social media
              marketing efforts. Our social media services include comprehensive
              analytics and performance tracking. We measure key metrics such as
              engagement rates, reach, click-through rates, and conversions. By
              analyzing these metrics, we gain valuable insights into campaign
              performance, enabling us to refine strategies and drive continuous
              improvement.
            </p>

            <List
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              {data.SSM_service5.map((item) => (
                <ListItem>
                  <ListItemAvatar>
                    <ThumbUpAltIcon
                      sx={{ fontSize: { xs: 20, md: 25, xl: 35 } }}
                    />
                  </ListItemAvatar>
                  <Typography
                    fontFamily="Manrope"
                    color="black"
                    sx={{
                      fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 17 },
                    }}
                  >
                    <strong>{item.title}</strong>
                  </Typography>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      </Grid>
    </div>
  </div>
);

export default SocialMediaMarketing;
