import React from "react";
import { data } from "../../constants";
import {
  Paper,
  Card,
  Box,
  CardMedia,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";

const Possibility = () => (
  <div className="illumin8__possibility illumin8__wrapper section__padding">
    <div className="illumin8__possibility-content">
      <Paper
        sx={{
          width: "100vw",
          padding: { sx: 5, md: 6, xl: 10 },
          borderRadius: "15px",
          background:
            "linear-gradient(103.22deg,#fff59d -13.86%,rgb(255, 223, 41) 99.55%)",
        }}
      >
        <h1 className="gradient__text" style={{ padding: "3rem 0" }}>
          WHY ILLUMIN8?
        </h1>
        <Box
          sx={{
            display: "flex",
            flexWrap: { xs: "wrap", lg: "noWrap" },
            overflow: "auto",
            "& > :not(style)": {
              m: 1,
              mb: 2,
              // maxWidth: "100%",
              width: { xs: 300, sm: 300, md: 250, lg: 300, xl: 300 },
              height: { xs: 400, sm: 400, md: 460, lg: 400, xl: 400 },
            },
            justifyContent: "center",
            alignItems: "center",
            alignContent: "center",
          }}
        >
          {data.features.map((item) => (
            <Card
              sx={{
                background: "rgb(255, 223, 41)",
                borderRadius: "15px",
                transform: "scale(1)",
                WebkitTransform: "scale(1)",
                transition: "0.3s ease-in-out",
                "&:hover, &.Mui-focusVisible": {
                  transform: "scale(0.95)",
                  WebkitTransform: "scale(0.95)",
                },
              }}
            >
              <CardMedia
                component="img"
                height="25%"
                image={item.imgUrl}
                alt=""
              />

              <CardContent>
                <Typography
                  fontFamily="Manrope"
                  gutterBottom
                  variant="h5"
                  component="div"
                >
                  <strong>{item.title}</strong>
                </Typography>
                <CardActions>
                  <Typography
                    color="black"
                    fontFamily="Manrope"
                    variant="subtitle1"
                  >
                    {item.subtitle}
                  </Typography>
                </CardActions>
              </CardContent>
            </Card>
          ))}
        </Box>
      </Paper>
    </div>
  </div>
);

export default Possibility;
