import images from './images';

const awards = [
  {
    imgUrl: images.award02,
    title: "Karaage Chicken",
    subtitle:
      "Japanese inspired chicken Karaage with chili flakes. Served with fries.",
  },
  {
    imgUrl: images.award01,
    title: "Roasted Bone Marrow",
    subtitle:
      "Roaster with our special mix of spices. We also have a spicy version for our spicy food lovers!",
  },
  {
    imgUrl: images.award05,
    title: "Tako Wasabi",
    subtitle:
      "Our version of Tako Wasabi made with tomatoes and served on bread.",
  },
  {
    imgUrl: images.award03,
    title: "Raspberries Shortbread",
    subtitle: "Made with no eggs. Completely gluten free. Perfect for vegans.",
  },
];

export default { awards };
