import React from "react";
import "./heroSlide.css";

function HeroSlide(props) {
  const { post } = props;
  return (
    <div className="illumin8__heroSlide scene illumin8__wrapper">
      <h1 className="illumin8__services__title">BRANDING</h1>
      <div className="magic"></div>
      <p className="illumin8__services__subtitle">
        A strong brand starts with a well-defined strategy.
      </p>
    </div>
  );
}

export default HeroSlide;
