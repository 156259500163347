import * as React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider, useTheme } from "@mui/material/styles";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import {
  clothing,
  shoes,
  bags,
  accessories,
  gift,
  newCollection,
  newCollection1,
  newCollection2,
  newCollection3,
  newCollection4,
  newCollection5,
  newCollection6,
  new1,
  new2,
  new3,
  new4,
  BannerImage1,
} from "./imports";
import "./ecommerceBanner.css";

//* Button Customization *//
const themeButton = createTheme({
  palette: {
    neutral: {
      main: "black",
      contrastText: "#fff",
    },
  },
});
//

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    label: "Clothing Collection",
    imgPath: clothing,
  },
  {
    label: "Shoes Collection",
    imgPath: shoes,
  },
  {
    label: "Bags Collection",
    imgPath: bags,
  },
  {
    label: "Accessories Collection",
    imgPath: accessories,
  },
  {
    label: "Gifts Collection",
    imgPath: gift,
  },
];

function EcommerceBanner() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <div className="illumin8__ecommerceBanner">
      <div
        className="illumin8__ecommerceBanner1-Container"
        style={{
          backgroundImage: `url(${BannerImage1})`,
        }}
      >
        <h3>EXPLORE THE NEW COLLECTION</h3>
        <h1> BRANDING </h1>

        <Link to="/ecommerceProducts">
          <button> DISCOVER MORE</button>
        </Link>
      </div>

      <Box sx={{ mt: 5, width: "100%", flexGrow: 1 }}>
        <AutoPlaySwipeableViews
          axis={theme.direction === "rtl" ? "x-reverse" : "x"}
          index={activeStep}
          onChangeIndex={handleStepChange}
          enableMouseEvents
        >
          {images.map((step, index) => (
            <div className="illumin8__ecommerceBanner1-img" key={step.label}>
              {Math.abs(activeStep - index) <= 2 ? (
                <Box
                  component="img"
                  sx={{
                    height: "80vh",
                    display: "block",
                    overflow: "hidden",
                    width: "100%",
                  }}
                  src={step.imgPath}
                  alt={step.label}
                />
              ) : null}
            </div>
          ))}
        </AutoPlaySwipeableViews>
        <Paper
          square
          elevation={0}
          sx={{
            display: "flex",
            alignItems: "center",
            height: 40,
            justifyContent: "center",
            pl: 2,
            bgcolor: "background.default",
          }}
        >
          <Typography color="black" sx={{ fontSize: 20, fontWeight: "bold" }}>
            {images[activeStep].label}
          </Typography>
        </Paper>
        <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              Next
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexWrap: { xs: "wrap", md: "noWrap" },
          "& > :not(style)": {
            m: 6,
            width: 500,
            height: 600,
          },
          justifyContent: "center",
          mt: 6,
        }}
      >
        <Card sx={{ maxWidth: 500 }}>
          <CardMedia component="img" height="470" image={newCollection} />
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              NEW ARRIVALS
            </Typography>
          </CardContent>
          <CardActions>
            <ThemeProvider theme={themeButton}>
              <Link to="/ecommerceProducts">
                <Button color="neutral" variant="contained">
                  Shop Now
                </Button>
              </Link>
            </ThemeProvider>
          </CardActions>
        </Card>

        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 1,
              width: 180,
              height: 250,
              mt: 4,
            },
            justifyContent: "center",
            mt: 2,
          }}
        >
          <CardMedia component="img" height="auto" image={new1} />
          <CardMedia component="img" height="auto" image={new2} />
          <CardMedia component="img" height="auto" image={new3} />
          <CardMedia component="img" height="auto" image={new4} />
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          overflow: "auto",
          "& > :not(style)": {
            m: 1,
            width: 210,
            height: 300,
          },
          justifyContent: "center",
          mt: 6,
        }}
      >
        <CardMedia component="img" height="400" image={newCollection1} />
        <CardMedia component="img" height="400" image={newCollection2} />
        <CardMedia component="img" height="400" image={newCollection3} />
        <CardMedia component="img" height="400" image={newCollection4} />
        <CardMedia component="img" height="400" image={newCollection5} />
        <CardMedia component="img" height="400" image={newCollection6} />
      </Box>
    </div>
  );
}

export default EcommerceBanner;
