import * as React from "react";
import { Box, Card, CardContent, Typography, Link } from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MenuGoogleMap from "./MenuGoogleMap";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit">Website</Link> {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

function MenuFooter() {
  return (
    <div className="illumin8__menuFooter">
      <Box
        sx={{
          bgcolor: "#795548",
          display: "flex",
          flexWrap: { xs: "wrap", md: "noWrap" },
          "& > :not(style)": { m: 4, width: 400, height: 250 },
          justifyContent: "center",
        }}
      >
        <Card sx={{ bgcolor: "#795548" }} elevation={0}>
          <CardContent>
            <Typography
              color="black"
              sx={{ fontWeight: 800 }}
              variant="h5"
              component="div"
            >
              HOURS
            </Typography>
            <Typography color="black" sx={{ fontSize: 20, mb: 1.5, mt: 4 }}>
              Weekday
              <Typography color="black">Open Hour: 10:00 - 20:00</Typography>
            </Typography>
            <Typography color="black" sx={{ fontSize: 20, mb: 1.5 }}>
              Weekend
              <Typography color="black">Open Hour: 11:00 - 22:00</Typography>
            </Typography>
          </CardContent>
        </Card>

        <Card sx={{ bgcolor: "#795548" }} elevation={0}>
          <CardContent>
            <Typography
              color="black"
              sx={{ fontWeight: 800 }}
              variant="h5"
              component="div"
            >
              INFO
            </Typography>
            <Typography
              color="black"
              sx={{ display: "flex", fontSize: 20, mb: 1.5, mt: 4 }}
            >
              <PhoneIcon sx={{ mr: 2 }} />
              (514) 000-0000
            </Typography>
            <Typography
              color="black"
              sx={{ display: "flex", fontSize: 20, mb: 1.5, mt: 4 }}
            >
              <LocationOnIcon sx={{ mr: 2 }} />
              8000 Guy Montreal, QC H1H H1H
            </Typography>
          </CardContent>
        </Card>
        <Card sx={{ bgcolor: "#795548" }} elevation={0}>
          <MenuGoogleMap />
        </Card>
      </Box>
      <Copyright sx={{ pb: 2, bgcolor: "#795548" }} />
    </div>
  );
}

export default MenuFooter;
