import { Navigate, useRoutes } from "react-router-dom";
import HomePage from "./pages/home/HomePage";
import ContactInfo from "./pages/contactInfo/ContactInfo";
import About from "./pages/about/About";
import SignIn from "./pages/signIn/SignIn";
import SignUp from "./pages/signUp/SignUp";
import Appointment from "./pages/appointment/Appointment";
import Ecommerce from "./pages/showcase/ecommerce/Ecommerce";
import EcommerceProducts from "./pages/showcase/ecommerce/EcommerceProducts";
import EcommerceShoppingBag from "./pages/showcase/ecommerce/EcommerceShoppingBag";
import EcommerceItemDetails from "./pages/showcase/ecommerce/EcommerceItemDetails";
import EcommerceLogin from "./pages/showcase/ecommerce/EcommerceLogin";
import EcommerceSignup from "./pages/showcase/ecommerce/EcommerceSignup";
import OnlineOrder from "./pages/showcase/onlineOrder/OnlineOrder";
import OnlineOrderMenu from "./pages/showcase/onlineOrder/OnlineOrderMenu";
import OnlineOrderPlaceOrder from "./pages/showcase/onlineOrder/OnlineOrderPlaceOrder";
import AppointmentBooking from "./pages/showcase/appointmentBooking/AppointmentBooking";
import AppointmentPicker from "./pages/showcase/appointmentBooking/AppointmentPicker";
import AppointmentProducts from "./pages/showcase/appointmentBooking/AppointmentProducts";
import BlogArticleOne from "./containers/blog/BlogArticleOne";
import BlogArticleTwo from "./containers/blog/BlogArticleTwo";
import BlogArticleThree from "./containers/blog/BlogArticleThree";
import PasswordRequest from "./components/password/PasswordRequest";
import PasswordReset from "./components/password/PasswordReset";
import Menu from "./pages/showcase/menu/Menu";
import MenuEat from "./pages/showcase/menu/MenuEat";
import MenuDrink from "./pages/showcase/menu/MenuDrink";
import MenuReservation from "./pages/showcase/menu/MenuReservation";
import MenuOnlineOrdering from "./pages/showcase/menu/MenuOnlineOrdering";
import BrandingPage from "./pages/brandingPage/BrandingPage";
import WebsitePage from "./pages/websitePage/WebsitePage";
import SEOPage from "./pages/seoPage/SEOPage";
import SocialMediaMarketingPage from "./pages/smmPage/SocialMediaMarketingPage";
import BrandingCaseStudy from "./pages/caseStudy/branding/BrandingCaseStudy";
import PetHomeCaseStudy from "./pages/caseStudy/webDesign/petHome/PetHomeCaseStudy";
import DeliciousCaseStudy from "./pages/caseStudy/webDesign/delicious/DeliciousCaseStudy";
import PastryCaseStudy from "./pages/caseStudy/webDesign/pastry/PastryCaseStudy";
import SalonCaseStudy from "./pages/caseStudy/seo/SalonCaseStudy";
import BlogArticleFour from "./containers/blog/BlogArticleFour";
import SocialMediaMarketingCaseStudy from "./pages/caseStudy/ssm/SocialMediaMarketingCaseStudy";
import BlogArticle5 from "./containers/blog/BlogArticle5";
import BlogArticle6 from "./containers/blog/BlogArticle6";

export default function Router() {
  const routes = useRoutes([
    { path: "/", element: <HomePage /> },
    // { path: "/webServices", element: <WebServices /> },
    { path: "/branding", element: <BrandingPage /> },
    { path: "/web-design-and-development", element: <WebsitePage /> },
    { path: "/seo", element: <SEOPage /> },
    {
      path: "/social-media-marketing",
      element: <SocialMediaMarketingPage />,
    },
    { path: "/about", element: <About /> },
    { path: "/signIn", element: <SignIn /> },

    { path: "/signUp", element: <SignUp /> },
    { path: "/contactInfo", element: <ContactInfo /> },
    { path: "/appointment", element: <Appointment /> },
    { path: "/ecommerce", element: <Ecommerce /> },
    {
      path: "/ecommerceProducts",
      element: <EcommerceProducts />,
    },

    {
      path: "/ecommerceShoppingBag",
      element: <EcommerceShoppingBag />,
    },

    {
      path: "/ecommerceItemDetails",
      element: <EcommerceItemDetails />,
    },

    { path: "/ecommerceLogin", element: <EcommerceLogin /> },
    {
      path: "/ecommerceSignup",
      element: <EcommerceSignup />,
    },
    {
      path: "/passwordRequest",
      element: <PasswordRequest />,
    },
    {
      path: "/passwordReset",
      element: <PasswordReset />,
    },

    { path: "/onlineOrder", element: <OnlineOrder /> },
    {
      path: "/onlineOrderMenu",
      element: <OnlineOrderMenu />,
    },
    {
      path: "/onlineOrderPlaceOrder",
      element: <OnlineOrderPlaceOrder />,
    },
    {
      path: "/appointmentBooking",
      element: <AppointmentBooking />,
    },
    {
      path: "/appointmentPicker",
      element: <AppointmentPicker />,
    },
    {
      path: "/appointmentProducts",
      element: <AppointmentProducts />,
    },
    { path: "/WeAreAStartup", element: <BlogArticleOne /> },
    { path: "/OurOfferToYou", element: <BlogArticleTwo /> },
    { path: "/WinwinCooperation", element: <BlogArticleThree /> },
    { path: "/BrandingStrategy", element: <BlogArticleFour /> },
    { path: "/NewDigitalHorizons", element: <BlogArticle5 /> },
    { path: "/WhySEOStillMattersToday", element: <BlogArticle6 /> },
    { path: "/menu", element: <Menu /> },
    { path: "/menuEat", element: <MenuEat /> },
    { path: "/menuDrink", element: <MenuDrink /> },
    { path: "/menuReservation", element: <MenuReservation /> },
    { path: "/menuOnlineOrdering", element: <MenuOnlineOrdering /> },
    {
      path: "/brandingCaseStudy",
      element: <BrandingCaseStudy />,
    },
    {
      path: "/petHomeCaseStudy",
      element: <PetHomeCaseStudy />,
    },
    {
      path: "/deliciousCaseStudy",
      element: <DeliciousCaseStudy />,
    },
    {
      path: "/pastryCaseStudy",
      element: <PastryCaseStudy />,
    },
    {
      path: "/salonCaseStudy",
      element: <SalonCaseStudy />,
    },
    {
      path: "/socialMediaMarketingCaseStudy",
      element: <SocialMediaMarketingCaseStudy />,
    },
  ]);

  return routes;
}
