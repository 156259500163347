import React, { useRef, useState, useEffect, useContext } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
import PaymentsIcon from "@mui/icons-material/Payments";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import AuthContext from "../../service/AuthService";
import { teal } from "@mui/material/colors";

//* Button Customization *//
const theme = createTheme({
  palette: {
    neutral: {
      main: "black",
      contrastText: "#fff",
    },
  },
});
//

const SignIn = () => {
  const navigate = useNavigate();

  const { setAuth } = useContext(AuthContext);
  const emailRef = useRef();
  const errRef = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [email, password]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const loginUser = await axios.post(
        `https://tdn72xi1e5.execute-api.us-east-2.amazonaws.com/users/login`,
        {
          email,
          password,
        },
      );

      const accessToken = loginUser?.data?.accessToken;
      const roles = loginUser?.data?.roles;

      setAuth({ email, password, roles, accessToken });
      setEmail("");
      setPassword("");

      if (loginUser) {
        navigate("/dashBoard");
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 400) {
        setErrMsg("Missing Email or Password");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed. Please try again.");
      }
      errRef.current.focus();
    }
  };

  return (
    <div className="illumin8__signIn illumin8__wrapper section__padding">
      <Container>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 12,
            display: "flex",
            flexWrap: { xs: "wrap", md: "noWrap" },
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Paper elevation={0}>
            <Card
              noValidate
              sx={{
                backgroundColor: teal[50],
                p: { xs: 2, md: 5 },
                mr: { xs: 0, md: 8 },
                width: { xs: "100%", md: "60ch" },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar
                sx={{
                  bgcolor: "black",
                  m: 1,
                }}
              >
                <LockOutlinedIcon />
              </Avatar>
              <Typography
                component="h1"
                variant="h5"
                sx={{
                  fontWeight: 600,
                  fontSize: "30px",
                  fontFamily: "Manrope",
                  mb: 2,
                }}
              >
                LOGIN
              </Typography>

              <Box
                noValidate
                sx={{
                  width: "auto",
                  height: "auto",
                }}
              >
                <Typography
                  sx={{ color: "red" }}
                  ref={errRef}
                  className={errMsg ? "errmsg" : "offscreen"}
                  aria-live="assertive"
                >
                  {errMsg}
                </Typography>
                <Box component="form" onSubmit={handleSubmit} method="POST">
                  <TextField
                    ref={emailRef}
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    autoFocus
                  />
                  <TextField
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Password"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                  />
                  <font color="#000000">
                    <FormControlLabel
                      control={<Checkbox value="remember" color="primary" />}
                      color="rgb(0, 0, 0)"
                      label="Remember me"
                    />
                  </font>
                  <ThemeProvider theme={theme}>
                    <Button
                      color="neutral"
                      variant="contained"
                      border-radius="60px"
                      type="submit"
                      fullWidth
                      sx={{
                        borderradius: "60px",
                        height: 50,
                        width: "100%",
                        mt: 3,
                        mb: 2,
                      }}
                    >
                      <Typography>LOGIN</Typography>
                    </Button>
                  </ThemeProvider>
                  <Grid container>
                    <Grid item>
                      <Link
                        sx={{ color: "#000000" }}
                        to="/passwordRequest"
                        variant="body1"
                      >
                        Forgot your password?
                      </Link>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Card>
          </Paper>

          <Paper elevation={0}>
            <Card
              sx={{
                backgroundColor: teal[100],
                p: { xs: 1, md: 5 },
                width: { xs: "100%", md: "60ch" },
                mt: { xs: 5, md: 0 },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <CardContent>
                <Typography
                  sx={{ fontSize: { xs: 24, md: 30 }, fontWeight: 800 }}
                  gutterBottom
                >
                  Create an Account
                </Typography>
                <Typography sx={{ fontSize: 16, mb: 4 }} component="div">
                  Create an account to enjoy all the benefits of our registered
                  customers.
                </Typography>
                <Typography
                  sx={{ mb: 1.5, display: "flex" }}
                  color="text.secondary"
                >
                  <FavoriteIcon sx={{ mr: 2 }} />
                  Save your favourites across devices
                </Typography>
                <Typography
                  sx={{ mb: 1.5, display: "flex" }}
                  color="text.secondary"
                >
                  <ShoppingBagIcon sx={{ mr: 2 }} />
                  Save time at checkout for future orders
                </Typography>
                <Typography
                  sx={{ mb: 1.5, display: "flex" }}
                  color="text.secondary"
                >
                  <PaymentsIcon sx={{ mr: 2 }} />
                  Manage your purchases and returns in your order history
                </Typography>
                <Typography
                  sx={{ mb: 1.5, display: "flex" }}
                  color="text.secondary"
                >
                  <ThumbUpIcon sx={{ mr: 2 }} />
                  Become a member to profit from special offers
                </Typography>
              </CardContent>
              <CardActions>
                <ThemeProvider theme={theme}>
                  <Link to="/signUp">
                    <Button
                      color="neutral"
                      variant="contained"
                      border-radius="60px"
                      type="submit"
                      fullWidth
                      sx={{
                        borderradius: "60px",
                        height: 50,
                        width: { xs: "100%", md: "60ch" },
                        mt: 3,
                      }}
                    >
                      <Typography>REGISTER NOW</Typography>
                    </Button>
                  </Link>
                </ThemeProvider>
              </CardActions>
            </Card>
          </Paper>
        </Box>
      </Container>
    </div>
  );
};

export default SignIn;
