import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Grid, CssBaseline, Container } from "@mui/material";
import { images } from "../../../../constants";
import MainFeaturedPost from "../../MainFeaturedPost";
import FeaturedPost from "../../FeaturedPost";
import PastryMainPost from "./PastryMainPost";

const PastryMainFeaturedPost = {
  title: "",
  image: images.pastryCSImg,
  imageText: "main image description",
};

const PastryFeaturedPosts = [
  {
    url: "/petHomeCaseStudy",
    title: "Pet Home",
    description:
      "Pet Home is a thriving local pet store dedicated to providing top-quality products and services for pet owners.",
    image: images.petCenterCaseStudy,
    imageLabel: "",
  },
  {
    url: "/deliciousCaseStudy",
    title: "Delicious Restaurant & Bar",
    description:
      " Delicious Restaurant & Bar where providing efficient reservation and online ordering functionalities experence.",
    image: images.restaurantCaseStudy,
    imageLabel: "",
  },
];

const defaultTheme = createTheme();

function PastryCaseStudy() {
  return (
    <div className="illumin8__pastryCaseStudy illumin8__bg">
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />

        <MainFeaturedPost post={PastryMainFeaturedPost} />
        <Container maxWidth="lg">
          <Grid sx={{ mt: 3 }}>
            <PastryMainPost />
          </Grid>
          <Grid container spacing={4} sx={{ pb: 10 }}>
            {PastryFeaturedPosts.map((post) => (
              <FeaturedPost key={post.title} post={post} />
            ))}
          </Grid>
        </Container>
      </ThemeProvider>
    </div>
  );
}

export default PastryCaseStudy;
