import * as React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, CardMedia } from "@mui/material";
import { images } from "../../../constants";

function BrandingMainPost() {
  return (
    <Grid
      item
      xs={12}
      md={8}
      sx={{
        "& .markdown": {
          py: 3,
        },
      }}
    >
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h5"
        fontWeight={600}
        marginTop={8}
      >
        Your Style - Elevating E-Commerce Fashion
      </Typography>

      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="subtitle1"
        fontWeight={550}
        marginTop={4}
      >
        In a dynamic and competitive e-commerce landscape, Your Style emerged as
        a distinctive clothing store, offering a curated collection of
        fashionable apparel for individuals seeking to express their unique
        personalities. This case study explores the comprehensive branding
        strategy that propelled Your Style into a prominent position within the
        fashion e-commerce sector.
      </Typography>
      <CardMedia
        component="img"
        sx={{ height: "100%", mt: 4, borderRadius: "15px" }}
        image={images.brandingCSImg04}
      />

      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        * Brand Identity
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        <strong>Logo :</strong> The logo features a sleek, modern font that
        balances elegance and simplicity, reflecting the store's commitment to
        staying on-trend while remaining accessible. A vibrant color palette of
        soft pastels and bold accents complements the logo, evoking a sense of
        individuality and creativity.
        <br />
        <br />
        <strong>Tagline :</strong> "Empower Your Expression" - This tagline
        encapsulates Your Style's core value proposition of enabling customers
        to embrace their distinct identities through fashion choices.
      </Typography>
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        * Visual Branding
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        <strong>Website Design :</strong> The Your Style website boasts a clean
        and intuitive design, ensuring a seamless shopping experience.
        High-quality imagery showcases the clothing line, while minimalist
        navigation aids easy exploration of the product categories.
        <br />
        <br />
        <strong>Photography Style :</strong> Lifestyle imagery prevails,
        showcasing diverse models engaged in authentic moments, allowing
        customers to visualize themselves in the apparel. Consistency in
        photography reinforces the brand's identity.
      </Typography>
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        * Brand Voice and Messaging
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        The brand communicates with a friendly and confident tone. Product
        descriptions are concise yet informative, guiding customers through
        features and benefits. Blog posts and social media content offer style
        advice, fashion trends, and stories that resonate with the target
        audience.
      </Typography>
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        * Brand Values
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        <strong>Individuality :</strong> Your Style celebrates uniqueness and
        encourages customers to embrace their individuality through clothing
        choices.
        <br />
        <br />
        <strong>Quality :</strong> The brand maintains high standards for
        materials and craftsmanship, reinforcing a sense of reliability and
        trust.
        <strong>Empowerment :</strong> Your Style's ethos revolves around
        empowering customers to express themselves boldly, amplifying their
        self-confidence.
      </Typography>
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        * Customer Experience
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        <strong>Personalization :</strong> The e-commerce store employs
        personalized product recommendations and tailored shopping experiences
        based on customer preferences and browsing history.
        <br />
        <br />
        <strong>Ease of Use :</strong> The website offers a smooth shopping
        journey, from product discovery to checkout, with multiple payment
        options and hassle-free returns.
        <strong>Customer Support :</strong> Responsive customer service through
        various channels, including live chat and email, ensures customer
        inquiries are promptly addressed.
      </Typography>

      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h5"
        fontWeight={600}
        marginTop={4}
      >
        Visual Branding Details
      </Typography>
      <CardMedia
        component="img"
        sx={{
          height: "100%",
          mt: 4,
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
        }}
        image={images.brandingCSImg01}
      />

      <CardMedia
        component="img"
        sx={{ height: "100%" }}
        image={images.brandingCSImg02}
      />
      <CardMedia
        component="img"
        sx={{
          height: "100%",
          borderBottomLeftRadius: "15px",
          borderBottomRightRadius: "15px",
        }}
        image={images.brandingCSImg03}
      />
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        1. Logo Design
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        The logo features a blend of a classic serif font for the word "Your"
        and a modern sans-serif font for "Style," representing a harmonious
        fusion of tradition and innovation.
        <br />
        <br />A subtle, abstract icon accompanies the logo, such as a stylized
        hanger, a simplified fashion silhouette, or an artistic rendition of a
        clothing accessory. This icon reinforces the fashion theme while
        remaining versatile enough for various applications.
      </Typography>
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        2. Color Palette
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        <strong>Soft Pastels :</strong> Delicate shades like blush pink, mint
        green, and baby blue add a touch of elegance and sophistication,
        catering to a diverse audience.
        <br />
        <br />
        <strong>Bold Accents :</strong> Vibrant accents in rich hues such as
        coral, royal blue, or deep magenta inject energy and playfulness into
        the brand's visuals.
      </Typography>
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        3. Typography
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        <strong>Primary Font :</strong>A modern and legible sans-serif typeface
        is used for headings, conveying a contemporary vibe and readability
        across digital platforms.
        <br />
        <br />
        <strong>Secondary Font :</strong> A cursive or script font adds a touch
        of elegance for secondary text elements like taglines, quotes, and
        descriptions.
        <br />
        <br />
        <strong>Detail-Oriented :</strong> Close-up shots emphasize fabric
        textures, stitching details, and accessory features, reinforcing the
        commitment to quality.
      </Typography>
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        4. Photography Style
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        <strong>Lifestyle Focus :</strong>Imagery showcases models in real-life
        settings, such as urban streets, cafes, or natural environments,
        reflecting the brand's ethos of blending fashion with daily life.
        <br />
        <br />
        <strong>Diversity :</strong> Models of different ethnicities, body
        types, and styles represent a wide range of customers, fostering
        inclusivity and relatability.
        <br />
        <br />
        <strong>Detail-Oriented :</strong> Close-up shots emphasize fabric
        textures, stitching details, and accessory features, reinforcing the
        commitment to quality.
      </Typography>
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        5. Visual Elements
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        <strong>Patterns :</strong>Incorporate subtle patterns inspired by
        textile textures (like fabric weaves, lace, or denim) into website
        backgrounds, social media posts, and marketing materials.
        <br />
        <br />
        <strong>Icons :</strong> Implement custom-designed icons that relate to
        fashion items (shoes, bags, accessories) and customer interactions
        (shopping carts, hearts for favorites) for consistent visual branding.
      </Typography>
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        6. Web Design
      </Typography>
      <CardMedia
        component="img"
        sx={{
          height: "100%",
          mt: 4,
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
        }}
        image={images.brandingCSImg05}
      />

      <CardMedia
        component="img"
        sx={{ height: "100%" }}
        image={images.brandingCSImg06}
      />
      <CardMedia
        component="img"
        sx={{
          height: "100%",
          borderBottomLeftRadius: "15px",
          borderBottomRightRadius: "15px",
        }}
        image={images.brandingCSImg07}
      />
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
        paddingBottom={10}
      >
        <strong>User-Centric Layout :</strong>Prioritize a clean and
        clutter-free layout that guides visitors towards key sections like new
        arrivals, featured collections, and special offers.
        <br />
        <br />
        <strong>Visual Hierarchy :</strong> Utilize color contrast and
        typography to create a clear visual hierarchy, making it easy for users
        to identify clickable elements and navigate the website effortlessly.
      </Typography>
    </Grid>
  );
}
BrandingMainPost.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
};
export default BrandingMainPost;
