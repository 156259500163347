import * as React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import "../appointment/appointment.css";
// * DateTimePicker * //
import TextField from "@mui/material/TextField";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import Stack from "@mui/material/Stack";

import appointmentImg from "../../assets/appointmentImg.jpg";

export default function Appointment() {
  const navigate = useNavigate();
  // * DateTimePicker * //
  const [value, setValue] = React.useState(new Date().toISOString());

  // * handle Submit function * //
  const handleSubmit = async (event) => {
    event.preventDefault();
    const appointmentData = new FormData(event.currentTarget);

    const dateString = value.toISOString(); // 2022-08-31T17:29:39.000Z
    const onlyDate = dateString.split("T")[0]; // 2022-08-31
    const onlyTime = dateString.split("T")[1]; // 17:29:39.000Z
    const timeSplit = onlyTime.split(":"); // ['17', '29', '39.000Z']
    const timeString = timeSplit[0] + ":" + timeSplit[1]; // 17:29
    const dateValue = onlyDate + "T" + timeString;

    try {
      const createAppointment = await axios.post(
        "https://g2bt1gbu8j.execute-api.us-east-2.amazonaws.com/appointments",
        {
          firstName: appointmentData.get("firstName"),
          lastName: appointmentData.get("lastName"),
          phone: appointmentData.get("phone"),
          email: appointmentData.get("email"),
          description: appointmentData.get("description"),
          appointmentDateTime: dateValue,
          duration: 60,
          password: "123",
          responsibility: "test",
        }
      );

      // * navigate rounte to home page * //
      if (createAppointment) {
        navigate("/");
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <div className="illumin8__appointment illumin8__wrapper section__padding illumin8__bg">
      <Box
        sx={{
          display: "flex",
          flexWrap: { xs: "wrap", md: "noWrap" },
          "& > :not(style)": {
            m: 2,
            width: "100%",
          },
          justifyContent: "center",
          alignItems: "center",
          mt: 6,
        }}
      >
        <Paper elevation={0} sx={{ backgroundColor: "inherit" }}>
          <CardMedia
            sx={{
              width: { xs: "100%", md: "100%" },
              height: { xs: 300, md: 680, xl: 700 },
              borderRadius: "20px",
            }}
            component="img"
            image={appointmentImg}
            alt="appointmentItemGrooming1"
          />
        </Paper>

        <Paper elevation={0} sx={{ backgroundColor: "inherit" }}>
          <div className="illumin8__appointment-form">
            <h1 className="gradient__text">Appointment</h1>
            <form
              className="illumin8__appointment-form"
              onSubmit={handleSubmit}
              id="contact-form"
              method="POST"
            >
              <label htmlFor="firstName">First Name*</label>
              <input id="firstName" name="firstName" type="text" required />
              <label htmlFor="lastName">Last Name*</label>
              <input id="lastName" name="lastName" type="text" required />
              <label htmlFor="phone">Phone*</label>
              <input id="phone" name="phone" type="number" required />
              <label htmlFor="email">Email*</label>
              <input id="email" name="email" type="email" required />
              <label htmlFor="message">Message*</label>
              <textarea
                id="description"
                rows="6"
                placeholder="Enter your project ideas..."
                name="description"
                required
              ></textarea>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <Stack>
                  <DateTimePicker
                    label="Booking Appointment"
                    renderInput={(params) => <TextField {...params} />}
                    value={value}
                    onChange={(newValue) => {
                      setValue(newValue);
                    }}
                  />
                </Stack>
              </LocalizationProvider>
              <br />
              <button className="illumin8__button__Contained" type="submit">
                Send Message
              </button>
            </form>
          </div>
        </Paper>
      </Box>
    </div>
  );
}
