import React from "react";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import EcommerceNavbar from "./EcommerceNavbar";
import EcommerceFooter from "./EcommerceFooter";
import EcommerceSimilarProducts from "./EcommerceSimilarProducts";
import CheckroomIcon from "@mui/icons-material/Checkroom";
import { dressDetail1, dressDetail2, dressDetail3 } from "./imports";

//* Button Customization *//
const theme = createTheme({
  palette: {
    neutral: {
      main: "black",
      contrastText: "#fff",
    },
  },
});
//

//* Accordion *//
const data = [
  { label: "S", status: "Notify me" },
  { label: "M", status: "Notify me" },
  { label: "L", status: "Only 3 left in stock" },
  { label: "XL", status: "Notify me" },
  { label: "XXL", status: "Only 3 left in stock" },
];

function EcommerceItemDetails() {
  // * Rating *//
  const [value, setValue] = React.useState(4);
  //

  return (
    <div className="illumin8__ecommerceItemDetails">
      <EcommerceNavbar />
      <div className="illumin8__ecommerceItemDetails-container">
        <Box
          sx={{
            display: "flex",
            flexWrap: { xs: "wrap", md: "noWrap" },
            "& > :not(style)": {
              m: 1,
              width: 700,
              height: "100%",
            },
            justifyContent: "center",
          }}
        >
          <Card elevation={0}>
            <ImageList
              sx={{ height: { xs: 600, md: 900 } }}
              cols={1}
              rowHeight={600}
            >
              {itemData.map((item) => (
                <ImageListItem key={item.img}>
                  <img
                    src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.title}
                    loading="lazy"
                  />
                </ImageListItem>
              ))}
            </ImageList>
          </Card>

          <Card elevation={0} sx={{ height: "100%", p: { xs: 1, md: 1 } }}>
            <CardContent>
              <Typography
                sx={{
                  fontSize: { xs: 40, md: 45 },
                  fontFamily: "monospace",
                  fontWeight: 700,
                  letterSpacing: ".3rem",
                  color: "black",
                  textDecoration: "none",
                  textAlign: { xs: "center", md: "right" },
                  pb: 2,
                }}
              >
                Your Style
                <CheckroomIcon sx={{ fontSize: 40 }} />
              </Typography>
              <Typography variant="h5" component="div">
                Pleated Chiffon Dress
              </Typography>
              <Typography variant="h6" sx={{ mt: 4 }} color="text.secondary">
                $69.99
              </Typography>
              <Stack sx={{ mt: 4 }} direction="row" spacing={1}>
                <Chip label="Regular Fit" />
                <Chip label="Bestseller" />
              </Stack>
              <div className="illumin8__ecommerceItemDetails-color">
                <ImageListItem sx={{ mt: 6, width: 80, height: 80 }}>
                  <img
                    alt="Ecommerce Item Details Color"
                    src={`${dressDetail1}?w=164&h=164&fit=crop&auto=format`}
                    srcSet={`${dressDetail1}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                  />
                  <Typography
                    color="black"
                    variant="body2"
                    sx={{ textAlign: "center" }}
                  >
                    Black
                  </Typography>
                </ImageListItem>
              </div>
              <div className="illumin8__ecommerceItemDetails-rating">
                <Typography color="black" sx={{ mt: 2, display: "flex" }}>
                  REVIEWS (10)
                  <Rating
                    sx={{ ml: 1 }}
                    value={value}
                    onChange={(event, newValue) => {
                      setValue(newValue);
                    }}
                    readOnly
                  />
                </Typography>
              </div>
              <div className="illumin8__ecommerceItemDetails-size">
                <Accordion
                  sx={{
                    mt: 4,
                    width: "100%",
                    maxWidth: 700,
                    bgcolor: "#eeeeee",
                  }}
                >
                  <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography color="black">SIZE</Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    {data.map((item) => (
                      <ListItemButton
                        key={item.label}
                        sx={{
                          py: 0,
                          minHeight: 40,
                        }}
                      >
                        <ListItemText
                          sx={{}}
                          primaryTypographyProps={{
                            fontSize: 16,
                            fontWeight: "medium",
                          }}
                          component="li"
                        >
                          <Typography
                            color="black"
                            sx={{ width: "20%", flexShrink: 0 }}
                          >
                            {item.label}
                          </Typography>
                        </ListItemText>
                        <ListItemIcon>
                          <Typography
                            color="black"
                            sx={{ color: "text.secondary" }}
                          >
                            {item.status}
                          </Typography>
                          <NotificationsNoneIcon />
                        </ListItemIcon>
                      </ListItemButton>
                    ))}
                  </AccordionDetails>
                </Accordion>
              </div>
            </CardContent>

            <CardActions>
              <ThemeProvider theme={theme}>
                <Button
                  sx={{
                    ml: 1,
                    height: 50,
                    width: "100%",
                    alignItems: "center",
                  }}
                  color="neutral"
                  variant="contained"
                >
                  <Link to="/ecommerceShoppingBag">
                    <Typography sx={{ color: "white" }}>ADD</Typography>
                  </Link>
                </Button>

                <Button
                  sx={{
                    ml: 1,
                    height: 50,
                    width: "100%",
                    alignItems: "center",
                  }}
                  color="neutral"
                  variant="contained"
                >
                  <Link to="/ecommerceShoppingBag">
                    <Typography sx={{ color: "white" }}>PAY NOW</Typography>
                  </Link>
                </Button>
              </ThemeProvider>
            </CardActions>

            <div className="illumin8__ecommerceItemDetails-info">
              <Accordion sx={{ mt: 4 }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography color="black">DETAILS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="black">
                    Dress made of 100% viscose. Buttoned back halter neck with
                    self-overlapping fabric. Side hidden in-seam pockets. Side
                    slit at hem.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography color="black">
                    MATERIAL INFORMATION & CARE INSTRUCTIONS
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="black">
                    100% Cotton
                    <br />
                    Cuff: 100% Cotton
                    <br />
                    Collar: 100% Polyester
                    <br />
                    <br />
                    We are working with monitoring programs to guarantee
                    compliance with the social, environmental, and health and
                    safety standards of our garments. To evaluate their
                    compliance, we have developed an auditing program and plans
                    for continual improvement.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography color="black">SHIPPING</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="black">
                    Following credit card authorization and verification of
                    information, you will receive your order within
                    approximately 2 to 9 business days for delivery to major
                    cities and urban areas. Please allow up to 15 days for
                    remote locations. (Monday through Friday, not including
                    holidays).
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3a-content"
                  id="panel3a-header"
                >
                  <Typography color="black">FREE RETURNS</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography color="black">
                    General Information If you need a return label our customer
                    service team will be happy to assist you. Please email to
                    service-ca@ecommerce.com to have a free prepaid shipping
                    label emailed to you. Please use the shipping label and
                    place it on the outside of your return package. Make sure to
                    keep the return tracking number for your records. Drop off
                    your return package at either a UPS Drop Box, a UPS store,
                    or at any other location that has regular UPS pick up visit
                    ups.com for locations. Please note to use our prepaid labels
                    shipments must be sent from Canada.
                  </Typography>
                </AccordionDetails>
              </Accordion>
            </div>
          </Card>
        </Box>
        <EcommerceSimilarProducts />
        <EcommerceFooter />
      </div>
    </div>
  );
}

export default EcommerceItemDetails;

const itemData = [
  {
    img: dressDetail1,
    title: "dressDetail1",
  },
  {
    img: dressDetail2,
    title: "dressDetail2",
  },
  {
    img: dressDetail3,
    title: "dressDetail3",
  },
];
