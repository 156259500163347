import React from "react";
import { Link } from "react-router-dom";
import { Box, Card, CardMedia, Typography } from "@mui/material";
import { StudyCaseList } from "./StudyCaseList";

export default function StudyCases() {
  return (
    <div className="illumin8__studyCase  illumin8__wrapper section__padding">
      <div className="illumin8__studyCase__List">
        <h1 className="gradient__text">HIGHLIGHTS</h1>
        <Box
          elevation={0}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
            padding: { xl: "0 20rem" },
            mt: 6,
          }}
        >
          {StudyCaseList.map((demoItem) => {
            return (
              <Card
                elevation={0}
                overflow="hidden"
                sx={{
                  m: { xs: 2, md: 4, xl: 6 },
                  width: {
                    xs: "100%",
                    sm: "40%",
                    md: "40%",
                    lg: "30%",
                    xl: "35%",
                  },
                  height: "auto",
                  backgroundColor: "inherit",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 650,
                    fontSize: { xs: "20px", md: "25px", xl: "30px" },
                    fontFamily: "Manrope",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                  component="div"
                  overflow="hidden"
                >
                  {demoItem.title}
                </Typography>
                <Link to={demoItem.url}>
                  <CardMedia
                    sx={{
                      mt: 2,
                      width: "100%",
                      height: "50%",
                      borderRadius: "15px",
                      "&:hover, &.Mui-focusVisible": {
                        transform: "scale(0.95)",
                        WebkitTransform: "scale(0.95)",
                      },
                    }}
                    component="img"
                    image={demoItem.image}
                    alt="picSource"
                    overflow="hidden"
                  />
                </Link>
                <Typography
                  sx={{
                    mt: 2,
                    fontWeight: 500,
                    fontSize: { xs: "18px", md: "20px", xl: "22px" },
                    fontFamily: "Manrope",
                    color: "black",
                    "&:hover, &.Mui-focusVisible": {
                      color: "#000000",
                      fontWeight: 900,
                      cursor: "pointer",
                    },
                  }}
                >
                  {demoItem.description}
                </Typography>
              </Card>
            );
          })}
        </Box>
      </div>
    </div>
  );
}
