import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { images } from "../../constants";
import Sidebar from "./Sidebar";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Grid, Container, Box, Paper, Typography } from "@mui/material";
import blogArticleImg3 from "../../assets/homePage/blogArticleImg3.jpg";

const post03Sidebar = {
  title: "Unlocking Success",
  description:
    "In today's dynamic business landscape, success is often a result of collaboration rather than competition. Enter Solutions Illumin8, a pioneering force that believes in the power of win-win cooperation as a cornerstone of achievement. As businesses strive to thrive in an interconnected world, forging partnerships that benefit all parties involved has become paramount.",
  archives: [
    {
      title: "We are a start-up!",
      imgPath: images.blog1,
      url: "/WeAreAStartup",
    },
    {
      title: "Our offer to you?",
      imgPath: images.blog2,
      url: "/OurOfferToYou",
    },
    {
      title: "Branding Strategy",
      imgPath: images.blog4,
      url: "/BrandingStrategy",
    },
  ],
  social: [
    {
      name: (
        <a href="https://www.linkedin.com/company/solutions-illumin8/">
          LinkedIn
        </a>
      ),
      icon: LinkedInIcon,
    },
    {
      name: (
        <a href="https://www.facebook.com/profile.php?id=100086324772795">
          Facebook
        </a>
      ),
      icon: FacebookIcon,
    },
    {
      name: (
        <a href="https://www.instagram.com/solutions_illumin8/">Instagram</a>
      ),
      icon: InstagramIcon,
    },
  ],
};

const defaultTheme = createTheme();

export default function BlogArticleThree() {
  return (
    <div className="illumin8__blogPost  illumin8__bg">
      <ThemeProvider theme={defaultTheme}>
        <Paper
          elevation={0}
          sx={{
            position: "relative",
            backgroundColor: "grey.800",
            color: "#fff",
            mb: 4,
            width: "100vw",
            height: "50vh",
            textAlign: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: `url(${blogArticleImg3})`,
          }}
        >
          {
            <img
              style={{ display: "none", width: "100%", height: "100%" }}
              src={blogArticleImg3}
              alt=""
            />
          }

          <Grid container justifyContent="center" alignItems="center">
            <Grid>
              <Box
                sx={{
                  position: "relative",
                  padding: { xs: "10vh", md: "30vh" },
                }}
              ></Box>
            </Grid>
          </Grid>
        </Paper>
        <Container maxWidth="lg">
          <Grid container spacing={5} sx={{ mt: 3, pb: 10 }}>
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                "& .markdown": {
                  py: 3,
                },
              }}
            >
              <Typography fontFamily="Manrope" variant="h4" fontWeight={600}>
                Win-win cooperation
              </Typography>
              <Typography
                fontFamily="Manrope"
                variant="subtitle1"
                marginTop={4}
              >
                In the early age of the pandemic, the keyword search on
                GoogleTrends shows that people search for the keyword small
                business more often than past five years. However, the keywords
                ‘website design’, ‘IT solution’ and ‘business analysis’ search
                remain the same. Due to small-scale retailers and individuals
                using isolated business strategies, many are not aware of or
                comfortable with using online marketing and social media for
                self-promotion. It shows that small businesses are multiplying
                in numbers, but related web services are not adapted as fast.
                <br />
                <br />
                In past years, Quebec was leading the way in terms of business
                growth which has been very beneficial for individuals to start
                their own companies. Some industries, such as restaurants and
                retail been hit especially hard during the pandemic. Lockdowns,
                curfews, and many other measures lead businesses to find new
                ways to adapt to survive.
                <br />
                <br />
                Even with an economic downturn, new businesses are growing
                faster than ever. When confronted with the world's economic woes
                and competition challenges, small businesses NEED to rely on
                digital branding strategies to boost their visibility. Are you a
                business owner, and do you have a website? Maybe, you are not
                satisfied with your current offering and would like more. Or, do
                you want to unlock your growth potential and expand your
                business but need the tools and knowledge to do so?
                <br />
                <br />
                <strong>The Essence of Win-Win Cooperation</strong> <br />
                Win-win cooperation embodies a mindset where all stakeholders
                emerge victorious, transcending the traditional zero-sum game.
                Solutions Illumin8 champions this philosophy, recognizing that
                when clients, partners, and the company itself unite
                harmoniously, the potential for collective growth becomes
                boundless. <br />
                <br />
                <strong>Client-Centric Approach</strong> <br />
                At the heart of Solutions Illumin8's win-win strategy lies a
                dedication to its clients. By deeply understanding their needs,
                aspirations, and challenges, the company tailors its services to
                exceed expectations. This commitment not only ensures
                exceptional results but fosters long-lasting relationships built
                on trust and mutual success. <br />
                <br />
                <strong>Collaboration Amplifies Innovation</strong> <br />
                In the spirit of win-win, Solutions Illumin8 thrives on
                collaboration. By synergizing its team's diverse talents,
                experiences, and perspectives, the company delivers innovative
                solutions that propel clients ahead of the curve. This
                collaborative spirit extends beyond the company's walls, as
                Solutions Illumin8 actively seeks partnerships that augment its
                service offerings. <br />
                <br />
                <strong>Partnering for Mutual Growth</strong> <br />
                Solutions Illumin8's win-win ethos extends to partnerships. From
                creative agencies to tech startups, the company seeks
                like-minded collaborators who share its commitment to excellence
                and mutual growth. These partnerships aren't mere transactions;
                they're the foundation for shared achievements, where each
                entity's strengths harmonize to create an unparalleled value
                proposition. <br />
                <br />
                <strong>Building Bridges, Not Barriers</strong> <br />
                In a world where success is often framed as a solitary pursuit,
                Solutions Illumin8 redefines the paradigm. By building bridges
                instead of barriers, the company cultivates an ecosystem where
                diverse entities unite to amplify each other's potential. In
                this collaborative environment, the sum is truly greater than
                its parts. <br />
                <br />
                <strong>Shaping a Brighter Future </strong>
                <br />
                As Solutions Illumin8 continues to illuminate the digital sphere
                with its innovative solutions, it paves the way for a future
                where win-win cooperation reigns supreme. The company's
                dedication to crafting immersive digital experiences, coupled
                with its unwavering commitment to clients and partners, sets a
                precedent for how business should be conducted in the modern
                age.
                <br />
                <br />
                Solutions illumin8 is here for you! We provide our professional
                services to boost your business efficiency and productivity. We
                help you get more visibility and customers by adequately using
                social media and marketing channels. We offer a personalized IT
                solution for you to achieve significant growth in the shortest
                period possible. Contact us and tell us your requirements!
              </Typography>
            </Grid>
            <Sidebar
              title={post03Sidebar.title}
              description={post03Sidebar.description}
              archives={post03Sidebar.archives}
              social={post03Sidebar.social}
            />
          </Grid>
        </Container>
      </ThemeProvider>
    </div>
  );
}
