import * as React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, CardMedia } from "@mui/material";
import { images } from "../../../../constants";

function PetHomeMainPost() {
  return (
    <Grid
      item
      xs={12}
      md={8}
      sx={{
        "& .markdown": {
          py: 3,
        },
      }}
    >
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h5"
        fontWeight={600}
        marginTop={8}
      >
        PET HOME - Web Design & Development
      </Typography>

      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="subtitle1"
        fontWeight={550}
        marginTop={4}
      >
        Pet Home is a thriving local pet store dedicated to providing
        top-quality products and services for pet owners. The store offers a
        wide range of pet supplies, from food and toys to grooming and
        healthcare products. In order to expand their reach and improve customer
        engagement, Pet Home decided to launch a new website that not only
        showcases their products but also offers features like online
        appointments for grooming services.
      </Typography>
      <CardMedia
        component="img"
        sx={{ height: "100%", mt: 4, borderRadius: "15px" }}
        image={images.webDesignCSImg02}
      />
      <Typography
        fontFamily="Manrope"
        variant="h5"
        fontWeight={550}
        marginTop={4}
      >
        Project Goals:
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        1. Online Presence
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        Develop a visually appealing and user-friendly website that reflects Pet
        Home's brand identity and values.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        2. Product Showcase
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        Create an intuitive and organized product catalog to help customers
        easily browse and purchase pet supplies online.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        3. Grooming Appointments
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        Implement a system for customers to book grooming appointments online,
        streamlining the appointment scheduling process.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        4. Mobile Responsiveness
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        Ensure the website is responsive and accessible across various devices,
        including desktops, tablets, and smartphones.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        5. SEO Optimization
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        Optimize the website's content and structure for search engines to
        improve its online visibility and ranking.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        6. User Experience
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        Enhance the overall user experience by focusing on intuitive navigation,
        quick load times, and clear calls to action.
      </Typography>
      <CardMedia
        component="img"
        sx={{
          height: "100%",
          mt: 4,
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
        }}
        image={images.webDesignCSImg04}
      />
      <CardMedia
        component="img"
        sx={{
          height: "100%",
          borderBottomLeftRadius: "15px",
          borderBottomRightRadius: "15px",
        }}
        image={images.webDesignCSImg05}
      />
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h5"
        fontWeight={600}
        marginTop={4}
      >
        Design and Development Process
      </Typography>
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        1. Discovery Phase
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        <ul>
          <li>
            Gathered information about Pet Home's brand identity, target
            audience, and specific design preferences.
          </li>
          <li>
            Conducted competitor analysis to identify industry trends and
            opportunities.
          </li>
          <li>
            Discussed the essential features, including the product catalog and
            appointment booking system.
          </li>
        </ul>
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        2. Design Phase
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        <ul>
          <li>
            Created wireframes and mockups to visualize the website's layout and
            design.
          </li>
          <li>
            Selected a color scheme and typography that aligned with Pet Home's
            brand.
          </li>
          <li>
            Designed a logo and incorporated it into the website's header.
          </li>
        </ul>
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        3. Development Phase
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        <ul>
          <li>
            Built the website using a responsive design approach, ensuring
            compatibility with different devices.
          </li>
          <li>
            Implemented a user-friendly content management system (CMS) to allow
            easy updates and additions to products and content.
          </li>
          <li>
            Developed the product catalog with clear categories, product
            descriptions, and images.
          </li>
          <li>
            Integrated a secure e-commerce platform to facilitate online
            purchases.
          </li>
          <li>
            Created a user registration and login system for customers to manage
            their accounts.
          </li>
        </ul>
      </Typography>
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        4. Appointment Booking System
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        <ul>
          <li>Developed a custom appointment scheduling module.</li>
          <li>
            Integrated a calendar system to display available grooming slots.
          </li>
          <li>
            Implemented a form for customers to select desired services, dates,
            and times for their appointments.
          </li>
          <li>
            Enabled automated email confirmations and reminders for booked
            appointments.
          </li>
        </ul>
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        5. SEO and Launch
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        <ul>
          <li>
            Conducted keyword research and optimized product descriptions and
            content for SEO.
          </li>
          <li>
            Implemented meta tags, alt attributes for images, and structured
            data to improve search engine visibility.
          </li>
          <li>
            Performed thorough testing, including cross-browser compatibility
            and responsiveness.
          </li>
          <li>
            Launched the website, closely monitoring its performance and making
            necessary adjustments.
          </li>
        </ul>
      </Typography>
      <CardMedia
        component="img"
        sx={{
          height: "100%",
          mt: 6,
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
        }}
        image={images.webDesignCSImg06}
      />
      <CardMedia
        component="img"
        sx={{
          height: "100%",
          borderBottomLeftRadius: "15px",
          borderBottomRightRadius: "15px",
        }}
        image={images.webDesignCSImg03}
      />

      <Typography
        fontFamily="Manrope"
        variant="h5"
        fontWeight={550}
        marginTop={4}
      >
        Outcome and Results
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        1. Increased Online Presence
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        The website provided Pet Home with a professional online platform,
        attracting more customers and boosting brand visibility.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        2. Improved User Experience
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        Users found the website easy to navigate, resulting in increased
        engagement and longer visit durations.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        3. Streamlined Appointments
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        The appointment booking system significantly reduced manual scheduling
        efforts and improved customer convenience.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        4. Higher Sales
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginLeft={6}
        paddingBottom={10}
      >
        The organized product catalog and intuitive checkout process contributed
        to higher online sales.
      </Typography>
    </Grid>
  );
}
PetHomeMainPost.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
};

export default PetHomeMainPost;
