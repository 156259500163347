import * as React from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import FeaturedPost from "./FeaturedPost";

function MainFeaturedPost(props) {
  const { post } = props;
  return (
    <Paper
      elevation={0}
      sx={{
        position: "relative",
        backgroundColor: "grey.800",
        color: "#fff",
        mb: 4,
        width: "100vw",
        height: "75vh",
        textAlign: "center",
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundImage: `url(${post.image})`,
      }}
    >
      {
        <img
          style={{ display: "none", width: "100%", height: "100%" }}
          src={post.image}
          alt={post.imageText}
        />
      }

      <Grid container justifyContent="center" alignItems="center">
        <Grid>
          <Box
            sx={{
              position: "relative",
              padding: { xs: "10vh", md: "30vh" },
            }}
          >
            <Typography
              fontFamily="Manrope"
              component="h1"
              variant="h3"
              color="black"
              gutterBottom
            >
              {post.title}
            </Typography>
            <Typography
              fontFamily="Manrope"
              variant="h5"
              color="black"
              paragraph
            >
              {post.description}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
}

MainFeaturedPost.propTypes = {
  post: PropTypes.shape({
    description: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    imageText: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
};

export default MainFeaturedPost;
