import * as React from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Fab,
  SwipeableDrawer,
} from "@mui/material";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import MessageIcon from "@mui/icons-material/Message";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import "./message.css";

export default function Message() {
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 380 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Card elevation={0}>
        <CardContent>
          <Typography
            fontFamily="Manrope"
            color="black"
            variant="h5"
            sx={{
              fontWeight: 600,
              mt: 10,
              display: "flex",
              underline: "hover",
            }}
          >
            <MessageIcon sx={{ mr: 2, mt: 0.5 }} />
            CONTACT US
          </Typography>

          <Typography
            fontFamily="Manrope"
            color="black"
            sx={{ mt: 8, fontWeight: 600, display: "flex" }}
          >
            <ContactPhoneIcon sx={{ mr: 2 }} />
            CALL US (English | Mandarin):
          </Typography>
          <Typography
            fontFamily="Manrope"
            color="black"
            variant="h6"
            sx={{ p: 1 }}
          >
            +1 438 979-5667
          </Typography>

          <Typography
            fontFamily="Manrope"
            color="black"
            sx={{ mt: 4, fontWeight: 600, display: "flex" }}
          >
            <ContactPhoneIcon sx={{ mr: 2 }} />
            CALL US (French | Spanish ):
          </Typography>
          <Typography
            fontFamily="Manrope"
            color="black"
            variant="h6"
            sx={{ p: 1 }}
          >
            +1 514 777-5577
          </Typography>

          <Typography
            fontFamily="Manrope"
            color="black"
            sx={{ mt: 4, fontWeight: 600, display: "flex" }}
          >
            <ContactMailIcon sx={{ mr: 2 }} />
            EMAIL US:
          </Typography>
          <Typography
            fontFamily="Manrope"
            color="black"
            variant="h6"
            sx={{ p: 1 }}
          >
            business@illumineight.com
          </Typography>

          <Typography
            fontFamily="Manrope"
            color="black"
            sx={{ mt: 4, fontWeight: 600, display: "flex" }}
          >
            <LocationOnIcon sx={{ mr: 2 }} />
            LOCATION (Coworking):
          </Typography>
          <Typography
            fontFamily="Manrope"
            color="black"
            sx={{ p: 1, fontWeight: 600 }}
          >
            360, RUE SAINT-JACQUES, MONTREAL (QUEBEC) H2Y 1P5
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
  return (
    <div className="illumin8__message">
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Fab
            onClick={toggleDrawer(anchor, true)}
            sx={{
              bottom: (theme) => theme.spacing(2),
              right: (theme) => theme.spacing(2),
              position: "fixed",
              bgcolor: "black",
              "&:hover": {
                bgcolor: "black",
              },
            }}
          >
            <ChatBubbleOutlineIcon sx={{ color: "white" }} />
          </Fab>

          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
          >
            <div className=" illumin8__message-content">
              {list(anchor)}
              <Box
                sx={{
                  "& > :not(style)": {
                    m: 2.5,
                  },
                  mt: 8,
                }}
              >
                <a href="https://www.linkedin.com/company/solutions-illumin8/">
                  <LinkedInIcon sx={{ fontSize: 50 }} />
                </a>
                <a href="https://www.facebook.com/profile.php?id=100086324772795">
                  <FacebookIcon sx={{ fontSize: 50 }} />
                </a>

                <a href="https://www.instagram.com/solutions_illumin8/">
                  <InstagramIcon sx={{ fontSize: 50 }} />
                </a>
              </Box>
            </div>
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
