import * as React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, CardMedia } from "@mui/material";
import { images } from "../../../constants";

function SocialMediaMarketingMainPost() {
  return (
    <Grid
      item
      xs={12}
      md={8}
      sx={{
        "& .markdown": {
          py: 3,
        },
      }}
    >
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h5"
        fontWeight={600}
        marginTop={8}
      >
        Shin Sun-G - Dog Sunglasses Store
      </Typography>

      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="subtitle1"
        fontWeight={550}
        marginTop={4}
      >
        Shin Sun-G, a niche dog sunglasses store situated in the heart of
        California, recognized the untapped potential of social media marketing
        to create a buzz about their unique product line and ultimately increase
        sales. Their journey to create a notable online presence offers valuable
        lessons.
      </Typography>
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        Objective
      </Typography>
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="subtitle1"
        fontWeight={550}
        marginTop={2}
      >
        Increase brand awareness of Shin Sun-G and drive sales through a
        strategic multi-channel social media marketing approach.
      </Typography>
      <CardMedia
        component="img"
        sx={{ height: "100%", mt: 4, borderRadius: "15px" }}
        image={images.SSMSImg03}
      />
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h5"
        fontWeight={550}
        marginTop={4}
      >
        Strategy
      </Typography>
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        1. Identifying Target Audience
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        <ul>
          <li>Dog owners (especially in sunnier regions)</li>
          <li>Pet influencers and bloggers</li>
          <li>Pet accessory enthusiasts</li>
        </ul>
      </Typography>
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        2. Platform Selection
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        <ul>
          <li>
            <strong>Instagram:</strong> For visually showcasing their product
            and user-generated content.
          </li>
          <li>
            <strong>Facebook:</strong> To engage with communities of dog owners.
          </li>
          <li>
            <strong>Pinterest:</strong> A platform to pin stylish photos and
            share pet fashion trends.
          </li>
          <li>
            <strong> Twitter:</strong> For engaging in real-time with followers
            and sharing updates.
          </li>
        </ul>
      </Typography>
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        3. Content Creation
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        <ul>
          <li>
            <strong>Instagram:</strong> High-quality photos of dogs wearing Shin
            Sun-G sunglasses, user-generated content, behind-the-scenes shots,
            and weekly featured dog posts.
          </li>
          <li>
            <strong> Facebook:</strong> Shared user testimonials, promotions,
            and articles about the importance of protecting dog's eyes.
          </li>
          <li>
            <strong> Pinterest:</strong> Curated boards on pet fashion, pet
            care, and cute dogs wearing glasses.
          </li>
          <li>
            <strong> Twitter:</strong> Daily tweets, retweets from satisfied
            customers, and participation in trending hashtags.
          </li>
        </ul>
      </Typography>
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        4. Collaborations and Influencer Marketing
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        <ul>
          <li>
            Partnered with pet influencers to feature their dogs wearing Shin
            Sun-G sunglasses.
          </li>
          <li>
            Collaborated with dog rescues and offered a pair of sunglasses for
            every adoption.
          </li>
        </ul>
      </Typography>
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        5. Engagement Strategies
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        <ul>
          <li>
            Created a hashtag #ShadyPups to encourage customers to share their
            dogs in Shin Sun-G sunglasses.
          </li>
          <li>
            Hosted monthly photo contests with winners receiving free pairs or
            discounts.
          </li>
          <li>
            Ran Q&A sessions on Facebook Live about dog eye care and the
            importance of sunglasses.
          </li>
        </ul>
      </Typography>
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        6. Paid Advertising
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        <ul>
          <li>
            Used Facebook's targeted ads to reach dog owners in sunnier parts of
            the country.
          </li>
          <li>Promoted Instagram posts showcasing best-sellers.</li>
          <li>Sponsored Pins on Pinterest during peak summer months.</li>
        </ul>
      </Typography>
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        7. Analytics and Tracking
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        <ul>
          <li>
            Monitored social media analytics to track growth, engagement rates,
            and sales conversion.
          </li>
          <li>
            Used UTM parameters to identify which platform drove the most
            traffic and sales.
          </li>
        </ul>
      </Typography>
      <CardMedia
        component="img"
        sx={{
          height: "100%",
          mt: 4,
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
        }}
        image={images.SSMSImg04}
      />

      <CardMedia
        component="img"
        sx={{
          height: "100%",
          borderBottomLeftRadius: "15px",
          borderBottomRightRadius: "15px",
        }}
        image={images.SSMSImg05}
      />
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h5"
        fontWeight={600}
        marginTop={4}
      >
        Results
      </Typography>
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        1. Increased Online Presence
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        <ul>
          <li>Instagram followers increased by 200% in six months.</li>
          <li>
            Facebook community grew with a 50% increase in daily engagement.
          </li>
          <li>
            Pinterest saw a 120% increase in repins and engagement on Shin Sun-G
            boards.
          </li>
          <li>
            Twitter became a hub for customer service and witnessed a 70%
            increase in mentions.
          </li>
        </ul>
      </Typography>
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        2. Sales Growth
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
      >
        <ul>
          <li>
            Social media referrals accounted for 40% of total sales, a
            significant increase from the 5% before the campaign.
          </li>
          <li>
            Collaborations with influencers led to three times more sales during
            promotion months.
          </li>
        </ul>
      </Typography>

      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h5"
        fontWeight={550}
        marginTop={4}
      >
        Conclusion
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="subtitle1"
        marginTop={2}
        marginLeft={6}
        paddingBottom={10}
      >
        Shin Sun-G's holistic approach to social media marketing demonstrated
        the power of understanding one's audience, leveraging platform
        strengths, and the importance of engagement. Through a combination of
        organic content, paid advertising, and strategic collaborations, they
        not only built a robust online presence but significantly raised their
        sales. Their journey underscores the power of social media when
        harnessed strategically and authentically.
      </Typography>
    </Grid>
  );
}
SocialMediaMarketingMainPost.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
};
export default SocialMediaMarketingMainPost;
