import * as React from "react";
import { styled } from "@mui/material/styles";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";

import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

import MenuNavbar from "./MenuNavbar";
import MenuFooter from "./MenuFooter";
import CardHeader from "@mui/material/CardHeader";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import {
  ListItem,
  Box,
  Card,
  CardActions,
  CardContent,
  Button,
  Typography,
  ListItemText,
  Paper,
  IconButton,
  List,
} from "@mui/material";
import {
  menuNew,
  menuAppetizers,
  menuMainDish,
  menuDesserts,
  menuDrinks,
} from "./MenuList";

//* Button Customization *//
const theme = createTheme({
  palette: {
    neutral: {
      main: "black",
      contrastText: "white",
    },
  },
});
//

// * Customization Accordion * //
const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "1rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(2),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
//

function MenuOnlineOrdering() {
  // * Customization Accordion * //
  const [expanded, setExpanded] = React.useState();

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  //

  // * Spanning table * //
  const TAX_RATE = 0.15;

  function ccyFormat(num) {
    return `${num.toFixed(2)}`;
  }

  function priceRow(qty, unit) {
    return qty * unit;
  }

  function createRow(desc, qty, unit) {
    const price = priceRow(qty, unit);
    return { desc, qty, unit, price };
  }

  function subtotal(items) {
    return items.map(({ price }) => price).reduce((sum, i) => sum + i, 0);
  }

  const menuOrder = [
    createRow("Karaage Chicken", 2, 12),
    createRow("Fried Calamari", 1, 15),
    createRow("Tako Wasabi", 3, 12),
  ];

  const invoiceSubtotal = subtotal(menuOrder);
  const invoiceTaxes = TAX_RATE * invoiceSubtotal;
  const invoiceTotal = invoiceTaxes + invoiceSubtotal;
  //
  return (
    <div className="illumin8__menuOnlineOrdering">
      <MenuNavbar />
      <Typography
        sx={{
          fontSize: { xs: 35, md: 40 },
          fontWeight: 700,
          letterSpacing: ".3rem",
          color: "black",
          textDecoration: "none",
          textAlign: "center",
          p: 2,
        }}
      >
        YOUR ORDER
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexWrap: { xs: "wrap", md: "noWrap" },
          "& > :not(style)": {
            m: 1,
            height: 750,
          },
          mb: 6,
          justifyContent: "center",
        }}
      >
        <Card
          elevation={0}
          sx={{
            maxWidth: { xs: "100%", md: "65%" },
            overflow: "auto",
          }}
        >
          <CardContent sx={{ bgcolor: "#bdbdbd" }}>
            <Typography
              variant="h5"
              component="div"
              sx={{ textAlign: "center" }}
            >
              Menu
            </Typography>
          </CardContent>

          <Accordion
            expanded={expanded === "panel0"}
            onChange={handleChange("panel0")}
          >
            <AccordionSummary
              aria-controls="panel0d-content"
              id="panel0d-header"
            >
              <Typography color="black">NEW</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
              >
                {menuNew.map((item) => (
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        alt="Remy Sharp"
                        src={item.imgPath}
                        sx={{ width: 100, height: 100 }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ ml: 4 }}
                      primary={item.title}
                      secondary={item.description}
                    />
                    <Typography sx={{ ml: 4, color: "red" }}>
                      {item.price}
                    </Typography>
                    <IconButton color="error" size="large">
                      <AddCircleOutlineIcon fontSize="inherit" />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
            >
              <Typography color="black">APPETIZERS</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
              >
                {menuAppetizers.map((item) => (
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        alt="Remy Sharp"
                        src={item.imgPath}
                        sx={{ width: 100, height: 100 }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ ml: 4 }}
                      primary={item.title}
                      secondary={item.description}
                    />
                    <Typography sx={{ ml: 4, color: "red" }}>
                      {item.price}
                    </Typography>
                    <IconButton color="error" size="large">
                      <AddCircleOutlineIcon fontSize="inherit" />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
            >
              <Typography color="black">MAIN DISH</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
              >
                {menuMainDish.map((item) => (
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        alt="Remy Sharp"
                        src={item.imgPath}
                        sx={{ width: 100, height: 100 }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ ml: 4 }}
                      primary={item.title}
                      secondary={item.description}
                    />
                    <Typography sx={{ ml: 4, color: "red" }}>
                      {item.price}
                    </Typography>
                    <IconButton color="error" size="large">
                      <AddCircleOutlineIcon fontSize="inherit" />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              aria-controls="panel3d-content"
              id="panel3d-header"
            >
              <Typography color="black">DESSERTS</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
              >
                {menuDesserts.map((item) => (
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        alt="Remy Sharp"
                        src={item.imgPath}
                        sx={{ width: 100, height: 100 }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ ml: 4 }}
                      primary={item.title}
                      secondary={item.description}
                    />
                    <Typography sx={{ ml: 4, color: "red" }}>
                      {item.price}
                    </Typography>
                    <IconButton color="error" size="large">
                      <AddCircleOutlineIcon fontSize="inherit" />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion
            expanded={expanded === "panel4"}
            onChange={handleChange("panel4")}
          >
            <AccordionSummary
              aria-controls="panel4d-content"
              id="panel4d-header"
            >
              <Typography color="black">DRINKS</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <List
                sx={{
                  width: "100%",
                  bgcolor: "background.paper",
                }}
              >
                {menuDrinks.map((item) => (
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar
                        alt="Remy Sharp"
                        src={item.imgPath}
                        sx={{ width: 100, height: 100 }}
                      />
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ ml: 4 }}
                      primary={item.title}
                      secondary={item.description}
                    />
                    <Typography sx={{ ml: 4, color: "red" }}>
                      {item.price}
                    </Typography>
                    <IconButton color="error" size="large">
                      <AddCircleOutlineIcon fontSize="inherit" />
                    </IconButton>
                  </ListItem>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        </Card>

        <Card
          sx={{
            bgcolor: "#bdbdbd",
            maxWidth: { xs: "100%", md: "35%" },

            p: { xs: 1, md: 1 },
            justifyContent: "center",
            textAlign: "center",
            borderRadius: "20px",
          }}
        >
          <CardHeader title="Order" />

          <TableContainer
            component={Paper}
            sx={{
              width: "100%",

              bgcolor: "background.paper",
              position: "relative",
              overflow: "auto",
              height: 600,
              "& ul": { padding: 0 },
            }}
          >
            <Table sx={{ minWidth: 400 }} aria-label="spanning table">
              <TableHead>
                <TableRow>
                  <TableCell>Desc</TableCell>
                  <TableCell align="right">Qty.</TableCell>
                  <TableCell align="right">Unit</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {menuOrder.map((row) => (
                  <TableRow key={row.desc}>
                    <TableCell>{row.desc}</TableCell>
                    <TableCell align="right">{row.qty}</TableCell>
                    <TableCell align="right">{row.unit}</TableCell>
                    <TableCell align="right">
                      <Button color="error">
                        <RemoveCircleOutlineIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}

                <TableRow>
                  <TableCell rowSpan={3} />
                  <TableCell colSpan={2}>Subtotal</TableCell>
                  <TableCell align="right">
                    {ccyFormat(invoiceSubtotal)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Tax</TableCell>
                  <TableCell align="right">{`${(TAX_RATE * 100).toFixed(
                    0
                  )} %`}</TableCell>
                  <TableCell align="right">{ccyFormat(invoiceTaxes)}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={2}>Total</TableCell>
                  <TableCell align="right">{ccyFormat(invoiceTotal)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <CardActions disableSpacing>
            <ThemeProvider theme={theme}>
              <Button
                color="neutral"
                variant="contained"
                type="submit"
                fullWidth
                sx={{
                  borderRadius: "10px",
                  height: 45,
                  width: "100%",
                  m: 1,
                }}
              >
                <Typography variant="h5">PAY</Typography>
              </Button>
            </ThemeProvider>

            {/* <Button
              variant="outlined"
              startIcon={<PaymentIcon />}
              sx={{ width: "100%", m: 1 }}
            >
              PAY
            </Button> */}
          </CardActions>
        </Card>
      </Box>
      <MenuFooter />
    </div>
  );
}

export default MenuOnlineOrdering;
