import {
  eatBanner1,
  eatBanner2,
  eatBanner3,
  eatNew1,
  eatNew2,
  eatNew3,
  eatAppetizer1,
  eatAppetizer2,
  eatAppetizer3,
  mainDish1,
  mainDish2,
  mainDish3,
  eatDessert1,
  eatDessert2,
  eatDessert3,
  menuDrink1,
  menuDrink2,
  menuDrink3,
  menuDrink4,
  menuDrink5,
  menuDrink6,
} from "./imports";

export const menuBanner = [
  {
    title: " A1. Croissant",
    description: "1this is description",
    price: "$6",
    imgPath: eatBanner1,
  },
  {
    title: " A2. Croissant",
    description: "2this is description",
    price: "$6",
    imgPath: eatBanner2,
  },
  {
    title: " A3. Croissant",
    description: "3this is description",
    price: "$6",
    imgPath: eatBanner3,
  },
];

export const menuNew = [
  {
    title: "Salmon Glaze",
    description: "Grilled salmon in our orange and rosemary home sauce.",
    price: "$18",
    imgPath: eatNew1,
  },
  {
    title: "Chili Fried Chicken",
    description:
      "Crispy and tender chicken that was marinated with our homegrown chilis.",
    price: "$15",
    imgPath: eatNew2,
  },
  {
    title: "Tiramisu Fudge",
    description: "Our very own version of Tiramisu. ",
    price: "$8",
    imgPath: eatNew3,
  },
];

export const menuAppetizers = [
  {
    title: "Karaage Chicken",
    description:
      "Japanese inspired chicken Karaage with chili flakes. Served with fries.",
    price: "$12",
    imgPath: eatAppetizer1,
  },
  {
    title: "Fried Calamari",
    description: "Calamari platter served with sweet dipping sauce.",
    price: "$15",
    imgPath: eatAppetizer2,
  },
  {
    title: "Tako Wasabi",
    description:
      "Our version of Tako Wasabi made with tomatoes and served on bread.",
    price: "$12",
    imgPath: eatAppetizer3,
  },
];

export const menuMainDish = [
  {
    title: "Braises Garlic Pork Loin",
    description:
      "Perfectly cooked and tender Pork marinated in a garlic and pepper sauce. Served with Baked Potatoes and vegetables",
    price: "$22",
    imgPath: mainDish1,
  },
  {
    title: "Prosciutto Bruncher",
    description:
      "Our go-to for Brunch lovers. Thick slices of Proscuitto, avocados and caramelized onions in a butter baked bread served with sunny-side up eggs.",
    price: "$20",
    imgPath: mainDish2,
  },
  {
    title: "Roasted Bone Marrow",
    description:
      "Roaster with our special mix of spices. We also have a spicy version for our spicy food lovers!",
    price: "$24",
    imgPath: mainDish3,
  },
];

export const menuDesserts = [
  {
    title: "Raspberries Shortbread",
    description:
      "Made with no eggs. Completely gluten free. Perfect for vegans.",
    price: "$9",
    imgPath: eatDessert1,
  },
  {
    title: "Frozen Chocolate & Vanilla Mousse Cake",
    description:
      "The best of both worlds. Wether you like Chocolate or Vanilla, you'll like this one!",
    price: "$7",
    imgPath: eatDessert2,
  },
  {
    title: "Homemade Peacan pie",
    description: "Made from hand crushed peacans and brown sugar.",
    price: "$8",
    imgPath: eatDessert3,
  },
];

export const menuDrinkNew = [
  {
    title: "Pimm's cup",
    description: "Refreshing Pimm's cup topped with prosecco",
    price: "$9",
    imgPath: menuDrink1,
  },
  {
    title: "Mojito",
    description: "Rum, Sugar, Lime. We recommend taking a double.",
    price: "$8",
    imgPath: menuDrink2,
  },
  {
    title: "Watermelon Moji",
    description:
      "A special version of a mojito. Made with Quebec Rum and baby limes.",
    price: "$9",
    imgPath: menuDrink3,
  },
];
export const menuDrinks = [
  {
    title: "Ivy Gimlet",
    description: "Like a Mojito but with Vodka!",
    price: "$8",
    imgPath: menuDrink4,
  },
  {
    title: "Aperol Spritz",
    description:
      "If you know, you know. If you don't, you should order to know.",
    price: "$9",
    imgPath: menuDrink5,
  },
  {
    title: "Queen's Park Swizzle",
    description: "Rum, Bitters, Lime, Orange spices.",
    price: "$9",
    imgPath: menuDrink6,
  },
];
