import { faker } from "@faker-js/faker";
import { sample } from "lodash";
import {
  bag1,
  bag2,
  bag3,
  accessories1,
  accessories2,
  accessories3,
  clothing1,
  clothing2,
  clothing3,
  shoes1,
  shoes2,
  shoes3,
} from "../imports";

const PRODUCT_NAME = [
  "Product",
  "Product",
  "Product",
  "Product",
  "Product",
  "Product",
  "Product",
  "Product",
  "Product",
  "Product",
  "Product",
  "Product",
  "Product",
  "Product",
  "Product",
  "Product",
  "Product",
  "Product",
  "Product",
  "Product",
  "Product",
  "Product",
  "Product",
  "Product",
];
const PRODUCT_COLOR = [
  "#00AB55",
  "#000000",
  "#FFFFFF",
  "#FFC0CB",
  "#FF4842",
  "#1890FF",
  "#94D82D",
  "#FFC107",
];
const PRODUCT_IMG = [
  bag1,
  bag2,
  bag3,
  accessories1,
  accessories2,
  accessories3,
  clothing1,
  clothing2,
  clothing3,
  shoes1,
  shoes2,
  shoes3,
];

// ----------------------------------------------------------------------

const products = [...Array(12)].map((_, index) => {
  const setIndex = index + 1;

  return {
    id: faker.datatype.uuid(),
    // cover: `./assets/images/products/product_${setIndex}.jpg`,
    cover: PRODUCT_IMG[index],
    name: PRODUCT_NAME[index],
    price: faker.datatype.number({ min: 199, max: 299, precision: 0.01 }),
    priceSale:
      setIndex % 3
        ? null
        : faker.datatype.number({ min: 19, max: 29, precision: 0.01 }),
    colors:
      (setIndex === 1 && PRODUCT_COLOR.slice(0, 2)) ||
      (setIndex === 2 && PRODUCT_COLOR.slice(1, 3)) ||
      (setIndex === 3 && PRODUCT_COLOR.slice(2, 4)) ||
      (setIndex === 4 && PRODUCT_COLOR.slice(3, 6)) ||
      (setIndex === 23 && PRODUCT_COLOR.slice(4, 6)) ||
      (setIndex === 24 && PRODUCT_COLOR.slice(5, 6)) ||
      PRODUCT_COLOR,
    status: sample(["sale", "new", "", ""]),
  };
});

export default products;
