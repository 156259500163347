import * as React from "react";
import { autoPlay } from "react-swipeable-views-utils";
import { useTheme } from "@mui/material/styles";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Button,
  Typography,
  MobileStepper,
} from "@mui/material";
import {
  menuBanner,
  menuNew,
  menuAppetizers,
  menuMainDish,
  menuDesserts,
} from "./MenuList";
import SwipeableViews from "react-swipeable-views";
import MenuNavbar from "./MenuNavbar";
import MenuFooter from "./MenuFooter";

// * Stepper * //
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
//

function MenuEat() {
  // * Stepper * //
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = menuBanner.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  //
  return (
    <div className="illumin8__menuEat">
      <MenuNavbar />
      <div className="illumin8__menuEat-banner">
        <Box
          sx={{
            justifyContent: "center",
            justifyItems: "center",
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <AutoPlaySwipeableViews
            elevation={0}
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {menuBanner.map((step, index) => (
              <div key={step.title}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <Box
                    component="img"
                    sx={{
                      alignItems: "center",
                      height: "100%",
                      display: "flex",
                      overflow: "hidden",
                      width: "100%",
                    }}
                    src={step.imgPath}
                    alt={step.title}
                  />
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                Next
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
        </Box>
      </div>

      <div className="illumin8__menuEat-new">
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: 40,
            color: "black",
            fontFamily: "monospace",
            letterSpacing: ".3rem",
            textAlign: "center",
            p: 2,
            mt: 4,
          }}
        >
          NEW
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 2,
              width: 400,
            },
            justifyContent: "center",
          }}
        >
          {menuNew.map((item) => (
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                alt="green iguana"
                height="300"
                image={item.imgPath}
              />
              <CardContent>
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  variant="h5"
                >
                  {item.title}
                  <Typography sx={{ ml: 4, color: "red" }} variant="h5">
                    {item.price}
                  </Typography>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {item.description}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      </div>

      <div className="illumin8__menuEat-appetizers">
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: 40,
            color: "black",
            fontFamily: "monospace",
            letterSpacing: ".3rem",
            textAlign: "center",
            p: 2,
            mt: 4,
          }}
        >
          APPETIZERS
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 2,
              width: 400,
            },
            justifyContent: "center",
          }}
        >
          {menuAppetizers.map((item) => (
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                alt="green iguana"
                height="300"
                image={item.imgPath}
              />
              <CardContent>
                <Typography
                  sx={{
                    mb: 2,
                    display: "flex",
                    justifyContent: "center",
                  }}
                  variant="h5"
                >
                  {item.title}
                  <Typography sx={{ ml: 4, color: "red" }} variant="h5">
                    {item.price}
                  </Typography>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {item.description}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      </div>

      <div className="illumin8__menuEat-mainDish">
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: 40,
            color: "black",
            fontFamily: "monospace",
            letterSpacing: ".3rem",
            textAlign: "center",
            p: 2,
            mt: 4,
          }}
        >
          MAIN DISH
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 2,
              width: 400,
            },
            justifyContent: "center",
          }}
        >
          {menuMainDish.map((item) => (
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                alt="green iguana"
                height="300"
                image={item.imgPath}
              />
              <CardContent>
                <Typography
                  sx={{ mb: 2, display: "flex", justifyContent: "center" }}
                  variant="h5"
                >
                  {item.title}
                  <Typography sx={{ ml: 4, color: "red" }} variant="h5">
                    {item.price}
                  </Typography>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {item.description}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      </div>
      <div className="illumin8__menuEat-desserts">
        <Typography
          sx={{
            fontWeight: 700,
            fontSize: 40,
            color: "black",
            fontFamily: "monospace",
            letterSpacing: ".3rem",
            textAlign: "center",
            p: 2,
            mt: 4,
          }}
        >
          DESSERTS
        </Typography>

        <Box
          marginBottom={6}
          sx={{
            display: "flex",
            flexWrap: "wrap",
            "& > :not(style)": {
              m: 2,
              width: 400,
            },
            justifyContent: "center",
          }}
        >
          {menuDesserts.map((item) => (
            <Card sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                alt="green iguana"
                height="300"
                image={item.imgPath}
              />
              <CardContent>
                <Typography
                  sx={{ mb: 2, display: "flex", justifyContent: "center" }}
                  variant="h5"
                >
                  {item.title}
                  <Typography sx={{ ml: 4, color: "red" }} variant="h5">
                    {item.price}
                  </Typography>
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {item.description}
                </Typography>
              </CardContent>
            </Card>
          ))}
        </Box>
      </div>
      <MenuFooter />
    </div>
  );
}

export default MenuEat;
