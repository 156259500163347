import * as React from "react";
import PropTypes from "prop-types";
import { Grid, Typography, CardMedia } from "@mui/material";
import { images } from "../../../constants";

function SalonMainPost() {
  return (
    <Grid
      item
      xs={12}
      md={8}
      sx={{
        "& .markdown": {
          py: 3,
        },
      }}
    >
      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h5"
        fontWeight={600}
        marginTop={8}
      >
        K2 Studio - Web Design & Development
      </Typography>

      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="subtitle1"
        fontWeight={550}
        marginTop={4}
      >
        K2 Studio, an avant-garde hair salon, predominantly serves a male
        clientele but also offers a diversified palette of beauty services. Even
        with their superior service offerings, their online presence wasn’t
        reflecting their on-ground reputation. This case study illustrates how
        SEO was the game changer for K2 Studio.
      </Typography>
      <CardMedia
        component="img"
        sx={{ height: "100%", mt: 4, borderRadius: "15px" }}
        image={images.salonCSImg02}
      />
      <Typography
        fontFamily="Manrope"
        variant="h5"
        fontWeight={550}
        marginTop={4}
      >
        Challenge
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginTop={4}>
        K2 Studio had carved out a name locally for its unique hair and beauty
        services. However, they were yet to achieve the desired digital
        footprint. With an evolving beauty industry that's becoming increasingly
        digital, their online obscurity was a clear challenge.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h5"
        fontWeight={550}
        marginTop={4}
      >
        Objective
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginTop={4}>
        The primary goals were to enhance K2 Studio’s online visibility,
        escalate website traffic, expand their clientele online, and ultimately
        augment their revenue through strategic SEO.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h5"
        fontWeight={550}
        marginTop={4}
      >
        Strategy and Implementation
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        1. Keyword Research
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        This phase commenced with exhaustive keyword research, aiming to
        pinpoint search queries associated with male grooming and beauty salon
        services.
        <ul>
          <li>
            Highlighted keywords included: "men's salon near me", "hair coloring
            professionals", "top-rated perm salon", etc.
          </li>
        </ul>
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        2. On-Page SEO
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        <ul>
          <li>
            Meta Descriptions and Titles: Each webpage of K2 Studio was infused
            with enticing meta titles and descriptions containing the target
            keywords.
          </li>
          <li>
            Content Augmentation: Refined existing content, and integrated
            detailed service portfolios, genuine client reviews, and insightful
            beauty blogs.
          </li>
          <li>
            Image Alt Texts: Made sure every image, especially showcasing their
            work, was embedded with keyword-centric alt texts.
          </li>
        </ul>
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        3. Local SEO
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        <ul>
          <li>
            Google My Business: Revamped K2 Studio’s profile with real-time
            updates, range of services, and routinely posted about special
            offers.
          </li>
          <li>
            Local Citations: Established a consistent NAP (Name, Address, Phone)
            across all local directories for K2 Studio.
          </li>
        </ul>
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        4. Off-Page SEO
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        <ul>
          <li>
            Building Backlinks: Engaged with local blogs, beauty portals, and
            forums to earn quality backlinks.
          </li>
          <li>
            Guest Blogging: Teamed up with beauty aficionados and bloggers for
            content creation and sharing.
          </li>
        </ul>
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        5. Technical SEO
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        <ul>
          <li>
            Mobile Adaptability: Ensured a mobile-friendly website interface.
          </li>
          <li>
            Enhancing Site Speed: Optimized visuals and employed browser caching
            for speedier site loads.
          </li>
        </ul>
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        6. Engaging Content
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        Initiated a blog corner discussing beauty advice, hair maintenance, and
        trending men's grooming fads.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        7. Review Strategy
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        Motivated satisfied clients to pen down positive reviews on Google and
        pertinent review platforms.
      </Typography>
      <CardMedia
        component="img"
        sx={{
          height: "100%",
          mt: 4,
          borderTopLeftRadius: "15px",
          borderTopRightRadius: "15px",
        }}
        image={images.salonCSImg05}
      />

      <CardMedia
        component="img"
        sx={{
          height: "100%",
          borderBottomLeftRadius: "15px",
          borderBottomRightRadius: "15px",
        }}
        image={images.salonCSImg03}
      />
      <Typography
        fontFamily="Manrope"
        variant="h5"
        fontWeight={550}
        marginTop={4}
      >
        Results
      </Typography>

      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={4}
      >
        1. Augmented Organic Traffic
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        K2 Studio experienced a 150% boost in organic site visits within 6
        months, accompanied by a 40% uptick in new user sessions.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        2. Prominent Local Presence
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        There was a 200% growth in “directions” clicks from their Google My
        Business profile.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        3. Elevated Bookings
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        Online reservations for services witnessed a spike of 120% during this
        period.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        4. Broadened Clientele
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        Not only did the regular male clientele grow, but there was also a 70%
        rise in female clients seeking beauty treatments.
      </Typography>
      <Typography
        fontFamily="Manrope"
        variant="h6"
        fontWeight={550}
        marginTop={2}
      >
        5. Engagement Metrics
      </Typography>
      <Typography fontFamily="Manrope" variant="subtitle1" marginLeft={6}>
        Blog articles registered an average 5-minute read duration, signifying
        robust content involvement.
      </Typography>

      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="h5"
        fontWeight={600}
        marginTop={4}
      >
        Conclusion
      </Typography>

      <Typography
        fontFamily="Manrope"
        component="h2"
        variant="subtitle1"
        fontWeight={550}
        marginTop={4}
        paddingBottom={10}
      >
        Embracing a focused and sustained SEO strategy, K2 Studio redefined its
        online and offline stature. The salon didn’t just thrive but saw a vast
        expansion in its customer base. This narrative underscores the
        significance of digital optimization in today's business milieu,
        transcending sectors conventionally seen as 'offline'.
      </Typography>
    </Grid>
  );
}
SalonMainPost.propTypes = {
  posts: PropTypes.arrayOf(PropTypes.string).isRequired,
  title: PropTypes.string.isRequired,
};

export default SalonMainPost;
