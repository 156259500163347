import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { images } from "../../constants";
import Sidebar from "./Sidebar";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import { Grid, Container, Box, Paper, Typography } from "@mui/material";
import blogArticleImg2 from "../../assets/homePage/blogArticleImg2.jpg";

const post02Sidebar = {
  title: "Welcome to Solutions Illumin8",
  description:
    "A visionary startup at the forefront of transforming digital landscapes. Our mission? To empower businesses with a holistic suite of services that transcend the ordinary, igniting their online presence and driving exponential growth.",
  archives: [
    {
      title: "New Digital Horizons",
      imgPath: images.blog5,
      url: "/NewDigitalHorizons",
    },
    {
      title: "Win-win cooperation",
      imgPath: images.blog3,
      url: "/WinwinCooperation",
    },
    {
      title: "Branding Strategy",
      imgPath: images.blog4,
      url: "/BrandingStrategy",
    },
  ],
  social: [
    {
      name: (
        <a href="https://www.linkedin.com/company/solutions-illumin8/">
          LinkedIn
        </a>
      ),
      icon: LinkedInIcon,
    },
    {
      name: (
        <a href="https://www.facebook.com/profile.php?id=100086324772795">
          Facebook
        </a>
      ),
      icon: FacebookIcon,
    },
    {
      name: (
        <a href="https://www.instagram.com/solutions_illumin8/">Instagram</a>
      ),
      icon: InstagramIcon,
    },
  ],
};

const defaultTheme = createTheme();

export default function BlogArticleTwo() {
  return (
    <div className="illumin8__blogPost  illumin8__bg">
      <ThemeProvider theme={defaultTheme}>
        <Paper
          elevation={0}
          sx={{
            position: "relative",
            backgroundColor: "grey.800",
            color: "#fff",
            mb: 4,
            width: "100vw",
            height: "50vh",
            textAlign: "center",
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundImage: `url(${blogArticleImg2})`,
          }}
        >
          {
            <img
              style={{ display: "none", width: "100%", height: "100%" }}
              src={blogArticleImg2}
              alt=""
            />
          }

          <Grid container justifyContent="center" alignItems="center">
            <Grid>
              <Box
                sx={{
                  position: "relative",
                  padding: { xs: "10vh", md: "30vh" },
                }}
              ></Box>
            </Grid>
          </Grid>
        </Paper>
        <Container maxWidth="lg">
          <Grid container spacing={5} sx={{ mt: 3, pb: 10 }}>
            <Grid
              item
              xs={12}
              md={8}
              sx={{
                "& .markdown": {
                  py: 3,
                },
              }}
            >
              <Typography fontFamily="Manrope" variant="h4" fontWeight={600}>
                Our offer to you?
              </Typography>
              <Typography
                fontFamily="Manrope"
                variant="subtitle1"
                marginTop={4}
              >
                Market research indicates small and individual businesses face
                stiff competition from medium and big companies. This is because
                they lack awareness on how important it is to apply effective
                publicity. Or, they are reluctant to increase spending for
                advertisement and branding, which leads them to a lack of
                competitiveness.
                <br />
                <br />
                In our world, post-pandemic, the global recession negatively
                impacted the economy. Today, every company is facing an economic
                depression. However, small businesses were hit the hardest by
                this crisis and met with a growth challenge that will result in
                some not able to survive the shockwave.
                <br />
                <br />
                Currently, some businesses are looking for ways to spread the
                word about their products and services differently from the
                traditional way, such as through leaflets, brochures and
                catalogues. They need to find an innovative way to promote
                themselves. A great example would be: Endorsing the product on
                social media channels or effectively use the internet with a
                dedicated and attractive webpage.
                <br />
                <br />
                <strong> Solutions illumin8 is here for you right now!</strong>
                <br />
                <br />
                Welcome to Solutions Illumin8, where we ignite your brand's
                potential through a comprehensive suite of services. Our
                expertise encompasses Branding, where we infuse your identity
                with resonance. Experience our impeccable Web Design &
                Development, where digital artistry meets seamless
                functionality. Elevate your online presence with our adept SEO
                strategies, ensuring your business shines brightly in search
                results. Amplify engagement through our dynamic Social Media
                Marketing, connecting you with your audience on a deeper level.
                At Solutions Illumin8, we don't just provide services; we
                orchestrate a symphony of success, illuminating your path to
                growth in the digital landscape.
                <br />
                <br />
                <strong> BRANDING: </strong> <br />
                In a world of infinite choices, branding is your beacon.
                Solutions Illumin8 crafts compelling brand identities that
                resonate. From logo design that encapsulates your essence to
                crafting a consistent visual language, we make your brand
                unforgettable. <br />
                <br />
                <strong> WEB DESIGN & DEVELOPMENT: </strong> <br />
                Your virtual storefront speaks volumes. Our innovative web
                designs merge artistry with functionality, delivering
                captivating user experiences. Backed by top-tier developers, we
                breathe life into websites, ensuring they're responsive,
                intuitive, and ready to convert visitors into loyal customers.{" "}
                <br />
                <br /> <strong> SEO (Search Engine Optimization): </strong>{" "}
                <br />
                What good is a website if it's not discoverable? Solutions
                Illumin8 employs SEO strategies to enhance your digital
                visibility. We decipher search engine algorithms, optimize
                content, and build authoritative backlinks, placing you atop
                search results and boosting organic traffic. <br />
                <br />
                <strong> SOCIAL MEDIA MARKETING: </strong> <br />
                The heartbeat of modern communication, social media demands
                strategic finesse. Our experts curate dynamic campaigns across
                platforms, creating authentic connections and amplifying your
                message. From engaging content to targeted ads, we put the
                'social' back into your strategy.
                <br />
                <br />
                At Solutions Illumin8, we don't just offer services; we craft
                immersive digital experiences. Our multidisciplinary team
                collaborates passionately, fusing creativity, technology, and
                data-driven insights to illuminate your path to success. Partner
                with us to sculpt your digital narrative and shine brightly in
                the vast online universe.
              </Typography>
            </Grid>
            <Sidebar
              title={post02Sidebar.title}
              description={post02Sidebar.description}
              archives={post02Sidebar.archives}
              social={post02Sidebar.social}
            />
          </Grid>
        </Container>
      </ThemeProvider>
    </div>
  );
}
