import React from "react";
import { images, data } from "../../constants";
import {
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  Typography,
} from "@mui/material";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import "../services.css";

const SEO = () => (
  <div className="illumin8__services">
    {/* service1 - Keyword Research and Analysis */}
    <div className="illumin8__services-content illumin8__wrapper section__padding">
      <Grid
        container
        spacing={{ xs: 0, sm: 8 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          <div>
            <i className="photo">
              <img src={images.seo01} alt="" />
            </i>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="illumin8__wrapper_info">
            <h1 className="illumin8__services__header-h1">
              <strong>Keyword Research and Analysis</strong>
            </h1>
            <p className="illumin8__p__opensans">
              Effective SEO starts with thorough keyword research and analysis.
              We identify relevant keywords and phrases that align with your
              business and target audience. By understanding the search intent
              of your potential customers, we optimize your website's content to
              rank higher in search engine results and attract qualified organic
              traffic.
            </p>

            <List
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              {data.SEO_service1.map((item) => (
                <ListItem>
                  <ListItemAvatar>
                    <ThumbUpAltIcon
                      sx={{ fontSize: { xs: 20, md: 25, xl: 35 } }}
                    />
                  </ListItemAvatar>
                  <Typography
                    fontFamily="Manrope"
                    color="black"
                    sx={{
                      fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 17 },
                    }}
                  >
                    <strong>{item.title}</strong>
                  </Typography>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      </Grid>
    </div>

    {/* service2 - On-Page Optimization */}
    <div className="illumin8__services-content illumin8__wrapper section__padding">
      <Grid
        container
        spacing={{ xs: 0, sm: 8 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid className="div1" item xs={12} sm={6}>
          <div id="div1" className="illumin8__wrapper_info">
            <h1 className="illumin8__services__header-h1">
              <strong>On-Page Optimization</strong>
            </h1>
            <p className="illumin8__p__opensans">
              On-page optimization is crucial for improving your website's
              visibility and search engine rankings. We optimize your website's
              meta tags, headings, URLs, and content structure, ensuring that
              search engines can easily crawl and understand your website's
              relevance. By implementing best practices, we help search engines
              recognize the value your website offers to users.
            </p>
            <List
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              {data.SEO_service2.map((item) => (
                <ListItem>
                  <ListItemAvatar>
                    <ThumbUpAltIcon
                      sx={{ fontSize: { xs: 20, md: 25, xl: 35 } }}
                    />
                  </ListItemAvatar>
                  <Typography
                    fontFamily="Manrope"
                    color="black"
                    sx={{
                      fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 17 },
                    }}
                  >
                    <strong>{item.title}</strong>
                  </Typography>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
        <Grid className="div2" item xs={12} sm={6}>
          <div>
            <i className="photo">
              <img src={images.seo02} alt="" />
            </i>
          </div>
        </Grid>
      </Grid>
    </div>

    {/* service3 - Link Building and Off-Page Optimization */}
    <div className="illumin8__services-content illumin8__wrapper section__padding">
      <Grid
        container
        spacing={{ xs: 0, sm: 8 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          <div>
            <i className="photo">
              <img src={images.seo03} alt="" />
            </i>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="illumin8__wrapper_info">
            <h1 className="illumin8__services__header-h1">
              <strong>Off-Page Optimization</strong>
            </h1>
            <p className="illumin8__p__opensans">
              Building high-quality backlinks is crucial for improving your
              website's authority and visibility. We employ strategic link
              building and off-page optimization techniques to enhance your
              website's reputation. Through outreach, content promotion, and
              relationship building with authoritative websites, we secure
              relevant and reputable backlinks that boost your organic rankings.
            </p>

            <List
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              {data.SEO_service3.map((item) => (
                <ListItem>
                  <ListItemAvatar>
                    <ThumbUpAltIcon
                      sx={{ fontSize: { xs: 20, md: 25, xl: 35 } }}
                    />
                  </ListItemAvatar>
                  <Typography
                    fontFamily="Manrope"
                    color="black"
                    sx={{
                      fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 17 },
                    }}
                  >
                    <strong>{item.title}</strong>
                  </Typography>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      </Grid>
    </div>

    {/* service4 - Local SEO */}
    <div className="illumin8__services-content illumin8__wrapper section__padding">
      <Grid
        container
        spacing={{ xs: 0, sm: 8 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid className="div1" item xs={12} sm={6}>
          <div id="div1" className="illumin8__wrapper_info">
            <h1 className="illumin8__services__header-h1">
              <strong>Local SEO</strong>
            </h1>
            <p className="illumin8__p__opensans">
              For businesses targeting specific geographic areas, our local SEO
              services can help you connect with local customers. We optimize
              your website for local search by creating and optimizing
              location-specific landing pages, managing online business
              listings, and implementing strategies to improve your visibility
              in local search results. We help you stand out in your target area
              and drive foot traffic to your physical store.
            </p>

            <List
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              {data.SEO_service4.map((item) => (
                <ListItem>
                  <ListItemAvatar>
                    <ThumbUpAltIcon
                      sx={{ fontSize: { xs: 20, md: 25, xl: 35 } }}
                    />
                  </ListItemAvatar>
                  <Typography
                    fontFamily="Manrope"
                    color="black"
                    sx={{
                      fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 17 },
                    }}
                  >
                    <strong>{item.title}</strong>
                  </Typography>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
        <Grid className="div2" item xs={12} sm={6}>
          <div>
            <i className="photo">
              <img src={images.seo04} alt="" />
            </i>
          </div>
        </Grid>
      </Grid>
    </div>

    {/* service5 - SEO Reporting and Analytics */}
    <div className="illumin8__services-content illumin8__wrapper section__padding">
      <Grid
        container
        spacing={{ xs: 0, sm: 8 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          <div>
            <i className="photo">
              <img src={images.seo05} alt="" />
            </i>
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="illumin8__wrapper_info">
            <h1 className="illumin8__services__header-h1">
              <strong>SEO Reporting and Analytics </strong>
            </h1>
            <p className="illumin8__p__opensans">
              Transparency and accountability are crucial in SEO. We provide
              regular reports and analytics to track the progress and
              effectiveness of our strategies. By measuring key performance
              indicators such as organic traffic, keyword rankings, and
              conversions, we provide actionable insights to refine our approach
              and continuously improve your website's search visibility.
            </p>

            <List
              sx={{
                width: "100%",
                height: "100%",
              }}
            >
              {data.SEO_service5.map((item) => (
                <ListItem>
                  <ListItemAvatar>
                    <ThumbUpAltIcon
                      sx={{ fontSize: { xs: 20, md: 25, xl: 35 } }}
                    />
                  </ListItemAvatar>
                  <Typography
                    fontFamily="Manrope"
                    color="black"
                    sx={{
                      fontSize: { xs: 13, sm: 13, md: 14, lg: 16, xl: 17 },
                    }}
                  >
                    <strong>{item.title}</strong>
                  </Typography>
                </ListItem>
              ))}
            </List>
          </div>
        </Grid>
      </Grid>
    </div>
  </div>
);

export default SEO;
