import * as React from "react";
import Box from "@mui/material/Box";
import OnlineOrderNavbar from "./OnlineOrderNavbar";
import OnlineOrderMenuOption from "./OnlineOrderMenuOption";
import "./onlineOrder.css";

function OnlineOrderMenu() {
  return (
    <div className="illumin8__onlineOrderMenu">
      <Box
        sx={{
          width: 400,
          height: 85,
          flexGrow: 1,
          margin: "auto",
          paddingBottom: 106,
        }}
      >
        <OnlineOrderNavbar />
        <OnlineOrderMenuOption />
      </Box>
    </div>
  );
}

export default OnlineOrderMenu;
