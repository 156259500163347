import * as React from "react";
import { Link } from "react-router-dom";
import {
  Paper,
  Box,
  Button,
  Avatar,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  SwipeableDrawer,
  Typography,
  Slide,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import logo from "../../logo.png";
import "./navbar.css";

//* SwipeableDrawer *//
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

//* logo invisible *//
window.onscroll = function () {
  var image = document.getElementById("scroll-img");
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    image.classList.add("hidden");
  } else {
    image.classList.remove("hidden");
  }
};

export default function Navbar() {
  const handleDrawerClose = () => {
    setState(false);
  };

  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: anchor === "top" || anchor === "bottom" ? "auto" : "100vw",
      }}
    >
      <IconButton onClick={handleDrawerClose} sx={{ color: "white" }}>
        <CloseIcon fontSize="large" />
      </IconButton>

      <Link
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
        to="/"
      >
        <Typography sx={{ ml: 2 }}>HOME</Typography>
      </Link>

      <Accordion elevation={0} sx={{ bgcolor: "inherit" }}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{ fontSize: 40, color: "white" }} />}
          onClick={toggleDrawer(anchor, true)}
          onKeyDown={toggleDrawer(anchor, true)}
        >
          <Typography>SERVICES</Typography>
        </AccordionSummary>
        <AccordionDetails
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
          sx={{ ml: 2 }}
        >
          <Link to="/branding">
            <Typography>Branding</Typography>
          </Link>
          <Link to="/web-design-and-development">
            <Typography>Web Design & Development</Typography>
          </Link>
          <Link to="/seo">
            <Typography>SEO</Typography>
          </Link>
          <Link to="/social-media-marketing">
            <Typography>Social Media Marketing</Typography>
          </Link>
        </AccordionDetails>
      </Accordion>

      <Link
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
        to="/about"
      >
        <Typography sx={{ ml: 2 }}>ABOUT</Typography>
      </Link>
    </Box>
  );

  return (
    <div className="illumin8__navbar">
      {["left"].map((anchor) => (
        <React.Fragment key={anchor}>
          <Box
            sx={{
              position: "fixed",
              paddingLeft: 10,
              paddingTop: 3,
              height: 100,
              zIndex: 1,
            }}
          >
            <a href="/">
              <img src={logo} alt="logo" id="scroll-img" />
            </a>
          </Box>

          <Button
            sx={{ position: "fixed", zIndex: 1, width: 100, height: 100 }}
            onClick={toggleDrawer(anchor, true)}
          >
            <Avatar
              variant="rounded"
              sx={{
                bgcolor: "black",
              }}
            >
              <MenuIcon />
            </Avatar>
          </Button>

          <SwipeableDrawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
            onOpen={toggleDrawer(anchor, true)}
            TransitionComponent={Transition}
          >
            <div className="illumin8__navbar-container illumin8__wrapper illumin8__bgNav">
              <Paper
                elevation={0}
                sx={{
                  display: "flex",
                  flexWrap: { xs: "wrap", md: "noWrap" },
                  bgcolor: "inherit",
                }}
              >
                <div className="illumin8__navbar-content">
                  <p>{list(anchor)}</p>
                </div>
              </Paper>
            </div>
          </SwipeableDrawer>
        </React.Fragment>
      ))}
    </div>
  );
}
