import * as React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { Link } from "react-router-dom";

function Sidebar(props) {
  const { archives, description, social, title } = props;
  return (
    <Grid item xs={12} md={4} marginBottom={10}>
      <Paper
        elevation={0}
        sx={{ p: 2, bgcolor: "white", borderRadius: "15px" }}
      >
        <Typography
          fontFamily="Manrope"
          color="black"
          variant="h6"
          gutterBottom
        >
          {title}
        </Typography>
        <Typography fontFamily="Manrope" color="black">
          {description}
        </Typography>
      </Paper>
      <Typography
        fontFamily="Manrope"
        fontWeight={650}
        color="black"
        variant="h6"
        gutterBottom
        sx={{ mt: 3 }}
      >
        Recommended Reading
      </Typography>
      {archives.map((archive) => (
        <Link to={archive.url}>
          <Card
            sx={{
              maxWidth: 300,
              m: 2,
              borderRadius: "10px",
              "&:hover, &.Mui-focusVisible": {
                WebkitFilter: "grayscale(100%) blur(1px)",
                filter: "grayscale(100%) blur(1px)",
              },
            }}
          >
            <CardMedia
              sx={{ height: 140 }}
              image={archive.imgPath}
              title="green iguana"
            />
            <CardContent>
              <Typography
                fontFamily="Manrope"
                fontWeight={600}
                color="black"
                key={archive.title}
              >
                {archive.title}
              </Typography>
            </CardContent>
          </Card>
        </Link>
      ))}
      <Typography
        fontFamily="Manrope"
        fontWeight={650}
        color="black"
        variant="h6"
        gutterBottom
        sx={{ mt: 3 }}
      >
        Social Medias
      </Typography>
      {social.map((network) => (
        <Typography
          fontFamily="Manrope"
          fontWeight={650}
          color="black"
          key={network.name}
          sx={{ mb: 0.5 }}
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <network.icon sx={{ fontSize: 40, color: "black" }} />
            <span>{network.name}</span>
          </Stack>
        </Typography>
      ))}
    </Grid>
  );
}
Sidebar.propTypes = {
  archives: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    })
  ).isRequired,
  description: PropTypes.string.isRequired,
  social: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.elementType,
      name: PropTypes.string.isRequired,
    })
  ).isRequired,
  title: PropTypes.string.isRequired,
};
export default Sidebar;
