import * as React from "react";
import { Paper } from "@mui/material";
import { data } from "../../constants";
import "./brand.css";

function Brand() {
  return (
    <div className="illumin8__brand section__padding">
      {data.logos.map((item) => (
        <Paper sx={{ backgroundColor: "inherit" }} elevation={0}>
          <img className="logo" src={item.imgUrl} />
        </Paper>
      ))}
    </div>
  );
}

export default Brand;
