import images from "./images";
import { Link } from "react-router-dom";

const features = [
  {
    imgUrl: images.feature01,
    title: "Innovation",
    subtitle:
      "We believe in staying at the forefront of web design and development trends. We continuously educate ourselves on the latest technologies and techniques to ensure the most innovative solutions.",
  },
  {
    imgUrl: images.feature02,
    title: "Customization",
    subtitle:
      "Every business is unique, and we understand that one-size-fits-all solutions don't always work. That's why we offer customized solutions that are tailored to the specific needs and goals of your business.",
  },
  {
    imgUrl: images.feature03,
    title: "Efficiency",
    subtitle:
      "A functional and user-friendly website can make a big difference in the efficiency of your business. We believe in creating websites that not only look great but also provide value and streamline processes.",
  },
  {
    imgUrl: images.feature04,
    title: "Collaboration",
    subtitle:
      "We value open communication and collaboration with our clients. We believe that by working together, we can achieve great results and create a website that exceeds your expectations.",
  },
];

const logos = [
  {
    id: 2,
    alt: "Logo 2",
    imgUrl: images.GoDaddy,
  },
  {
    id: 3,
    alt: "Logo 3",
    imgUrl: images.SQSP,
  },
  {
    id: 4,
    alt: "Logo 4",
    imgUrl: images.wordPress,
  },
  {
    id: 5,
    alt: "Logo 5",
    imgUrl: images.webflow,
  },
  {
    id: 6,
    alt: "Logo 6",
    imgUrl: images.shopify,
  },
  {
    id: 7,
    alt: "Logo 7",
    imgUrl: images.wooCommerce,
  },
  {
    id: 8,
    alt: "Logo 8",
    imgUrl: images.figma,
  },
  {
    id: 9,
    alt: "Logo 9",
    imgUrl: images.UEAT,
  },
  {
    id: 10,
    alt: "Logo 10",
    imgUrl: images.opentable,
  },
  {
    id: 11,
    alt: "Logo 11",
    imgUrl: images.libro,
  },
];

const brandingBanners = [
  {
    imgPath: images.brandingBanner1,
  },
  {
    imgPath: images.brandingBanner2,
  },
  {
    imgPath: images.brandingBanner3,
  },
];

const awards = [
  {
    title: "* Inclusivity",
    subtitle:
      "We strive to create a platform that is accessible and welcoming to people from all walks of life.",
  },
  {
    title: "* Authenticity",
    subtitle:
      "We value authenticity and encourage clients to share their stories and experiences in an honest and transparent way.",
  },
  {
    title: "* Community",
    subtitle:
      "We foster a sense of togetherness and connection among our clients, supporting one another as we share our stories and experiences.",
  },
  {
    title: "* Social Impact",
    subtitle:
      "We care about social impact and recognize the power of storytelling to promote positive change and create a more just and equitable world.",
  },
];

const caseStudy = [
  {
    url: "/brandingCaseStudy",
    imgPath: images.ecommerceCaseStudy,
    title: "Branding",
    subtitle:
      "Branding helps your business stand out from the crowd. A strong brand identity sets you apart from your competitors by showcasing your unique value proposition, products, or services.",
    button: <Link to="/brandingCaseStudy">Read More</Link>,
    width: "30%",
  },
  {
    url: "/socialMediaMarketingCaseStudy",
    imgPath: images.webCaseStudy01,
    title: "Social Media Marketing",
    subtitle:
      "Shin Sun-G with an ever-increasing dog-loving population, the company wanted to capitalize on the trend and reach a broader audience using social media marketing.",
    button: "Read More",
    width: "30%",
  },
  {
    url: "/salonCaseStudy",
    imgPath: images.salonCaseStudy,
    title: "SEO",
    subtitle:
      "K2 Studio was facing challenges in terms of online visibility. This case study unveils how the implementation of a robust SEO strategy propelled K2 Studio to greater heights in the beauty industry.",
    button: "Read More",
    width: "30%",
  },
  {
    url: "/deliciousCaseStudy",
    imgPath: images.restaurantCaseStudy,
    title: "Delicious",
    subtitle:
      "We create a modern and visually appealing website for Delicious that showcases its offerings, and streamline the reservation process through the development of an integrated reservation function.",
    button: "Read More",
    width: "30%",
  },
  {
    url: "/petHomeCaseStudy",
    imgPath: images.petCenterCaseStudy,
    title: "Pet Home",
    subtitle:
      "Pet Home website not only enhanced the company's online presence but also offered a seamless experience for both customers and the business, contributing to increased revenue and customer satisfaction.",
    button: "Read More",
    width: "30%",
  },
  {
    url: "/pastryCaseStudy",
    imgPath: images.buffetCaseStudy,
    title: "Pastry Shop",
    subtitle:
      "Pastry Shop is a boutique bakery and the client approached us with the goal of enhancing their online presence and implementing an efficient online order system to boost sales.",
    button: "Read More",
    width: "30%",
  },
];

const blogs = [
  {
    url: "/WhySEOStillMattersToday",
    imgUrl: images.blog6,
    title: "SEO Still Matters",
    subtitle: "Read Blog",
  },
  {
    url: "/NewDigitalHorizons",
    imgUrl: images.blog5,
    title: "New Digital Horizons",
    subtitle: "Read Blog",
  },
  {
    url: "/BrandingStrategy",
    imgUrl: images.blog4,
    title: "Branding Strategy",
    subtitle: "Read Blog",
  },
  {
    url: "/WinwinCooperation",
    imgUrl: images.blog3,
    title: "win-win cooperation",
    subtitle: "Read Blog",
  },
  {
    url: "/OurOfferToYou",
    imgUrl: images.blog2,
    title: "Our offer to you?",
    subtitle: "Read Blog",
  },
];

const Brand_service1 = [
  {
    title: "Differentiation and Competitive Advantage",
  },
  {
    title: "Long-Term Brand Growth",
  },
  {
    title: "Customer Loyalty and Advocacy",
  },
  {
    title: "Effective Resource Allocation",
  },
];

const Brand_service2 = [
  {
    title: "Captivating First Impressions",
  },
  {
    title: "Consistent Brand Representation",
  },
  {
    title: "Versatility and Adaptability",
  },
  {
    title: "Evoking Emotions and Building Connections",
  },
];

const Brand_service3 = [
  {
    title: " Consistent and Cohesive Communication",
  },
  {
    title: "Differentiation and Unique Value Proposition",
  },
  {
    title: "Emotional Connection and Brand Loyalty",
  },
  {
    title: "Storytelling and Brand Narrative",
  },
];

const Brand_service4 = [
  {
    title: "Professional and Polished Image",
  },
  {
    title: "Amplified Brand Messaging",
  },
  {
    title: "Increased Brand Awareness",
  },
  {
    title: "Effective Communication of Information",
  },
];

const Brand_service5 = [
  {
    title: "Coherent Visual Identity",
  },
  {
    title: "Efficient Collaboration",
  },
  {
    title: "Brand Protection",
  },
  {
    title: "Scalability and Expansion",
  },
];

const Brand_service6 = [
  {
    title: "Comprehensive Brand Evaluation",
  },
  {
    title: "Alignment with Brand Strategy",
  },
  {
    title: "Target Audience Insights",
  },
  {
    title: "Strategic Recommendations",
  },
];

const Brand_reason1 = [
  {
    title: "Differentiation",
    description:
      "Branding helps your business stand out from the crowd. A strong brand identity sets you apart from your competitors by showcasing your unique value proposition, products, or services.",
  },
  {
    title: "Recognition and Recall",
    description:
      "A well-developed brand becomes easily recognizable and memorable to consumers. When people can quickly identify and recall your brand, they are more likely to choose it over others when making purchasing decisions.",
  },
  {
    title: "Trust and Credibility",
    description:
      "An established brand builds trust and credibility among customers and stakeholders. A consistent and reliable brand experience fosters a sense of assurance and loyalty, encouraging repeat business and positive word-of-mouth recommendations.",
  },
  {
    title: "Customer Loyalty",
    description:
      "Strong branding fosters emotional connections with customers, leading to increased loyalty. Customers are more likely to stick with brands they trust and feel emotionally connected to, even if other alternatives might be cheaper or more readily available.",
  },
];

const Brand_reason2 = [
  {
    title: "Price Premium",
    description:
      "A well-known brand with a positive reputation can command a price premium for its products or services. Customers are often willing to pay more for a brand they trust, perceiving it as a guarantee of quality and satisfaction.",
  },
  {
    title: "Brand Advocacy",
    description:
      "Loyal customers not only repurchase but also become brand advocates. They voluntarily promote your brand to others, amplifying your marketing efforts and attracting new customers through positive word-of-mouth marketing.",
  },
  {
    title: "Employee Morale",
    description:
      "A strong brand can boost employee morale and motivation. When employees feel proud of their association with a reputable brand, they are more likely to be engaged and committed to their work.",
  },
  {
    title: "Expansion Opportunities",
    description:
      "Successful branding opens up opportunities for business expansion and diversification. As your brand gains recognition and trust, it becomes easier to introduce new products, enter new markets, or pursue strategic partnerships.",
  },
];

const Website_service1 = [
  {
    title: "Enhanced User Experience",
  },
  {
    title: "Increased Reach and Accessibility",
  },
  {
    title: "Boost in Search Engine Rankings",
  },
  {
    title: "Cost-Effectiveness and Time Efficiency",
  },
];

const Website_service2 = [
  {
    title: "Unique Brand Identity",
  },
  {
    title: "Tailored User Experience",
  },
  {
    title: "Enhanced Functionality",
  },
  {
    title: "Scalability and Flexibility",
  },
];

const Website_service3 = [
  {
    title: "Expanded Market Reach",
  },
  {
    title: "Increased Sales and Revenue",
  },
  {
    title: "Enhanced Customer Insights",
  },
  {
    title: "Seamless Inventory Management",
  },
];

const Website_service4 = [
  {
    title: "Visualize and Validate Design Concepts",
  },
  {
    title: "Efficient Communication and Collaboration",
  },
  {
    title: "Realistic User Experience Testing",
  },
  {
    title: "Visual Consistency and Brand Cohesion",
  },
];

const Website_service5 = [
  {
    title: "Test and Validate Design Choices",
  },
  {
    title: "Enhance User Experience and Interaction",
  },
  {
    title: "Facilitate Iterative Design and Continuous Improvement",
  },
  {
    title: "Minimize Risks and Costs",
  },
];

const Website_reason1 = [
  {
    title: "Global Accessibility",
    description:
      "The internet has transformed the way information is accessed and shared. A well-designed and developed website ensures that your content is accessible to a global audience 24/7, breaking down geographical barriers and expanding your reach beyond traditional limitations.",
  },
  {
    title: "First Impressions",
    description:
      "Your website is often the first interaction users have with your brand. A visually appealing and user-friendly design creates a positive first impression, building credibility and trust. An outdated or poorly designed website can drive potential visitors away.",
  },
  {
    title: "User Experience",
    description:
      "The design and development of a website directly impact how users navigate and interact with it. A well-thought-out UX considers factors like site structure, navigation, page load speed, and responsiveness across devices.",
  },
  {
    title: "Brand Identity",
    description:
      "A professionally designed website helps establish and reinforce your brand identity. Consistent use of colors, typography, imagery, and messaging across the site creates a cohesive and memorable brand experience, making it easier for visitors to recognize and remember your brand.",
  },
];

const Website_reason2 = [
  {
    title: "Mobile Responsiveness",
    description:
      "With the increasing use of mobile devices, having a website that adapts seamlessly to different screen sizes is essential. Mobile-responsive design ensures that your content remains easily accessible and readable across various devices, improving the user experience and SEO rankings.",
  },
  {
    title: "Search Engine Optimization",
    description:
      "Proper web development practices contribute to good SEO. Clean code, optimized images, structured data, and fast loading times are factors that search engines consider when ranking websites. Higher search engine rankings lead to increased visibility and organic traffic.",
  },
  {
    title: "Conversion Rates",
    description:
      "A well-designed website incorporates elements that guide users towards desired actions, such as making a purchase, signing up, or filling out a form. Effective calls-to-action (CTAs), clear navigation, and intuitive layout all contribute to higher conversion rates.",
  },
  {
    title: "Competitive Advantage",
    description:
      "A professionally designed and developed website sets you apart from competitors who may have outdated or poorly functioning sites. A modern and functional website can be a key differentiator that attracts customers and keeps them engaged.",
  },
];

const SEO_service1 = [
  {
    title: "Improved Organic Visibility",
  },
  {
    title: "Targeted Traffic Acquisition",
  },
  {
    title: "Competitive Advantage",
  },
  {
    title: "Content Optimization",
  },
];

const SEO_service2 = [
  {
    title: "Improved Search Engine Rankings",
  },
  {
    title: "Increased Organic Traffic",
  },
  {
    title: "Better Click-Through Rates",
  },
  {
    title: "Targeted Keyword Optimization",
  },
];

const SEO_service3 = [
  {
    title: "Increased Website Authority",
  },
  {
    title: "Enhanced Online Visibility",
  },
  {
    title: "Targeted Traffic Generation",
  },
  {
    title: " Relationship Building and Networking Opportunities",
  },
];

const SEO_service4 = [
  {
    title: "Increased Local Visibility",
  },
  {
    title: "Targeted Local Traffic",
  },
  {
    title: "Improved Online Reputation",
  },
  {
    title: "Increased Foot Traffic and Conversions",
  },
];

const SEO_service5 = [
  {
    title: "Performance Evaluation",
  },
  {
    title: "Identifying Opportunities",
  },
  {
    title: "Refining Strategies",
  },
  {
    title: "Tracking Competitors",
  },
];

const SEO_reason1 = [
  {
    title: " Increased Organic Traffic",
    description:
      "When your site ranks higher in search engine results pages (SERPs), more users are likely to click on your link, leading to a significant increase in organic traffic. Higher rankings mean more exposure and more potential customers finding your content.",
  },
  {
    title: "Credibility and Trust",
    description:
      "Users tend to trust search engines, and they associate high rankings with credibility and authority. If your website consistently appears at the top of relevant search results, users are more likely to trust your brand and perceive it as a reputable source of information or products.",
  },
  {
    title: "Cost-Effective Marketing",
    description:
      "Compared to paid advertising, SEO provides a cost-effective way to attract organic traffic over the long term. While it might require an initial investment in terms of time and resources, the ongoing benefits can be substantial without the need for continuous advertising spend.",
  },
  {
    title: "Better User Experience",
    description:
      "SEO involves optimizing your website's structure, navigation, and page load speed, which not only improve your search rankings but also provide a better experience. A well-organized and user-friendly site encourages users to stay longer and engage more.",
  },
];

const SEO_reason2 = [
  {
    title: "High-Quality Leads",
    description:
      "SEO targets users who are actively searching for products, services, or information related to your industry. These users are more likely to convert into customers because they have a specific intent, making them higher-quality leads compared to random website visitors.",
  },
  {
    title: "Long-Term Results",
    description:
      "While SEO requires consistent effort and updates, the results tend to have a longer shelf life compared to some other marketing tactics. Well-optimized content can continue to rank and bring in traffic for months or even years after it's published.",
  },
  {
    title: "Local Search Visibility",
    description:
      "For businesses with a physical presence, local SEO is crucial. Optimizing for local search ensures that your business appears in relevant local searches, allowing you to attract nearby customers actively seeking your products or services.",
  },
  {
    title: "Competitive Advantage",
    description:
      "In industries with strong online competition, effective SEO can be a major differentiator. If your competitors are neglecting SEO, you have the opportunity to capture a larger share of the market by ranking higher in search results.",
  },
];

const SSM_service1 = [
  {
    title: "Enhanced Audience Engagement",
  },
  {
    title: "Targeted Audience Reach",
  },
  {
    title: "Brand Authority and Thought Leadership",
  },
  {
    title: "Increased Website Traffic and Conversions",
  },
];

const SSM_service2 = [
  {
    title: "Establishing Thought Leadership",
  },
  {
    title: "Engaging Your Audience",
  },
  {
    title: "Driving Website Traffic",
  },
  {
    title: "Showcasing Brand Personality",
  },
];

const SSM_service3 = [
  {
    title: "Increased Reach and Brand Visibility",
  },
  {
    title: "Highly Targeted Advertising",
  },
  {
    title: "Cost-Effective Marketing",
  },
  {
    title: "Measurable Results and Analytics",
  },
];
const SSM_service4 = [
  {
    title: "Enhanced Brand Loyalty",
  },
  {
    title: "Valuable Customer Insights",
  },
  {
    title: "Building a Knowledge Sharing Hub",
  },
  {
    title: "Crisis Management and Reputation Building",
  },
];
const SSM_service5 = [
  {
    title: "Measure Key Metrics",
  },
  {
    title: "Identify Areas of Improvement",
  },
  {
    title: "Refine Targeting and Personalization",
  },
  {
    title: "Measure and Track Competitor Performance",
  },
];
const SSM_reason1 = [
  {
    title: "Massive Audience Reach",
    description:
      "Social media platforms like Facebook, Instagram, Twitter, LinkedIn, and TikTok have billions of active users combined. This offers an unparalleled opportunity to connect with a vast and diverse audience on a global scale.",
  },
  {
    title: "Targeted Advertising",
    description:
      "Social media platforms provide advanced targeting options that allow marketers to narrow down their audience based on demographics, interests, behaviors, and more. Your marketing efforts are directed at the people most likely to be interested in your products or services.",
  },
  {
    title: "Brand Awareness",
    description:
      "Social media offers a platform to increase brand visibility and recognition. Regular posting and engaging content can help your brand stay at the forefront of your audience's minds, even when they're not actively looking for your products or services.",
  },
  {
    title: "Engagement and Interaction",
    description:
      "Social media enables direct interaction with your audience through comments, likes, shares, and direct messages. This engagement builds stronger relationships, fosters trust, and creates a sense of community around your brand.",
  },
];
const SSM_reason2 = [
  {
    title: "Customer Support",
    description:
      "Social media serves as a convenient channel for customers to ask questions, seek assistance, or provide feedback. Responsiveness on these platforms demonstrates your commitment to customer satisfaction and can help resolve issues quickly.",
  },
  {
    title: "Content Distribution",
    description:
      "Social media provides a medium to distribute various types of content, such as blog posts, videos, images, infographics, and more. This content can showcase your expertise, educate your audience, and drive traffic to your website.",
  },
  {
    title: "Lead Generation",
    description:
      "Social media can be used to capture leads and funnel potential customers into your sales pipeline. By offering valuable content, contests, webinars, or downloadable resources, you can entice users to share their contact information.",
  },
  {
    title: "Cost-Effective",
    description:
      "Compared to traditional advertising methods, social media marketing often offers a more cost-effective solution. Most social media platforms allow you to set budgets and bid strategies, making it accessible to businesses of all sizes.",
  },
];

export default {
  features,
  logos,
  brandingBanners,
  awards,
  caseStudy,
  blogs,
  Brand_service1,
  Brand_service2,
  Brand_service3,
  Brand_service4,
  Brand_service5,
  Brand_service6,
  Brand_reason1,
  Brand_reason2,
  Website_service1,
  Website_service2,
  Website_service3,
  Website_service4,
  Website_service5,
  Website_reason1,
  Website_reason2,
  SEO_service1,
  SEO_service2,
  SEO_service3,
  SEO_service4,
  SEO_service5,
  SEO_reason1,
  SEO_reason2,
  SSM_service1,
  SSM_service2,
  SSM_service3,
  SSM_service4,
  SSM_service5,
  SSM_reason1,
  SSM_reason2,
};
