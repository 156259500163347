import React from "react";
import Box from "@mui/material/Box";
import MenuNavbar from "./MenuNavbar";
import MenuFooter from "./MenuFooter";
import { Chef, Header, Intro, Laurels, SpecialMenu } from "../menu/container";
import "./AppMenu.css";

function Menu() {
  return (
    <div className="illumin8__menu">
      <Box>
        <MenuNavbar />
        <Header />
        <SpecialMenu />
        <Chef />
        <Intro />
        <Laurels />
        <MenuFooter />
      </Box>
    </div>
  );
}

export default Menu;
