import React from "react";
import CTABranding from "./CTABranding";
import CTAWeb from "./CTAWeb";
import CTASEO from "./CTASEO";
import CTASMM from "./CTASMM";

function Services() {
  return (
    <div className="illumin8__servicesHomePage">
      <div className="illumin8__services-Title">
        <h1 className="gradient__text" style={{ padding: "3rem 0" }}>
          SERVICES
        </h1>
        <CTABranding />
        <CTAWeb />
        <CTASEO />
        <CTASMM />
      </div>
    </div>
  );
}

export default Services;
