import React from "react";
import { Link } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Typography,
  Divider,
  Button,
  TextField,
} from "@mui/material";

//* Button Customization *//
const theme = createTheme({
  palette: {
    neutral: {
      main: "black",
      contrastText: "#fff",
    },
  },
});
//

function PasswordReset() {
  return (
    <div className="illumin8__passwordReset">
      <Box
        minHeight="100vh"
        sx={{
          display: "flex",
          flexWrap: "wrap",
          "& > :not(style)": {
            m: 2,
            width: 600,
          },
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Card elevation={0}>
          <CardContent>
            <Typography
              sx={{
                fontSize: { xs: 40, md: 45 },
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "black",
                textDecoration: "none",
                textAlign: "center",
                pb: 2,
              }}
            >
              MY ACCOUNT
            </Typography>
            <Divider />
            <Typography sx={{ pt: 5 }} variant="h5" component="div">
              CHANG YOUR PASSWORD
            </Typography>
          </CardContent>
          <Box
            component="form"
            sx={{
              "& > :not(style)": { m: 1, width: "80ch" },
            }}
            noValidate
            autoComplete="off"
          >
            <Typography sx={{ pt: 3 }} color="text.secondary" component="div">
              Please enter reset code.
            </Typography>
            <TextField id="filled-basic" label="Reset Code*" variant="filled" />
            <Typography sx={{ pt: 3 }} color="text.secondary" component="div">
              Please enter your new password.
            </Typography>
            <TextField
              id="filled-basic"
              label="New Password*"
              variant="filled"
            />
            <TextField
              id="filled-basic"
              label="Confirm New Password*"
              variant="filled"
            />
          </Box>
          <CardActions
            sx={{
              justifyContent: "right",
            }}
          >
            <ThemeProvider theme={theme}>
              <Link to="/signIn">
                <Button
                  color="neutral"
                  variant="contained"
                  border-radius="60px"
                  type="submit"
                  fullWidth
                  sx={{
                    mt: 1,
                    height: 50,
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <Typography>CHANG YOUR PASSWORD</Typography>
                </Button>
              </Link>
            </ThemeProvider>
          </CardActions>
        </Card>
      </Box>
    </div>
  );
}

export default PasswordReset;
