import feature01 from "../assets/homePage/feature01.png";
import feature02 from "../assets/homePage/feature02.png";
import feature03 from "../assets/homePage/feature03.png";
import feature04 from "../assets/homePage/feature04.png";

import libro from "../assets/logo/libro.png";
import GoDaddy from "../assets/logo/GoDaddy.png";
import SQSP from "../assets/logo/SQSP.png";
import shopify from "../assets/logo/Shopify.png";
import opentable from "../assets/logo/opentable.png";
import UEAT from "../assets/logo/UEAT.png";
import webflow from "../assets/logo/webflow.png";
import wooCommerce from "../assets/logo/WooCommerce.png";
import wordPress from "../assets/logo/WordPress.png";
import figma from "../assets/logo/figma.svg";


import brandingBanner1 from "../assets/branding/brandingBanner1.png";
import brandingBanner2 from "../assets/branding/brandingBanner2.png";
import brandingBanner3 from "../assets/branding/brandingBanner3.png";

import brandingHP from "../assets/homePage/brandingHP.png";
import socialmedia from "../assets/homePage/socialmedia.png";
import web from "../assets/homePage/web.jpg";
import seo from "../assets/homePage/seo.jpg";

import webCaseStudy01 from "../assets/caseStudy/webCaseStudy01.png";
import ecommerceCaseStudy from "../assets/caseStudy/ecommerceCaseStudy.png";
import restaurantCaseStudy from "../assets/caseStudy/restaurantCaseStudy.png";
import petCenterCaseStudy from "../assets/caseStudy/petCenterCaseStudy.png";
import buffetCaseStudy from "../assets/caseStudy/buffetCaseStudy.png";
import salonCaseStudy from "../assets/caseStudy/salonCaseStudy.png";

import brandingCSImg from "../assets/caseStudy/brandingPost/brandingCSImg.jpg";
import brandingCSImg01 from "../assets/caseStudy/brandingPost/brandingCSImg01.jpg";
import brandingCSImg02 from "../assets/caseStudy/brandingPost/brandingCSImg02.jpg";
import brandingCSImg03 from "../assets/caseStudy/brandingPost/brandingCSImg03.jpg";
import brandingCSImg04 from "../assets/caseStudy/brandingPost/brandingCSImg04.jpg";
import brandingCSImg05 from "../assets/caseStudy/brandingPost/brandingCSImg05.jpg";
import brandingCSImg06 from "../assets/caseStudy/brandingPost/brandingCSImg06.jpg";
import brandingCSImg07 from "../assets/caseStudy/brandingPost/brandingCSImg07.jpg";
import brandingCSImg08 from "../assets/caseStudy/brandingPost/brandingCSImg08.jpg";

import webDesignCSImg01 from "../assets/caseStudy/webDesignPost/petHome/webDesignCSImg01.png";
import webDesignCSImg02 from "../assets/caseStudy/webDesignPost/petHome/webDesignCSImg02.png";
import webDesignCSImg03 from "../assets/caseStudy/webDesignPost/petHome/webDesignCSImg03.png";
import webDesignCSImg04 from "../assets/caseStudy/webDesignPost/petHome/webDesignCSImg04.png";
import webDesignCSImg05 from "../assets/caseStudy/webDesignPost/petHome/webDesignCSImg05.png";
import webDesignCSImg06 from "../assets/caseStudy/webDesignPost/petHome/webDesignCSImg06.png";

import deliciousCSImg01 from "../assets/caseStudy/webDesignPost/delicious/deliciousCSImg01.png";
import deliciousCSImg02 from "../assets/caseStudy/webDesignPost/delicious/deliciousCSImg02.png";
import deliciousCSImg03 from "../assets/caseStudy/webDesignPost/delicious/deliciousCSImg03.png";
import deliciousCSImg04 from "../assets/caseStudy/webDesignPost/delicious/deliciousCSImg04.png";
import deliciousCSImg05 from "../assets/caseStudy/webDesignPost/delicious/deliciousCSImg05.png";
import deliciousCSImg06 from "../assets/caseStudy/webDesignPost/delicious/deliciousCSImg06.png";
import deliciousCSImg07 from "../assets/caseStudy/webDesignPost/delicious/deliciousCSImg07.png";
import deliciousCSImg08 from "../assets/caseStudy/webDesignPost/delicious/deliciousCSImg08.png";
import deliciousCSImg09 from "../assets/caseStudy/webDesignPost/delicious/deliciousCSImg09.png";

import pastryCSImg from "../assets/caseStudy/webDesignPost/pastry/pastryCSImg.jpg";
import pastryCSImg01 from "../assets/caseStudy/webDesignPost/pastry/pastryCSImg01.png";
import pastryCSImg02 from "../assets/caseStudy/webDesignPost/pastry/pastryCSImg02.jpg";

import salonCSImg01 from "../assets/caseStudy/seoPost/salonCSImg01.png";
import salonCSImg02 from "../assets/caseStudy/seoPost/salonCSImg02.png";
import salonCSImg03 from "../assets/caseStudy/seoPost/salonCSImg03.png";
import salonCSImg04 from "../assets/caseStudy/seoPost/salonCSImg04.png";
import salonCSImg05 from "../assets/caseStudy/seoPost/salonCSImg05.png";

import SSMSImg01 from "../assets/caseStudy/SSMPost/SSMSImg01.png";
import SSMSImg02 from "../assets/caseStudy/SSMPost/SSMSImg02.png";
import SSMSImg03 from "../assets/caseStudy/SSMPost/SSMSImg03.png";
import SSMSImg04 from "../assets/caseStudy/SSMPost/SSMSImg04.png";
import SSMSImg05 from "../assets/caseStudy/SSMPost/SSMSImg05.png";
import SSMSImg06 from "../assets/caseStudy/SSMPost/SSMSImg06.png";

import branding01 from "../assets/branding/brand01.jpg";
import branding02 from "../assets/branding/brand02.jpg";
import branding03 from "../assets/branding/brand03.jpg";
import branding04 from "../assets/branding/brand04.jpg";
import branding05 from "../assets/branding/brand05.jpg";
import branding06 from "../assets/branding/brand06.jpg";

import website01 from "../assets/web/web01.png";
import website02 from "../assets/web/web02.png";
import website03 from "../assets/web/web03.png";
import website04 from "../assets/web/web04.png";
import website05 from "../assets/web/web05.png";

import seo01 from "../assets/seo/seo01.png";
import seo02 from "../assets/seo/seo02.png";
import seo03 from "../assets/seo/seo03.png";
import seo04 from "../assets/seo/seo04.png";
import seo05 from "../assets/seo/seo05.png";

import smm01 from "../assets/smm/smm01.png";
import smm02 from "../assets/smm/smm02.png";
import smm03 from "../assets/smm/smm03.png";
import smm04 from "../assets/smm/smm04.png";
import smm05 from "../assets/smm/smm05.png";

import teamUs from "../assets/aboutUs/teamUs.jpg";
import team02 from "../assets/aboutUs/team02.png";

import blog1 from "../assets/homePage/blog1.jpg";
import blog2 from "../assets/homePage/blog2.jpg";
import blog3 from "../assets/homePage/blog3.jpg";
import blog4 from "../assets/homePage/blog4.jpg";
import blog5 from "../assets/homePage/blog5.jpg";
import blog6 from "../assets/homePage/blog6.jpg";

export default {
  feature01,
  feature02,
  feature03,
  feature04,
  libro,
  GoDaddy,
  SQSP,
  shopify,
  opentable,
  UEAT,
  webflow,
  wooCommerce,
  wordPress,
  figma,
  blog1,
  blog2,
  blog3,
  blog4,
  blog5,
  blog6,
  brandingBanner1,
  brandingBanner2,
  brandingBanner3,
  brandingHP,
  socialmedia,
  seo,
  web,
  branding01,
  branding02,
  branding03,
  branding04,
  branding05,
  branding06,
  website01,
  website02,
  website03,
  website04,
  website05,
  seo01,
  seo02,
  seo03,
  seo04,
  seo05,
  smm01,
  smm02,
  smm03,
  smm04,
  smm05,
  teamUs,
  team02,
  webCaseStudy01,
  ecommerceCaseStudy,
  restaurantCaseStudy,
  petCenterCaseStudy,
  buffetCaseStudy,
  salonCaseStudy,
  brandingCSImg,
  brandingCSImg01,
  brandingCSImg02,
  brandingCSImg03,
  brandingCSImg04,
  brandingCSImg05,
  brandingCSImg06,
  brandingCSImg07,
  brandingCSImg08,
  webDesignCSImg01,
  webDesignCSImg02,
  webDesignCSImg03,
  webDesignCSImg04,
  webDesignCSImg05,
  webDesignCSImg06,
  deliciousCSImg01,
  deliciousCSImg02,
  deliciousCSImg03,
  deliciousCSImg04,
  deliciousCSImg05,
  deliciousCSImg06,
  deliciousCSImg07,
  deliciousCSImg08,
  deliciousCSImg09,
  pastryCSImg,
  pastryCSImg01,
  pastryCSImg02,
  salonCSImg01,
  salonCSImg02,
  salonCSImg03,
  salonCSImg04,
  salonCSImg05,
  SSMSImg01,
  SSMSImg02,
  SSMSImg03,
  SSMSImg04,
  SSMSImg05,
  SSMSImg06,
};
