import * as React from "react";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { teal } from "@mui/material/colors";

//* Button Customization *//
const theme = createTheme({
  palette: {
    neutral: {
      main: "black",
      contrastText: "#fff",
    },
  },
});
//

export default function SignUp() {
  const navigate = useNavigate();

  // * handle Submit function * //
  const handleSubmit = async (event) => {
    event.preventDefault();
    const registerData = new FormData(event.currentTarget);

    try {
      const createUser = await axios.post(
        "https://tdn72xi1e5.execute-api.us-east-2.amazonaws.com/register",
        {
          firstName: registerData.get("firstName"),
          lastName: registerData.get("lastName"),
          email: registerData.get("email"),
          password: registerData.get("password"),
          updatedAt: 2022,
          version: 1,
        }
      );

      if (createUser) {
        navigate("/signin");
      }
    } catch (error) {
      console.log({ error });
    }
  };

  return (
    <div className="illumin8__signUp illumin8__wrapper section__padding">
      <Container>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 12,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Paper>
            <Box
              noValidate
              sx={{
                backgroundColor: teal[100],
                p: 5,
                width: { xs: "wrap", md: "60ch" },
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Avatar sx={{ m: 1, bgcolor: "black" }}>
                <LockOutlinedIcon />
              </Avatar>
              <Typography
                component="h1"
                variant="h5"
                sx={{
                  fontWeight: 600,
                  fontSize: "30px",
                  fontFamily: "Manrope",
                  mb: 4,
                }}
              >
                REGISTER
              </Typography>

              <Box
                noValidate
                sx={{
                  width: "auto",
                  height: "auto",
                }}
              >
                <Box onSubmit={handleSubmit} method="POST" component="form">
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        autoComplete="given-name"
                        required
                        fullWidth
                        id="firstName"
                        name="firstName"
                        label="First Name"
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        required
                        fullWidth
                        id="lastName"
                        name="lastName"
                        label="Last Name"
                        autoComplete="family-name"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        id="email"
                        name="email"
                        label="Email Address"
                        autoComplete="email"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        required
                        fullWidth
                        label="Password"
                        type="password"
                        id="password"
                        name="password"
                        autoComplete="new-password"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <font color="#000000">
                        <FormControlLabel
                          control={
                            <Checkbox
                              value="allowExtraEmails"
                              color="primary"
                            />
                          }
                          label="I want to receive inspiration, marketing promotions and updates via email."
                        />
                      </font>
                    </Grid>
                  </Grid>
                  <ThemeProvider theme={theme}>
                    <Button
                      color="neutral"
                      variant="contained"
                      border-radius="60px"
                      type="submit"
                      fullWidth
                      sx={{
                        borderradius: "60px",
                        height: 50,
                        width: "100%",
                        mt: 3,
                        mb: 2,
                      }}
                    >
                      <Typography>REGISTER NOW</Typography>
                    </Button>
                  </ThemeProvider>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Box>
      </Container>
    </div>
  );
}
