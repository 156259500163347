import React from "react";
import { images } from "../../constants";
import Grid from "@mui/material/Grid";
import "./cta.css";

export default function CTASMM() {
  return (
    <div className="illumin8__CTASMM  illumin8__wrapper section__padding">
      <Grid
        container
        spacing={{ xs: 0, sm: 8 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item xs={12} sm={6}>
          <div className="illumin8__wrapper_img illumin8__wrapper_img-reverse">
            <img src={images.socialmedia} alt="header_img" />
          </div>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div className="illumin8__wrapper_info">
            <h1 className="illumin8__headtext__cormorant">
              <strong>SOCIAL MEDIA MARKETING</strong>
            </h1>
            <p className="illumin8__p__opensans" style={{ margin: "1rem 0" }}>
              In today's digital era, harnessing the potential of social media
              has become imperative for businesses to thrive and succeed. <br />
              <br />
              Our Social Media Marketing service is your key to unlocking the
              vast opportunities presented by popular platforms. With our
              expertise, we can help you leverage the power of social media to
              effectively{" "}
              <strong>
                reach and engage your target audience, build brand awareness,
                drive website traffic, and generate leads
              </strong>
              . <br />
              <br />
              Our tailored solutions will enable you to elevate your social
              media presence and propel your business forward in the dynamic
              world of digital marketing.
            </p>
            <a
              href="https://m.me/100722566156091?ref=Welcome%20to%20Solutions%20illumin8%20!"
              target="client"
            >
              <button type="button" className="illumin8__button__Contained">
                Request a Proposal -&gt;
              </button>
            </a>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
