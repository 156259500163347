import React from "react";
import { Navbar } from "./components";
import Router from "./routes";
import ScrollToTop from "./components/scroll-to-top";
import Message from "./components/message";
// import { StyledChart } from "./userDashboard/components/chart";
// import ThemeProvider from "./userDashboard/theme";

import "./App.css";

function App() {
  return (
    <div className="App">
      <div>
        {/* <ThemeProvider>
          <StyledChart />
        </ThemeProvider> */}
        <Navbar />
        <ScrollToTop />
        <Message />
        <Router />
      </div>
    </div>
  );
}

export default App;
