import gallery01 from "../../../../assets/reservation/gallery01.png";
import gallery02 from "../../../../assets/reservation/gallery02.png";
import gallery03 from "../../../../assets/reservation/gallery03.png";
import gallery04 from "../../../../assets/reservation/gallery04.png";
import menu from "../../../../assets/reservation/menu.png";
import welcome from "../../../../assets/reservation/welcome.png";
import laurels from "../../../../assets/reservation/laurels.png";
import award01 from "../../../../assets/reservation/award01.png";
import award02 from "../../../../assets/reservation/award02.png";
import award03 from "../../../../assets/reservation/award03.png";
import award05 from "../../../../assets/reservation/award05.png";
import chef from "../../../../assets/reservation/chef.png";

export default {
  chef,
  gallery01,
  gallery02,
  gallery03,
  gallery04,
  menu,
  welcome,
  laurels,
  award01,
  award02,
  award03,
  award05,
};
