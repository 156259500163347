import React from "react";
import { useState } from "react";
import EcommerceNavbar from "./EcommerceNavbar";
import EcommerceFooter from "./EcommerceFooter";
import { Container, Stack } from "@mui/material";

import {
  ProductSort,
  ProductList,
  ProductFilterSidebar,
} from "../ecommerce/products";
import PRODUCTS from "../ecommerce/_mock/products";

function EcommerceProducts() {
  const [openFilter, setOpenFilter] = useState(false);

  const handleOpenFilter = () => {
    setOpenFilter(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  return (
    <div className="illumin8__ecommerceProducts">
      <EcommerceNavbar />
      <Container>
        <Stack
          direction="row"
          flexWrap="wrap-reverse"
          alignItems="center"
          justifyContent="flex-end"
          sx={{ mb: 5 }}
        >
          <Stack direction="row" spacing={1} flexShrink={0} sx={{ my: 1 }}>
            <ProductFilterSidebar
              openFilter={openFilter}
              onOpenFilter={handleOpenFilter}
              onCloseFilter={handleCloseFilter}
            />
            <ProductSort />
          </Stack>
        </Stack>

        <ProductList products={PRODUCTS} />
      </Container>

      <EcommerceFooter />
    </div>
  );
}

export default EcommerceProducts;
