import React from "react";
import { Link } from "react-router-dom";
import { BannerImage2, BannerImage3, BannerImage4 } from "./imports";
import "./ecommerceBanner.css";

function EcommerceFooterBanner() {
  return (
    <div className="illumin8__ecommerceFooterBanner">
      <div
        className="illumin8__ecommerceFooter-Container"
        component="img"
        style={{
          backgroundImage: `url(${BannerImage2})`,
        }}
      >
        <h3>EXPLORE THE NEW COLLECTION</h3>
        <h1> BRANDING </h1>

        <Link to="">
          <button> DISCOVER MORE</button>
        </Link>
      </div>

      <div
        className="illumin8__ecommerceFooter-Container"
        style={{
          backgroundImage: `url(${BannerImage3})`,
        }}
      >
        <h3>EXPLORE THE NEW COLLECTION</h3>
        <h1> BRANDING </h1>

        <Link to="">
          <button> DISCOVER MORE</button>
        </Link>
      </div>

      <div
        className="illumin8__ecommerceFooter-Container"
        style={{
          backgroundImage: `url(${BannerImage4})`,
        }}
      >
        <h3>EXPLORE THE NEW COLLECTION</h3>
        <h1> BRANDING </h1>

        <Link to="">
          <button> DISCOVER MORE</button>
        </Link>
      </div>
    </div>
  );
}

export default EcommerceFooterBanner;
