import * as React from "react";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import PeopleIcon from "@mui/icons-material/People";
import ContactPhoneIcon from "@mui/icons-material/ContactPhone";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import menuReservation from "../../../assets/menu/menuReservation.jpeg";
import menuImg from "../../../assets/menu/menuImg.jpeg";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Button,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  ListSubheader,
  Paper,
} from "@mui/material";
import MenuNavbar from "./MenuNavbar";
import MenuFooter from "./MenuFooter";

export default function MenuReservation() {
  // *  Select Party Size * //
  const [size, setSize] = React.useState("");

  const handleChangeSize = (event) => {
    setSize(event.target.value);
  };

  // *  Select time * //
  const [time, setTime] = React.useState("");

  const handleChangeTime = (event) => {
    setTime(event.target.value);
  };

  //* Alerts Dialog *//
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //

  const [value, setValue] = React.useState(null);

  return (
    <div className="illumin8__menuReservation">
      <MenuNavbar />

      <Box
        sx={{
          display: "flex",
          flexWrap: { xs: "wrap", md: "noWrap" },
          "& > :not(style)": {
            m: 2,
            width: 600,
          },
          justifyContent: "center",
          mt: 6,
          mb: 6,
        }}
      >
        <Paper elevation={0}>
          <Card elevation={0}>
            <CardContent>
              <Typography
                sx={{ textAlign: "center" }}
                gutterBottom
                variant="h5"
                component="div"
              >
                RESERVATION POLICIES
              </Typography>
              <Typography color="black" sx={{ p: 1 }}>
                We operate on a first-come, first-served basis, ensuring
                placement for those who arrive first. In the event that
                reservations follow the waiting list, we provide a 15-minute
                grace period for lateness without prior notice. Kindly take a
                moment to inform us if you anticipate being late for your
                reservation, so we can hold the table for you.
              </Typography>
            </CardContent>
            <CardMedia
              sx={{
                height: { xs: 300, md: 450 },
              }}
              component="img"
              image={menuReservation}
            />
          </Card>
        </Paper>

        <Paper elevation={0}>
          <Card elevation={0} sx={{ display: "flex", mb: 4 }}>
            <CardMedia component="img" sx={{ width: 151 }} image={menuImg} />
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <CardContent sx={{ flex: "1 0 auto" }}>
                <Typography
                  variant="h4"
                  noWrap
                  component="a"
                  sx={{
                    mr: 2,
                    display: { xs: "none", md: "flex" },
                    fontFamily: "monospace",
                    fontWeight: 700,
                    letterSpacing: ".3rem",
                    textDecoration: "none",
                  }}
                >
                  DELICIOUS
                </Typography>
                <Typography variant="subtitle1" component="div">
                  8000 Guy Montreal, QC H1H H1H
                </Typography>
                <Typography variant="subtitle1" component="div">
                  +1 (514) 000-0000
                </Typography>
              </CardContent>
            </Box>
          </Card>

          <Box spacing={2}>
            <Typography variant="h6">
              Please select the party size, date and time*
            </Typography>

            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Party Size</InputLabel>
              <Select
                value={size}
                label="Party Size"
                onChange={handleChangeSize}
              >
                <MenuItem value={1}>1</MenuItem>
                <MenuItem value={2}>2</MenuItem>
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2 }}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date"
                  openTo="day"
                  views={["year", "month", "day"]}
                  value={value}
                  onChange={(newValue) => {
                    setValue(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </FormControl>

            <FormControl fullWidth sx={{ mt: 2 }}>
              <InputLabel>Time</InputLabel>
              <Select value={time} label="Time" onChange={handleChangeTime}>
                <ListSubheader color="primary">TABLE</ListSubheader>
                <MenuItem value={50}>10:00 AM</MenuItem>
                <MenuItem value={51}>10:30 AM</MenuItem>
                <MenuItem value={52}>11:00 AM</MenuItem>
                <MenuItem value={53}>11:30 AM</MenuItem>
                <MenuItem value={54}>12:00 PM</MenuItem>
                <MenuItem value={55}>12:30 PM</MenuItem>
                <MenuItem value={56}>1:00 PM</MenuItem>
                <MenuItem value={57}>1:30 PM</MenuItem>
                <MenuItem value={58}>2:00 PM</MenuItem>
                <MenuItem value={59}>2:30 PM</MenuItem>
                <MenuItem value={60}>3:00 PM</MenuItem>
                <MenuItem value={61}>5:00 PM</MenuItem>
                <MenuItem value={62}>5:30 PM</MenuItem>
                <MenuItem value={63}>6:00 PM</MenuItem>
                <MenuItem value={64}>6:30 PM</MenuItem>
                <MenuItem value={65}>7:00 PM</MenuItem>
                <MenuItem value={66}>7:30 PM</MenuItem>
                <MenuItem value={67}>8:00 PM</MenuItem>
                <MenuItem value={68}>8:30 PM</MenuItem>
                <MenuItem value={69}>9:00 PM</MenuItem>
                <MenuItem value={70}>9:30 PM</MenuItem>
                <MenuItem value={71}>10:00 PM</MenuItem>
                <ListSubheader color="primary">BAR COUNTER</ListSubheader>
                <MenuItem value={50}>10:00 AM</MenuItem>
                <MenuItem value={51}>10:30 AM</MenuItem>
                <MenuItem value={52}>11:00 AM</MenuItem>
                <MenuItem value={53}>11:30 AM</MenuItem>
                <MenuItem value={54}>12:00 PM</MenuItem>
                <MenuItem value={55}>12:30 PM</MenuItem>
                <MenuItem value={56}>1:00 PM</MenuItem>
                <MenuItem value={57}>1:30 PM</MenuItem>
                <MenuItem value={58}>2:00 PM</MenuItem>
                <MenuItem value={59}>2:30 PM</MenuItem>
                <MenuItem value={60}>3:00 PM</MenuItem>
                <MenuItem value={61}>5:00 PM</MenuItem>
                <MenuItem value={62}>5:30 PM</MenuItem>
                <MenuItem value={63}>6:00 PM</MenuItem>
                <MenuItem value={64}>6:30 PM</MenuItem>
                <MenuItem value={65}>7:00 PM</MenuItem>
                <MenuItem value={66}>7:30 PM</MenuItem>
                <MenuItem value={67}>8:00 PM</MenuItem>
                <MenuItem value={68}>8:30 PM</MenuItem>
                <MenuItem value={69}>9:00 PM</MenuItem>
                <MenuItem value={70}>9:30 PM</MenuItem>
                <MenuItem value={71}>10:00 PM</MenuItem>
              </Select>
            </FormControl>

            <Typography variant="h6" sx={{ mt: 4 }}>
              Contact Info*
            </Typography>

            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <PeopleIcon sx={{ mr: 1, my: 0.5 }} />
              <TextField fullWidth label="Name" variant="standard" />
            </Box>
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <ContactPhoneIcon sx={{ mr: 1, my: 0.5 }} />
              <TextField
                fullWidth
                type="number"
                label="Phone"
                variant="standard"
              />
            </Box>

            <Stack
              sx={{ mt: 2, justifyContent: "flex-end" }}
              direction="row"
              spacing={2}
            >
              <Button
                onClick={handleClickOpen}
                variant="contained"
                color="inherit"
                size="large"
                startIcon={<TableRestaurantIcon />}
              >
                BOOK
              </Button>
              <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title">
                  {"Appointment Booking Confirmation"}
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    You have selected your appointment for Saturday December 03,
                    2023 at 1:00 p.m. at Quartier Dix30. Would you like to
                    confirm?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose}>Delete</Button>
                  <Button onClick={handleClose} autoFocus>
                    Confirm
                  </Button>
                </DialogActions>
              </Dialog>
            </Stack>
          </Box>
        </Paper>
      </Box>
      <MenuFooter />
    </div>
  );
}
