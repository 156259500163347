import * as React from "react";
import Box from "@mui/material/Box";
import OnlineOrderCheckoutNavbar from "./OnlineOrderCheckoutNavbar";
import OnlineOrderCheckout from "./OnlineOrderCheckout";

function OnlineOrderPlaceOrder() {
    return (
      <div className="illumin8__onlineOrderMenu">
        <div className="illumin8__onlineOrderPlaceOrder">
          <Box
            sx={{
              maxWidth: 400,
              maxHeight: 85,
              flexGrow: 1,
              margin: "auto",
              paddingBottom: 106,
            }}
          >
            <OnlineOrderCheckoutNavbar />
            <OnlineOrderCheckout />
          </Box>
        </div>
      </div>
    );
}

export default OnlineOrderPlaceOrder;
