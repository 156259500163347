import * as React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { Grid, CssBaseline, Container } from "@mui/material";
import { images } from "../../../constants";
import MainFeaturedPost from "../MainFeaturedPost";
import FooterPost from "../FooterPost";
import SocialMediaMarketingMainPost from "./SocialMediaMarketingMainPost";

const SocialMediaMarketingMainFeaturedPost = {
  title: "",
  image: images.SSMSImg01,
  imageText: "main image description",
};

const SocialMediaMarketingFeaturedPosts = [
  {
    title: "- Angela Ahrendts",
    description:
      "If you're not adapting to the digital world, you'll be left behind.",
    image: images.SSMSImg06,
    imageLabel: "",
  },
];

const defaultTheme = createTheme();

function SocialMediaMarketingCaseStudy() {
  return (
    <div className="illumin8__socialMediaMarketingCaseStudy  illumin8__bg">
      <ThemeProvider theme={defaultTheme}>
        <CssBaseline />

        <MainFeaturedPost post={SocialMediaMarketingMainFeaturedPost} />
        <Container maxWidth="lg">
          <Grid sx={{ mt: 3 }}>
            <SocialMediaMarketingMainPost />
          </Grid>
          <Grid spacing={4} sx={{ pb: 6 }}>
            {SocialMediaMarketingFeaturedPosts.map((post) => (
              <FooterPost key={post.title} post={post} />
            ))}
          </Grid>
        </Container>
      </ThemeProvider>
    </div>
  );
}

export default SocialMediaMarketingCaseStudy;
