import React from "react";
import { images } from "../../constants";
import Grid from "@mui/material/Grid";
import "./cta.css";

export default function CTASEO() {
  return (
    <div className="illumin8__CTASEO  illumin8__wrapper section__padding illumin8__bgy">
      <Grid
        container
        spacing={{ xs: 0, sm: 8 }}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid className="div1" item xs={12} sm={6}>
          <div id="div1" className="illumin8__wrapper_info">
            <h1 className="illumin8__headtext__cormorant">
              <strong>SEO</strong>
            </h1>
            <p className="illumin8__p__opensans" style={{ margin: "1rem 0" }}>
              Standing out from the crowd and reaching your target audience
              online is essential. That's where our professional SEO service
              comes in. <br />
              <br />
              With our expertise and proven strategies, we can help you unlock
              the full potential of Search Engine Optimization (SEO) to{" "}
              <strong>
                boost your website's visibility, increase organic traffic, and
                drive conversions
              </strong>
              . Our dedicated team of SEO experts stays ahead of the latest
              industry trends and algorithm updates, ensuring that your{" "}
              <strong> website ranks higher</strong> in search engine results.{" "}
              <br />
              <br />
              Don't let your online presence go unnoticed. Partner with us and
              harness the power of SEO to propel your business to new heights.
            </p>
            <a
              href="https://m.me/100722566156091?ref=Welcome%20to%20Solutions%20illumin8%20!"
              target="client"
            >
              <button type="button" className="illumin8__button__Contained">
                Request a Proposal -&gt;
              </button>
            </a>
          </div>
        </Grid>
        <Grid className="div2" item xs={12} sm={6}>
          <div id="div2" className="illumin8__wrapper_img">
            <img src={images.seo} alt="header_img" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}
