import * as React from "react";
import header from "../../assets/yellowbg.png";
import "./header.css";

function Header() {
  return (
    <div className="illumin8__header">
      <div
        className="illumin8__header-Container illumin8__wrapper section__padding"
        style={{
          backgroundImage: `url(${header})`,
        }}
      >
        <div>
          <h1 className="illumin8__headtext__metallic">
            Illuminate Your Future
          </h1>

          <p
            className="illumin8__header__p__opensans"
            style={{ margin: "2rem 2rem" }}
          >
            Welcome to Solutions illumin8,{" "}
            <strong> your one-stop-shop for all things digital.</strong>
            <br />
            <br />{" "}
            <em>
              Whether it's eye-catching branding, intuitive website design and
              development, mastering the art of SEO, or making waves on social
              media, our team of experts has the skills and experience to turn
              your digital dreams into reality.
            </em>{" "}
            <br />
            <br />
            <strong> So why wait?</strong> Get your shine on with Solutions
            illumin8 today!
          </p>
          <a
            href="https://m.me/100722566156091?ref=Welcome%20to%20Solutions%20illumin8%20!"
            target="client"
          >
            <button type="button" className="illumin8__button__Home">
              Request a Proposal -&gt;
            </button>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Header;
